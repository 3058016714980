/**
 * @file
 * Form Checkbox element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementFormCheckbox
 * @extends Element
 */
const ElementFormCheckbox = (window.ElementFormCheckbox || window.ElementBase).extend(/** @lends ElementFormCheckbox.prototype */ {

    /**
     * Holds the list
     * @type {jQuery}
     */
    $list: null,

    /**
     * Holds the label
     * @type {jQuery}
     */
    $label: null,

    /**
     * Collect the list and label
     *
     * @override
     * @return {this}
     */
    wakeup: function() {

        this.$list = this.getViewport().jQuery('> ul.checkbox_list', this.$element[0]);
        this.$label = this.getViewport().jQuery('> label', this.$element[0]);

        return this;

    },

});


export default ElementFormCheckbox;

/**
 * @file
 * Container element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

import { refreshParallaxData, updateParallax } from '../helpers/parallax';

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementContainer
 * @extends Element
 */
const ElementContainer = (window.ElementContainer || window.ElementBase).extend(
    /** @lends ElementContainer.prototype */ {
        /**
         * Holds the content container
         * @type {jQuery}
         */
        $inner: null,

        /**
         * Collect the container and set up event listener
         *
         * @override
         * @return {this}
         */
        wakeup: function() {
            this.$inner = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);
            if (!this.$inner.length) {
                console.warn('Invalid markup for container element.', this.$element[0]);
                return this;
            }

            // Update parallax after loading or resizing
            this.getViewport().observe('load resize', () => {
                this.updateParallax(true);
            });

            // To prevent unnecessary function calls,
            // only bind to scroll events if parallax is used
            // or the page is opened in the editor
            // Get parallax amount
            var parallaxAmount;
            if (!('dataset' in this.$inner[0])) {
                // Support for IE
                parallaxAmount =
                    parseInt(this.$inner[0].getAttribute('data-parallax-amount') || 0) /
                    100;
            } else {
                parallaxAmount =
                    parseInt(this.$inner[0].dataset.parallaxAmount || 0) / 100;
            }
            if (parallaxAmount || (typeof cms !== 'undefined' && cms)) {
                this.getViewport()
                    .observe('scroll.start', () => {
                        this.refreshParallaxData();
                    })
                    .observe('frame.scroll', () => {
                        this.updateParallax();
                    });
            }

            // Update parallax while animations play
            this.getViewport().debounce('animation.step animation.end', 5, () => {
                this.updateParallax(true);
            });

            this.update();

            return this;
        },

        /**
         * @inheritDoc
         */
        getMainElement() {
            return this.$inner || this.$element;
        },

        /**
         * @inheritDoc
         */
        getSelectorForMainElement() {
            return '> .inner';
        },

        /**
         * Update parallax
         *
         * @override
         * @return {this}
         */
        update: function() {
            // Do nothing if the element isn't attached (happens in edit mode)
            if (!this.isAttached()) return;

            // Initialize parallax
            this.updateParallax(true);

            return this;
        },

        /**
         * Refresh all data that is necessary for the parallax scrolling effect
         *
         * @return {this}
         */
        refreshParallaxData() {
            refreshParallaxData(this, this.$element, this.$inner);
        },

        /**
         * Update the parallax scrolling effect.
         *
         * @param {boolean} [deep] If set to true, refresh data via refreshParallaxData
         * @return {this}
         */
        updateParallax(deep) {
            updateParallax(this, this.$element, this.$inner, deep)
        }
    }
);

export default ElementContainer;

/**
 * @file
 * Iframe element
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementIframe
 * @extends Element
 */
const ElementIframe = (window.ElementIframe || window.ElementBase).extend(/** @lends ElementIframe.prototype */ {

    /**
     * Holds the video iframe
     * @type {jQuery}
     */
    $iframe: null,

    /**
     * Collect the video iframe
     *
     * @return {this}
     */
    wakeup: function() {
        this.$iframe = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);

        return this;
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return 'iframe';
    },

    /**
     * Get the main representing element.
     * The <img> tag for an image, for example.
     * Used to draw the outline
     * @return {jQuery}
     */
    getMainElement: function() {
        if (this.$iframe && this.$iframe.length) {
            return this.$iframe;
        }

        return this.$element;
    },

});


export default ElementIframe;

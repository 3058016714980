export const COLLECTION_PAGE_ELEMENT = 'collection-page';
export const COLLECTION_ITEM_ELEMENT = 'collection-item';
export const COLLECTION_CONTAINER_ELEMENT = 'collection-container';

export const COLLECTION_ELEMENTS = [
    COLLECTION_PAGE_ELEMENT,
    COLLECTION_ITEM_ELEMENT,
    COLLECTION_CONTAINER_ELEMENT
];

export const COLLECTION_DATA_BINDING = 'data-collection-bind';

/**
 * @file
 * Form element LEGACY VERSION
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementForm
 * @extends Element
 */
const ElementForm = (window.ElementForm || window.ElementBase).extend(/** @lends ElementForm.prototype */ {});


export default ElementForm;

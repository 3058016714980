/**
 * @file
 * Menu element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementMenu
 * @extends Element
 */
const ElementMenu = (window.ElementMenu || window.ElementBase).extend(/** @lends ElementMenu.prototype */ {

    /**
     * Activate the parent menu items.
     *
     * @override
     * @see ElementMenu.activateParentItems
     * @return {this}
     */
    wakeup: function() {

        // Activate the parents of the current menu item
        this.activateParentItems();

        // Call autoFill method to fill automatic menus upon initialization
        ('autoFill' in this) && this.autoFill();

        return this;
    },

    /**
     * Activate the parents of the active menu item.
     * In custom menus, the server only activates (sets class to) the current menu item
     * and leaves its parents untouched.
     *
     * @return {this}
     */
    activateParentItems: function() {

        // If menu is a custom one
        if (this.$element.is('.wv-custom')) {

            // Make sub containers active
            $('li.active', this.$element).parents('div.sub').addClass('current');

            // Make parent li's active
            $('div.sub.current', this.$element).parents('li').addClass('active');

        }

        return this;

    }

});


export default ElementMenu;

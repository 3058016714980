import { replaceCSSName } from './utils';
function setElementAttributes(element, collectionItem, dataBind) {
    if (!dataBind.attributes) {
        return;
    }
    const { attributes: { css, alternative, caption, link } } = dataBind;
    if (css) {
        const { field } = css;
        if (field && field in collectionItem) {
            element.parentElement.classList.add(replaceCSSName(collectionItem[field]));
        }
    }

    if (alternative) {
        const { field } = alternative;
        if (field && field in collectionItem) {
            element.setAttribute('alt', collectionItem[field])
        }
    }

    if (caption) {
        const { field } = caption;
        const captionEl = element.parentElement.querySelector('span');
        captionEl.innerHTML = collectionItem[field];
    }

    if (link) {
        element.href += '/' + (collectionItem.hasOwnProperty('slug') ? collectionItem.slug : collectionItem.id);
    }
}
export default setElementAttributes;
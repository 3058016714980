/**
 * @file
 * Defines the Animations class
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * Animation controller that handles element animations and their triggers.
 * An instance of this class is globally available as "animations".
 *
 * @class Animations
 */
import 'velocity-animate/velocity';
import 'velocity-animate/velocity.ui';

var Animations = Class.extend(/** @lends Animations.prototype */ {

    /**
     * Holds all animated elements on the page
     * @type {object}
     */
    elements: {
        all: [],
        load: [],
        scroll: [],
        grouped: {}
    },

    /**
     * Highest horizontal offset of an animated element
     * @type {number}
     */
    highestOffset: 0,

    /**
     * Lowest horizontal offset of an animated element
     * @type {number}
     */
    lowestOffset: 0,

    /**
     * Force enable animations
     * @type {Boolean}
     */
    forced: false,

    /**
     * Constructor function that initializes the animations instance
     *
     * @param {Element[]} [elements]
     *
     * @return {this}
     * @constructs Animations
     */
    init: function(elements) {

        // Start if enabled
        if (this.getEnabled()) {
            this.start(elements);
        }

        // Link the groups together
        this.initGroups();

        // Init animation triggers
        this.initTriggers();

        // When user scrolls, execute scroll animations
        viewport.observe('scroll', this.executeScroll.bind(this));

        // When browser resizes, recalculate offsets
        viewport.observe('resize', this.calculateOffsets.bind(this));

        // Calculate offsets
        this.calculateOffsets();

        return this;

    },

    /**
     * Set force enable animations
     * @param {Boolean} forced
     * @return {this}
     */
    setForced: function(forced) {
        this.forced = forced;
        return this;
    },

    /**
     * Get is forced
     * @return {Boolean}
     */
    isForced: function() {
        return this.forced;
    },

    /**
     * Reset the styles of all animated elements
     * @return {this}
     */
    reset() {

        var elements = this.getAllAnimatedElements();

        for (var i = 0; i < elements.length; i++) {

            var animation = elements[i].getAnimation(),
                $target = elements[i].getAnimationTarget();

            // Stop all running animations
            $target.velocity('finish').stop(true);

            // Reset general styles
            $target.css({
                display: '',
                visibility: ''
            });

            // Reset wasAnimated
            elements[i].wasAnimated(false);

            switch (animation.type) {
                case 'fade':
                    // Reset styles
                    $target.css({
                        opacity: ''
                    });
                    break;
                case 'slide':
                    // Reset styles
                    $target.css({
                        overflow: '',
                        height: ''
                    });
                    break;
                case 'overlay':

                    // Wait until magnific is ready
                    viewport.promise('api.magnific.ready', function() {

                        // Close any popups
                        viewport.jQuery.magnificPopup.close();

                        // Reset styles
                        $target.css({
                            position: '',
                            width: '',
                            maxWidth: '',
                            textAlign: '',
                            marginLeft: '',
                            marginRight: ''
                        }).removeClass('mfp-hide');

                    });

                    break;
            }

            // Reset triggers
            this.updateTriggers(elements[i], false);

        }

        return this;

    },

    /**
     * Start the animation logic:
     * Collect animated elements and set their initial status
     *
     * @param {Element[]} [elements]
     *
     * @return {this}
     */
    start: function(elements) {

        // Reset stack
        this.elements.all = [];
        this.elements.load = [];
        this.elements.scroll = [];
        this.elements.grouped = [];

        // Collect elements
        this.collectElements(elements);

        // Set initial statuses
        this.setInitialStatus();

        // We need to initially trigger the scroll event
        // to animate elements that are inside the visible
        // area when the page has loaded.
        // But first we need to wait for all top-level onload events
        // to finish, because they could move the scroll-animated
        // elements down - out of the visible area.
        // (e.g. full-height header which slides down on page load - if the
        // adjacent element is animated on scroll, it's animation would trigger before
        // the header is slided down)
        var elements = this.getLoadAnimatedElements(),
            neededAnimations = 0, finishedAnimations = 0;
        for (var i = 0; i < elements.length; i++) {

            // Only top level animated elements
            if (!elements[i].getAnimatedParent()) {
                // Increase animations needed until scroll animations are fired
                neededAnimations++;
                elements[i].once('animation.end', function() {
                    // This element is ready, increase finished animations
                    finishedAnimations++;
                    if (finishedAnimations >= neededAnimations) {
                        // All needed animations are ready, fire scroll animations
                        this.executeScroll();
                    }
                }.bind(this));
            }

        }

        // Trigger onload animations after an initial delay of 500ms
        window.setTimeout(function() {

            this.executeOnload();

            // If no animations are needed, fire scroll animations here
            if (!neededAnimations) {
                this.executeScroll();
            }

        }.bind(this), 500);


        return this;

    },

    /**
     * Return if animations should be enabled (false in edit mode)
     *
     * @return {boolean}
     */
    getEnabled: function() {

        if (this.isForced()) {
            return true;
        }

        // No animations in edit mode
        if (typeof editor !== 'undefined' && editor && !(editor.getView() && editor.getView().getPreview())) {
            return false;
        }

        return true;

    },

    /**
     * Returns if the compatibility mode is enabled,
     * in which onload and onscroll events are disabled
     * and animations are replaced by simple
     * .show()/.hide() calls to ensure compatibility with
     * older browsers
     *
     * @return {boolean}
     */
    getCompatibilityMode: function() {

        // iPhone, iPad or iPod?
        if (/iP(hone|od|ad)/.test(navigator.userAgent)) {

            const match = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);

            if (!match) {
                return false;
            }

            const version = parseInt(match[1], 10);
            // Versions >=9 are allowed
            return version < 9;

        }

        return false;

    },

    /**
     * Collect animated elements.
     *
     * @return {this}
     */
    collectElements: function(elements) {

        if (typeof elements === 'undefined' || !elements) {
            // Get all elements
            if (typeof editor !== 'undefined' && editor) {
                elements = editor.getAllElements();
            } else {
                return this;
            }
        }

        // Loop through the elements
        for (var i = 0; i < elements.length; i++) {

            // If element animations are already initialized,
            // skip here.
            if (this.elements.all.indexOf(elements[i]) >= 0) {
                continue;
            }

            // Get animation details
            var animation = elements[i].getAnimation();

            if (!animation || animation.type == 'none') {
                continue;
            }

            // Add to all elements
            this.elements.all.push(elements[i]);

            if (animation.trigger == 'scroll') {
                // Add to scroll elements
                this.elements.scroll.push(elements[i]);
            } else if (animation.trigger == 'onload') {
                // Add to onload elements
                this.elements.load.push(elements[i]);
            }

            // Add to grouped elements
            if (animation.group.name) {

                // Create group
                if (!(animation.group.name in this.elements.grouped))
                    this.elements.grouped[animation.group.name] = [];

                // Add to group
                this.elements.grouped[animation.group.name].push(elements[i]);

            }

        }

        return this;

    },

    /**
     * Set the initial (visibility) status of animated elements
     *
     * @param {Element[]} elements
     *
     * @return {this}
     */
    setInitialStatus: function(elements) {

        if (typeof elements === 'undefined' || !elements) {
            elements = this.getAllAnimatedElements();
        }

        for (var i = 0; i < elements.length; i++) {

            var animation = elements[i].getAnimation(),
                $target = elements[i].getAnimationTarget();
            if (!animation || animation.type == 'none') {
                continue;
            }

            // No initial status on pulse or flash animations
            if (animation.type == 'pulse' || animation.type == 'flash') {
                continue;
            }

            // Set initial status
            if (animation.type == 'overlay') {

                $target.hide(); // Overlay is always hidden

                this.updateTriggers(elements[i], true);

            } else if (animation.initial && !( this.getCompatibilityMode() && ( animation.trigger == 'onload' || animation.trigger == 'scroll' ) )) {
                // Don't set initial status if compatibility mode is active,
                // the animation is onload- or scroll-triggered and the element isn't an overlay

                if (animation.initial == 'hide') {
                    // Hide element
                    if (animation.keepHeight) {
                        $target.css('visibility', 'hidden');
                    } else {
                        $target.hide();
                    }

                    this.updateTriggers(elements[i], true);

                } else {
                    // Show element
                    $target.show();

                    this.updateTriggers(elements[i], false);
                }
            } else {
                // No initial status specified, element is visible
                this.updateTriggers(elements[i], false);
            }
        }

        return this;

    },

    /**
     * Subsequently initialize animations on an element
     *
     * @param {Element[]} elements
     *
     * @return {this}
     */
    initSubsequent: function(elements) {

        // Set initial status
        this.collectElements(elements);

        // Set initial status
        this.setInitialStatus(elements);

        return this;

    },

    /**
     * Initialize the group behavior.
     * Sets up an event listener that animated group members
     * when another group member is animated.
     *
     * @return {this}
     */
    initGroups: function() {

        // Observe start of animations
        viewport.observe('animation.start', function(element, reverse, trigger) {

            // Get element animation data
            var animation = element.getAnimation();

            // Element isn't grouped? Skip here.
            // Trigger was the same group? Skip here
            if (!animation.group.name || animation.group.name == trigger)
                return;

            // Find group members
            var groupMembers = this.getGroupAnimatedElements(animation.group.name);

            for (var i = 0; i < groupMembers.length; i++) {

                // Skip current element
                if (groupMembers[i].is(element))
                    continue;

                // Determine animation direction
                var memberAnimation = groupMembers[i].getAnimation();
                if (!memberAnimation) {
                    continue;
                }

                // Determine what to do
                if (memberAnimation.group.behavior[( reverse ? 'hide' : 'show' )] == 'show') {
                    groupMembers[i].animate(false, animation.group.name); // Forward
                } else if (memberAnimation.group.behavior[( reverse ? 'hide' : 'show' )] == 'hide') {
                    groupMembers[i].animate(true, animation.group.name); // Reverse
                }


            }

        }.bind(this));


        return this;

    },


    /**
     * Get all elements that are getting animated on scroll
     *
     * @return {Element[]}
     */
    getScrollAnimatedElements: function() {
        return this.elements.scroll;
    },

    /**
     * Get all elements that are getting animated on page load
     *
     * @return {Element[]}
     */
    getLoadAnimatedElements: function() {
        return this.elements.load;
    },

    /**
     * Get all elements that are animated within a group
     *
     * @param {string} [group] Return all members of "group" or, if omitted, all group-animated elements
     * @return {Element[]}
     */
    getGroupAnimatedElements: function(group) {
        if (group && (group in this.elements.grouped))
            return this.elements.grouped[group];
        return this.elements.grouped;
    },

    /**
     * Get all elements that are animated in any kind.
     *
     * @return {Element[]}
     */
    getAllAnimatedElements: function() {
        return this.elements.all;
    },

    /**
     * Execute the scroll animations
     *
     * @return {this}
     */
    executeScroll: function() {

        // Skip in compatibility mode
        if (!this.getEnabled() || this.getCompatibilityMode()) {
            return this;
        }

        var elements = this.getScrollAnimatedElements();

        for (var i = 0; i < elements.length; i++) {

            // Don't re-animate
            if (elements[i].wasAnimated())
                continue;

            // Get initial hidden status
            var hidden = ( elements[i].getElement().css('display') == 'none' );
            // Show element
            elements[i].getElement().show();
            // Get offset
            var offset = elements[i].getElement().offset().top;
            // Hide if it was hidden before
            if (hidden) {
                elements[i].getElement().css('display', 'none');
            }

            // If element is visible, start animaiton
            if (viewport.getScrollBottom() > offset) {
                elements[i].animate();
            }

        }

        return this;

    },


    /**
     * Execute the onload animations
     *
     * @return {this}
     */
    executeOnload: function() {

        var elements = this.getLoadAnimatedElements();

        for (var i = 0; i < elements.length; i++) {

            // Don't re-animate
            if (elements[i].wasAnimated())
                continue;

            // Don't animate in compatibility mode (except overlays)
            if (this.getCompatibilityMode() && elements[i].getAnimation().type != 'overlay')
                continue;

            // Get first animated parent
            var parent = elements[i].getAnimatedParent();

            // If element has an animated parent, wait for it's animation to start
            if (parent && parent.getIsAnimated()) {

                parent.once('animation.start', (function(element) {
                    return function() {

                        // Wait until all images have been loaded (if necessary)
                        element.getElement().imagesLoaded({background: true}, function() {

                            // Give the browser time to render
                            window.setTimeout(function() {

                                // Finally trigger animation
                                element.animate();
                            }, 0);

                        });

                    };
                })(elements[i]));

            } else {

                // No animated parents, just wait for images to load
                elements[i].getElement().imagesLoaded({background: true}, (function(element) {
                    return function() {

                        // Give the browser time to render
                        window.setTimeout(function() {

                            // Trigger animation
                            element.animate();
                        }, 0);

                    };
                })(elements[i]));

            }


        }

        return this;

    },

    /**
     * Get all hyperlinks whose target is "element"
     *
     * @param  {Element} element The element to search for
     * @return {jQuery}         All hyperlinks that link to "element"
     */
    getTriggers: function(element) {

        var collection = viewport.jQuery('.wv-link-elm[href$="#' + element.getId(true) + '"]');

        var prevElement = element.getElement().prev();
        if (prevElement) {
            collection = collection.add(prevElement.children('a[href="#!next"]'));
            if (!collection.length) {
                collection = collection.add(prevElement.children().children('a[href="#!next"]'));
            }
        }

        return collection;

    },

    /**
     * Update the triggers that link to element (add an "active" class)
     *
     * @param  {Element} element   The element whose triggers are retrieved
     * @param  {boolean} [reverse] The direction the animation was played (if false, "active" class is added)
     * @return {this}
     */
    updateTriggers: function(element, reverse) {
        var $triggers = this.getTriggers(element);
        if (!$triggers.length) {
            return this;
        }

        if (reverse) {
            $triggers.removeClass('active');
        } else {
            $triggers.addClass('active');
        }

        return this;
    },

    /**
     * @param {HTMLElement} element
     */
    findNext(element) {
        const closestEdElement = element && element.parentNode ? element.parentNode.closest('.ed-element') : undefined;
        if (!closestEdElement) {
            return null;
        }

        return closestEdElement.nextSibling || this.findNext(closestEdElement);
    },

    /**
     * Initialize the animation triggers (e.g. set up a click handler)
     *
     * @return {this}
     */
    initTriggers: function() {
        const self = this;

        // Observe start of animations
        viewport.observe('animation.start', function(element, reverse) {
            if (!element.getAnimation() || element.getAnimation().type !== 'overlay') {
                this.updateTriggers(element, reverse);
            }
        }.bind(this));

        // Listen to click events
        viewport.jQuery(document).on('click', '.wv-link-elm', function(event) {
            const hash = this.hash;
            let element;

            if (!hash) {
                return;
            }

            // Handle commands
            if (hash === '#!next') {
                element = self.findNext($(this)[0]);
            } else {
                element = document.getElementById(hash.substr(1));
            }

            // Try to get element by href
            if (!element) {
                return;
            }

            event.preventDefault();

            // Check if element has some animation to trigger
            if (element.element && element.element.getIsAnimated()) {
                return element.element.animate(null, this);
            }

            // No animation. Scroll to element
            viewport.scrollTo(element, 'top', 500);
        });

        return this;
    },

    /**
     * (Re)calculate the highest and lowest horizontal offset of all animated elements.
     * This is used for the slide-left / slide-right animation types to get an even movement.
     *
     * @return {this}
     */
    calculateOffsets: function() {

        // Reset
        this.highestOffset = 0;
        this.lowestOffset = 0;

        var elements = this.getAllAnimatedElements();

        for (var i = 0; i < elements.length; i++) {

            // Get initial hidden status
            var hidden = ( elements[i].getElement().css('display') == 'none' );
            // Show element
            elements[i].getElement().show();
            // Get offset
            var offset = elements[i].getElement().offset().left;
            // Hide if it was hidden before
            if (hidden) {
                elements[i].getElement().css('display', 'none');
            }

            if (offset > this.highestOffset)
                this.highestOffset = offset;
            if (offset < this.lowestOffset)
                this.lowestOffset = offset;

        }

        return this;

    },

    /**
     * Get the calculated highest horizontal offset
     *
     * @see Animations.calculateOffsets
     * @return {number}
     */
    getHighestOffset: function() {
        return this.highestOffset;
    },

    /**
     * Get the calculated lowest horizontal offset
     *
     * @see Animations.calculateOffsets
     * @return {number}
     */
    getLowestOffset: function() {
        return this.lowestOffset;
    },

    /**
     * Get the CSS transforms applied to $element
     *
     * @param {jQuery} $element
     * @return {object} The detected transforms
     */
    getTransforms: function($element) {

        var transform = $element.css('transform');
        if (!transform || transform == 'none')
            return {};

        // From http://stackoverflow.com/a/32125700
        var values = transform.split('(')[1].split(')')[0].split(',');
        var angle = Math.atan2(values[1], values[0]),
            denom = Math.pow(values[0], 2) + Math.pow(values[1], 2),
            scaleX = Math.sqrt(denom),
            scaleY = (values[0] * values[3] - values[2] * values[1]) / scaleX,
            skewX = Math.atan2(values[0] * values[2] + values[1] * values[3], denom);
        return {
            translateX: parseFloat(values[4]) + 'px',	// translation x
            translateY: parseFloat(values[5]) + 'px', 	// translation y
            rotateZ: angle / (Math.PI / 180),			// Rotation in degrees
            scaleX: scaleX, 							// scaleX factor
            scaleY: scaleY,								// scaleY factor
            skewX: skewX / (Math.PI / 180),				// skewX angle degrees
            skewY: 0,									// skewY angle degrees
        };

    },


});


export default Animations;

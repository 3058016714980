/**
 * @file
 * Store element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementEcwidStore
 * @extends Element
 */
const ElementEcwidStore = (window.ElementEcwidStore || window.ElementBase).extend(
    /** @lends ElementEcwidStore.prototype */ {
        wakeup: function() {
            // Abort here and let cms-bundle's prepare() do the wakeup
            if (this.isAwake) {
                return;
            }

            var storeId = $('.ecwid-id', this.$element).data('storeId');
            //var type = this.dynamicWidgets ? this.type || this.getStoreWidgetType() : 'default';

            this.getViewport().requireEcwid(storeId, 'default');

            this.getViewport().promise('api.ecwid.ready.default', () => {
                !this.isAwake && !this.loaded && this.doElementWakeUp(storeId);
                this.loaded = true;
            });

            return this;
        }
    }
);


export default ElementEcwidStore;

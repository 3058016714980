import { assign } from 'lodash';

import CollectionsAPI from '../../api/collections-api';
import { decodeBinding, findTextNode, parseQueryString } from './utils';
import { COLLECTION_DATA_BINDING, COLLECTION_CONTAINER_ELEMENT } from './constants';
import ElementUtils from '../../helpers/elementutils';
//Item helpers
import FileElement from './item-file-elements';
import setElementAttributes from './item-element-attributes';

const ElementCollectionContainer = (
    window.ElementCollectionContainer || window.ElementBase
).extend({
    type: COLLECTION_CONTAINER_ELEMENT,

    /**
     * Collection id
     * @type {string}
     */
    currentId: undefined,

    /**
     * @inherittoc
     */
    wakeup() {
        this.$inner = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);
        this.templateItem = (
            this.$inner[0] || document.createElement('div')
        ).querySelector('.ed-collection-item');

        const params = this.getDataBinding();

        if (params.id) {
            this.currentId = params.id;
        }

        if (this.$element.parent().closest('.ed-reference').length || this.isEditing) {
            return;
        }

        if (!this.$inner.length) {
            console.warn(
                'Invalid markup for %s element.',
                this.type,
                this.$element[0]
            );
            return this;
        }

        if (this.templateItem) {
            if (params.single) {
                // The item ID is part of the URL and will be extracted by legacy website rendering as a global variable "extraPath"
                // References to: flex.php, WebcardModuleActions.class.php
                if (typeof extraPath !== 'undefined') {
                    CollectionsAPI.getLiveCollectionItem(
                        this.currentId,
                        extraPath
                    ).then(response => {
                        this.renderCollectionItems(response.items, false);
                    });
                    return;
                } else {
                    Object.assign(params, { offset: 0, limit: 1 });
                }
            }

            const updateItems = () => {
                // Add eventually existing params from hash
                const hashParams = params.filterByUrl
                    ? parseQueryString(location.hash.split('!')[1])
                    : {};

                CollectionsAPI.getLiveCollectionItems(
                    assign({ id: this.currentId }, params, hashParams)
                ).then(response => {
                    this.renderCollectionItems(response.items, false);
                    this.updatePagination(
                        this.findPagination(),
                        !params.hasOwnProperty('paginate') || params.paginate
                            ? response.links
                            : {}
                    );
                });
            };

            updateItems();
            if (params.filterByUrl) {
                window.onpopstate = updateItems;
            }
        }
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return '> .inner';
    },

    /**
     * @inheritDoc
     */
    getMainElement() {
        return this.$inner || this.$element;
    },

    /**
     * Render items depending on field type
     */
    renderCollectionItems(items, scroll = true) {
        this.$inner
            .removeAttr('style')
            .find('.ed-collection-item')
            .remove();

        items.reverse().forEach(item => {
            const newElement = this.templateItem.cloneNode(true);
            const selector = `[${COLLECTION_DATA_BINDING}]`;
            const nodesArray = [...newElement.querySelectorAll(selector)];
            newElement.removeAttribute('style');
            nodesArray.forEach(itemBoundElement => {
                const data = decodeBinding(
                    itemBoundElement.getAttribute(COLLECTION_DATA_BINDING)
                );
                const { type: fieldType } = data;
                setElementAttributes(itemBoundElement, item, data);
                switch (fieldType) {
                    case 'text':
                    case 'choice':
                    case 'number': {
                        const textNode = findTextNode(itemBoundElement);
                        if (textNode) {
                            textNode.textContent = item.hasOwnProperty(data.field) ? item[data.field] : '';
                        }
                        break;
                    }
                    case 'date': {
                        const textNode = findTextNode(itemBoundElement);
                        if (textNode && item.hasOwnProperty(data.field)) {
                            textNode.textContent = data.format === 'from' ? moment(item[data.field]).fromNow() : moment(item[data.field]).format(data.format || 'L');
                        }
                        break;
                    }
                    case 'editor':
                        itemBoundElement.innerHTML = item.hasOwnProperty(data.field)
                            ? item[data.field]
                            : '';
                        break;
                    case 'file':
                        FileElement(itemBoundElement, item, data);
                        break;
                    case 'cms': {
                        const serializedElements = item.hasOwnProperty(data.field)
                            ? item[data.field]
                            : [];
                        if (serializedElements.length) {
                            const renderedElements = [];
                            serializedElements.forEach(serializedElement => {
                                ElementUtils.renderFromSerialized(
                                    serializedElement,
                                    itemBoundElement,
                                    renderedElements
                                );
                            });
                            window.animations.initSubsequent(renderedElements);
                        }
                        break;
                    }
                }

                const anchor =
                    itemBoundElement.tagName === 'A'
                        ? itemBoundElement
                        : itemBoundElement.parentNode && itemBoundElement.parentNode.tagName === 'A'
                            ? itemBoundElement.parentNode
                            : itemBoundElement.querySelector('a');

                if (!anchor || !data.link) {
                    return;
                }

                if (data.link === 'page') {
                    anchor.href += `/${item.slug || item.id}`;
                } else if (data.link === 'filter' && data.field) {
                    anchor.href = `#!filter[columns.${data.field}]=${encodeURIComponent(item[data.field])}`;
                } else if (data.link === 'field') {
                    anchor.href = data.attributes?.href?.field 
                        ? item[data.attributes.href.field]?.url || item[data.attributes.href.field] || '#'
                        : '#'
                }
            });
            this.$inner[0].insertBefore(newElement, this.$inner[0].firstChild);

            const newElements = ElementUtils.initializeElements(
                newElement.querySelectorAll('.ed-element'),
                undefined,
                this.getEditor ? this.getEditor() : undefined
            );
            window.animations.initSubsequent(newElements);
        });

        ElementUtils.lazyload();


        if (scroll) {
            this.getViewport().scrollTo(this, 'top', 250);
        }
    },

    /**
     * @inheritDoc
     */
    getElementForStyling: function () {
        return this.$inner || this.$element;
    },

    /**
     * get the data binding element
     */
    getDataBindingElement() {
        return this.$element.find('> .inner');
    },

    /**
     * @returns {object} data bindings
     */
    getDataBinding() {
        const binding = this.getDataBindingElement().attr(COLLECTION_DATA_BINDING);

        this.collectionConfig = decodeBinding(binding);

        return this.collectionConfig;
    },

    getCollectionId() {
        return this.currentId;
    },

    /**
     * @returns {Element|null}
     */
    findPagination() {
        return this.getElement()[0].querySelector('.pagination');
    },

    updatePagination(paginationElement, links) {
        if (!paginationElement) {
            return;
        }

        if (!links.prev && !links.next) {
            paginationElement.classList.add('hide');
            return;
        }

        paginationElement.classList.remove('hide');

        const buttons = {
            prev: paginationElement.querySelector('.pagination-prev a'),
            next: paginationElement.querySelector('.pagination-next a')
        };

        Object.keys(buttons).forEach(key => {
            if (!buttons[key]) {
                return;
            }

            if (!links[key]) {
                buttons[key].classList.add('hide');
                return;
            }

            buttons[key].classList.remove('hide');
            buttons[key].removeEventListener('click', buttons[key].listener);
            buttons[key].listener = e => {
                e.preventDefault();
                CollectionsAPI.getLiveCollectionItems({ url: links[key].href }).then(
                    response => {
                        if (this.isEditing) {
                            this.setState({
                                items: response.items,
                                links: response.links
                            });
                            return;
                        }

                        this.renderCollectionItems(response.items);
                        this.updatePagination(this.findPagination(), response.links);
                    }
                );
            };
            buttons[key].addEventListener('click', buttons[key].listener);
        });
    }
});

export default ElementCollectionContainer;

class CultureLocaleMapping {
    static remap(culture) {
        return CultureLocaleMapping.map[culture] || culture;
    }

    static isDefaultLocale(culture) {
        return culture == 'en';
    }
}

CultureLocaleMapping.map = {
    za: 'af',
    eg: 'ar',
    ba: 'bs',
    cn: 'zh-cn',
    dk: 'da',
    ee: 'et',
    gr: 'el',
    ie: 'ga',
    kr: 'ko',
    lu: 'lb',
    no: 'nb',
    rs: 'sr',
    cz: 'cs',
    ua: 'uk',
    vn: 'vi'
};

export default CultureLocaleMapping;
/**
 * @file
 * Initializes the page (which then initializes the elements),
 * the viewport and the animation instances.
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

import Animations from './animations';
import ElementUtils from "./helpers/elementutils";
import CultureLocaleMapping from "./helpers/culturesmapping"

// If a parent window and editor instance is available, hand initialization errors over to it.
if (
    window.parent &&
    window.parent !== window &&
    typeof editor !== 'undefined' &&
    editor
) {
    window.onerror = function (msg, url, line) {
        if (!msg) {
            // Sometimes this event is dispatched
            // without any errors in place
            return false;
        }

        // Dispatch error in editor
        editor.handleWebsiteError(
            msg,
            line,
            document.currentScript ||
            (function () {
                var scripts = document.getElementsByTagName('script');
                return scripts.length ? scripts[scripts.length - 1] : null;
            })()
        );

        return false;
    };
}

/**
 * Globally available Viewport instance
 * @see Viewport
 * @type {Viewport}
 * @global
 */
window.viewport;

/**
 * Holds the current Page instance.
 * @deprecated
 *
 * @see Page
 * @type {Page}
 * @global
 */
window.page;

/**
 * Globally available Animations instance
 * @see Animations
 * @type {Animations}
 * @global
 */
window.animations;

/**
 * When document is ready, start initialization
 */
jQuery(async function ($) {
    const moment = await import('moment');
    const culture = window.webcard && window.webcard.culture || 'de';
    const locale = CultureLocaleMapping.remap(culture);
    if (!CultureLocaleMapping.isDefaultLocale(culture)) {
        import(`moment/locale/${locale}`).then(() => {
            window.moment.locale(locale);
        });
    }

    ElementUtils.lazyload(!!window.editor);

    // Notify
    typeof editor !== 'undefined' && editor && editor.notify('init.before');

    // Initialize the viewport (wraps resize/scroll events)
    window.viewport = new Viewport();

    var storeElementsCount = 0;

    const elements = ElementUtils.initializeElements(
        document.querySelectorAll('.ed-element'),
        // Count all store elements that are not inside a reference
        element => {
            const htmlElement = element.getElement()[0];
            if (htmlElement.getAttribute('class').indexOf('ecwid') === -1) {
                return;
            }

            if (htmlElement.closest('.ed-reference')) {
                return;
            }

            storeElementsCount++;
        }
    );

    if (storeElementsCount && typeof editor !== 'undefined' && editor) {
        editor.notify('store.init', [storeElementsCount]);
    }

    // Initialize the animations controller
    window.animations = new Animations(elements);

    // init form value writer, to support validation
    $(document).on('keyup', '.ed-form-date > input', function () {
        $(this).val(this.value);
    });

    // Notify
    typeof editor !== 'undefined' && editor && editor.notify('init.after');
});

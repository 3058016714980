/**
 * @file
 * Form Date element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementFormDate
 * @extends Element
 */
const ElementFormDate = (window.ElementFormDate || window.ElementBase).extend(/** @lends ElementFormDate.prototype */ {

    /**
     * Holds the input
     * @type {jQuery}
     */
    $input: null,

    /**
     * Holds the hidden input
     * @type {jQuery}
     */
    $hidden: null,

    /**
     * Holds the label
     * @type {jQuery}
     */
    $label: null,

    /**
     * Additional configuration for the datepicker
     * @type {Object}
     */
    configuration: {},

    /**
     * Collect the input and label
     *
     * @override
     * @return {this}
     */
    wakeup() {
        const $ = this.getViewport().jQuery;

        // Remove old stuff (important for presets)
        $('input[type="hidden"], div.xdsoft_datetimepicker', this.$element[0]).remove();

        this.$input = $(this.getSelectorForMainElement(), this.$element[0]);
        this.$label = $('label', this.$element[0]);

        // Create a hidden input
        this.$hidden = $('<input type="hidden" />').attr('name', this.$input.attr('name')).insertAfter(this.$input);
        this.$input.removeAttr('name');

        // Insert previously selected date
        if (this.$input.val()) {
            // Check for validity
            var previous = moment(this.$input.val(), 'L');
            if (previous && previous.isValid()) {
                this.$hidden.val(this.$input.val());
            }

            // Clear the visible input
            this.$input.val('');
        }

        // Update hidden field when user manually changes input
        this.$input.change(() => {
            this.$hidden.val(this.$input.val());
        });

        // Initialize datepicker
        this.updateDatepicker();

        return this;

    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return 'input';
    },

    /**
     * @inheritDoc
     */
    getMainElement() {
        return this.$input || this.$element;
    },

    /**
     * Set additional datepicker configuration
     *
     * @param {Object} configuration    New configuration
     *
     * @return {this}
     */
    setConfiguration(configuration) {
        this.configuration = configuration;
        this.update();
        return this;
    },

    /**
     * Get additional datepicker configuration
     *
     * @return {Object}
     */
    getConfiguration() {
        return this.configuration;
    },

    /**
     * Update the element
     */
    update() {

        this.updateDatepicker();

        return this;

    },

    /**
     * Update the datepicker
     *
     * @return {this}
     */
    updateDatepicker() {
        const $ = this.getViewport().jQuery;

        // Destroy if already initialized
        if (this.$input.data('xdsoft_datetimepicker')) {
            this.$input.datetimepicker('destroy');
        }

        // Set locale and parser
        $.datetimepicker.setLocale(document.documentElement.lang);

        $.datetimepicker.setDateFormatter({
            parseDate(date, format) {
                var d = moment(date, format);
                return d.isValid() ? d.toDate() : false;
            },
            formatDate(date, format) {
                return moment(date).format(format);
            }
        });

        // In embed mode, hide the input
        this.$element[( !!this.$input.data('embed') ? 'addClass' : 'removeClass' )]('embedded');
        if (!this.$input.data('embed')) {
            this.$input.css('display', '');
        }

        // Convert min/max date
        var minDate = false,
            maxDate = false,
            min = this.$input.attr('data-min-date'),
            max = this.$input.attr('data-max-date');

        if (min) {
            var match = min.match(/([0-9-]+) (days|weeks|months|years)/);
            if (match) {
                minDate = moment().add(match[1], match[2]).format('YYYY-MM-DD');
            } else {
                minDate = moment(min).format('YYYY-MM-DD');
            }
        }

        if (max) {
            var match = max.match(/([0-9-]+) (days|weeks|months|years)/);
            if (match) {
                maxDate = moment().add(match[1], match[2]).format('YYYY-MM-DD');
            } else {
                maxDate = moment(max).format('YYYY-MM-DD');
            }
        }

        var timeFormat = (this.$input.attr('data-time-format') ? this.$input.attr('data-time-format') : 'HH:mm');

        // Add one minute to max time
        var minTime = this.$input.attr('data-min-time') || false,
            maxTime = this.$input.attr('data-max-time') || false;
        if (maxTime) {
            maxTime = moment(maxTime, timeFormat).add(1, 'minute').format(timeFormat);
        }

        // Get allowed weekdays
        var disabledWeekdays = [];
        const enabledWeekdays = (this.$input.attr('data-weekdays') || '').split(',');
        for (var i = 0; i < 7; i++) {
            if (enabledWeekdays.indexOf(i + '') === -1) {
                disabledWeekdays.push(i);
            }
        }

        // Get output format
        var outputFormat = ( this.$input.attr('data-format') ? this.$input.attr('data-format') : 'L' );
        var type = ( this.$input.attr('data-type') ? this.$input.attr('data-type') : 'date' );
        // Append time, if necessary
        if (type === 'datetime') {
            outputFormat += ' ' + timeFormat;
        } else if (type === 'time') {
            outputFormat = timeFormat;
        }

        // Updates the value of the hidden input
        var updateHiddenInput = function(date) {
            if (!date) {
                this.$hidden.val('');
                return;
            }
            this.$hidden.val(moment(date, 'L').format('L'));
        }.bind(this);


        var currentValue = false;
        if (this.$hidden.val()) {
            var date = moment(this.$hidden.val(), outputFormat);
            currentValue = date.format(outputFormat);
        }

        // Initialize datetimepicker
        this.$input.datetimepicker(_.merge({
            dayOfWeekStart: moment().startOf('week').isoWeekday(),
            inline: !!this.$input.data('embed'),
            weeks: !!this.$input.data('weeks'),
            minDate: minDate,
            maxDate: maxDate,
            minTime: minTime,
            maxTime: maxTime,
            value: currentValue,
            format: outputFormat,
            formatTime: timeFormat,
            formatDate: 'YYYY-MM-DD',
            datepicker: (type != 'time'),
            timepicker: (type != 'date'),
            defaultSelect: false,
            allowBlank: true,
            hours12: false,
            step: parseInt(this.$input.attr('data-step') || 60),
            defaultDate: false,
            defaultTime: false,
            disabledWeekDays: disabledWeekdays,
            yearStart: ( Math.floor((new Date().getFullYear() - 100) / 10) * 10 ),
            yearEnd: ( Math.ceil((new Date().getFullYear() + 50) / 10) * 10 ),
            onSelectDate: updateHiddenInput,
            onSelectTime: updateHiddenInput,
            scrollMonth: false,
            scrollTime: false,
            scrollInput: false
        }, this.getConfiguration()));

        if (!currentValue) {
            this.$input.datetimepicker('reset');
        }

        return this;

    }

});


export default ElementFormDate;

/**
 * @file
 * Inherits all classes of the parent window into this frame
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */


/**
 * Globally available cms and editor objects
 * Remains null if not in edit mode.
 * Otherwise, refers to the object of same name in parent frame
 * @type {Cms|null}
 * @global
 */
window.cms = null;
window.editor = null;

/**
 * Expose parent classes and the CMS to this window
 */
try {

    if (window.frameElement && window.frameElement.cms && window.frameElement.editor && window.parent && window.parent.window) {

        // Expose Cms
        window.cms = window.frameElement.cms;
        window.editor = window.frameElement.editor;

        for (var prop in window.parent.window) {
            // Expose classes from parent window
            if (window.parent.window.hasOwnProperty(prop) && typeof window.parent.window[prop] === 'function' && '_isClass' in window.parent.window[prop]) {
                window[prop] = window.parent.window[prop];
            }
        }

    }

} catch (e) {

    // CMS not available
    console.error("Instantiation error:", e);

}

/**
 * Patches jQuery Mousewheel to prevent elem.ownerDocument bug
 */
jQuery(document).ready(function($) {

    $.event.special.mousewheel.getLineHeight = function(elem) {
        var $elem = $(elem),
            $parent = $elem['offsetParent' in $.fn ? 'offsetParent' : 'parent']();
        if (!$parent.length) {
            $parent = $('body');
        }
        return !elem.ownerDocument ? 16 : parseInt($parent.css('fontSize'), 10) || parseInt($elem.css('fontSize'), 10) || 16;
    };

});

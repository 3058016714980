/**
 * @file
 * Image element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementImage
 * @extends Element
 */
const ElementImage = (window.ElementImage || window.ElementBase).extend(/** @lends ElementImage.prototype */ {

    /**
     * Holds the image element
     * @type {jQuery}
     */
    $img: null,

    /**
     * Collect the image and trigger an update to initialize MagnificPopup
     *
     * @override
     * @return {this}
     */
    wakeup: function() {

        // Select the image
        this.$img = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);

        // Initialize magnific, if necessary
        this.update();

        return this;
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return 'img';
    },

    /**
     * Get the main representing element.
     * The <img> tag for an image, for example.
     * Used to draw the outline
     * @return {jQuery}
     */
    getMainElement: function() {

        if (this.getIsBackground())
            return this.getViewport().jQuery('> .background-image-holder', this.$element);

        return this.$img || this.$element;
    },

    /**
     * Initializes magnific popup, if needed
     *
     * @override
     * @return {this}
     */
    update: function() {

        if (this.$element.is('.zoom')) {

            // Wait for magnific to load
            this.getViewport().promise('api.magnific.ready', function() {

                // Initialize magnific
                this.getViewport().jQuery(this.$element[0]).magnificPopup({
                    delegate: 'a',
                    type: 'image',
                    tLoading: '...',
                    image: {
                        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
                    },
                    disableOn: () => !this.editor
                        || this.editor.getView().getPreview()
                        || !this.editor.getSecurity().isGranted('element-edit', [this])
                });

            }.bind(this));

            // Require magnific
            this.getViewport().requireMagnific();

        }

        return this;

    }

});

ElementImage.prototype.DUMMY_IMAGE = '/images/dummy.png';


export default ElementImage;

/**
 * @file
 * Video element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementYoutube
 * @extends Element
 */
const ElementYoutube = (window.ElementYoutube || window.ElementBase).extend(/** @lends ElementYoutube.prototype */ {

    /**
     * Holds the video iframe
     * @type {jQuery}
     */
    $video: null,

    /**
     * Holds the video loader, if any
     * @type {jQuery}
     */
    $loader: null,

    /**
     * Collect the video iframe
     *
     * @return {this}
     */
    wakeup: function() {

        // Get the video element
        this.$video = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);

        // Get the video loader
        this.$loader = this.getViewport().jQuery('.video-loader', this.$element[0]);

        // Bind an event to remove the loader
        this.$element.on('click', '.video-loader', function(event) {
            event.preventDefault();
            this.removeLoader();
        }.bind(this));

        return this;

    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return 'iframe';
    },

    /**
     * Get the main representing element.
     * The <img> tag for an image, for example.
     * Used to draw the outline
     * @return {jQuery}
     */
    getMainElement: function() {
        if (this.$loader && this.$loader.length) {
            return this.$loader;
        }

        if (this.$video && this.$video.length) {
            return this.$video;
        }

        return this.$element;
    },

    /**
     * Remove the loader overlay.
     *
     * @param {boolean} [autoplay] Defaults to true
     *
     * @return {this}
     */
    removeLoader: function(autoplay) {

        if (!this.$element.is('.lazy-load')) {
            return this;
        }

        // Grab video/iframe if not available
        if (!this.$video) {
            this.$video = this.getViewport().jQuery('iframe', this.$element[0]);
        }

        // Get iframe src attribute
        var src = this.$video.attr('data-src');

        // Append autoplay option
        if (typeof autoplay === 'undefined' || autoplay) {
            src += ( src.match(/\?[a-z]/) ? '&' : '?' ) + 'autoplay=1';
        }

        this.$video.attr('src', src);
        this.$video.removeAttr('data-src');

        // Show the iframe
        this.$video.css('display', '');

        // Remove the loader
        ( this.$loader || this.$element.children('.video-loader') ).remove();

        // Unset loader
        this.$loader = null;

        return this;
    }

});

export default ElementYoutube;

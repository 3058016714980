import ImageUtils from './imageutils';

class ElementUtils {
    /**
     * Returns the class name by css class or type, e.g. (text -> ElementText, ed-text -> ElementText, ed-collection-container -> ElementCollectionContainer)
     *
     * @param {String} classes
     * @returns {String}
     */
    static getTypeClassName(classes) {
        let elementType;

        if (classes.indexOf('ed-') !== -1) {
            elementType = (classes.replace('ed-element', '').match(/ed-(?!stranger|reference-)([a-z\-]+)/) || [
                null,
                null
            ])[1];
        } else {
            elementType = classes;
        }

        return 'Element' + elementType.replace(/^([a-z])|\-([a-z])/g, function(
            match,
            first,
            second,
            third
        ) {
            return (first || second || third).toUpperCase();
        });
    }

    /**
     * Initializes an element
     *
     * @param {HTMLElement|string} element
     * @param {Editor} editor
     * @returns {ElementBase}
     */
    static initializeElement(element, editor = window['editor']) {
        let className;

        if (typeof element === 'object' && 'getAttribute' in element) {
            className = this.getTypeClassName(element.getAttribute('class'));
        } else if (typeof element === 'string') {
            className = element;
        }

        if (!(className in window)) {
            return;
        }

        let elementInstance;
        try {
            elementInstance = typeof editor !== 'undefined' && editor
                ? new window[className](editor, element)
                : new window[className](element);
        } catch (e) {
            console.warn('Could not initialize element', element, className, e);
            return;
        }

        return elementInstance;
    }

    /**
     * Initialize a set of elements
     *
     * @param {object} elements
     * @param {function} callback Callable for every element (optional)
     * @param {Editor} editor
     * @returns {ElementBase[]}
     */
    static initializeElements(elements, callback, editor) {
        return Object.values(elements).map(element => {
            // An element instance is already bound to the DOMElement,
            // don't reinitialize
            if ('element' in element && element.element) {
                return;
            }

            const elementInstance = this.initializeElement(element, editor);

            if (typeof callback === 'function' && elementInstance) {
                callback(elementInstance);
            }

            return elementInstance;
        }).filter(Boolean);
    }

    /**
     * Render serialized elements into a specified container, depending on field type
     * @param {Object} element Serialized element
     * @param {Node} parentDiv DOM node to attach element  to.
     * @param {Node[]} renderedElements Accumulator for rendered elements passed as a reference
     *
     * 'id', 'children', 'content', 'css_class', 'css_style', 'from_preset', 'sort', 'type', 'col', 'animation', 'origin', 'max_count', 'min_count'
     */
    static renderFromSerialized(element, parentDiv, renderedElements = []) {
        const elDiv = document.createElement('div');
        if (element.hasOwnProperty('animation') && element.animation) {
            elDiv.setAttribute('data-animation', JSON.stringify(element.animation));
        }
        elDiv.setAttribute('id', 'ed-'+element.id);
        if (element.css_style) {
            elDiv.setAttribute('style', element.css_style);
        }
        elDiv.classList.add('ed-element', `ed-${element.type}`, ...(element.css_class||'').split(' ').filter(Boolean));
        const content = document.createRange().createContextualFragment(element.content||'');

        const contentChByColumn = this.collectContainers({}, content);

        (element.children||[]).forEach(child => {
            this.renderFromSerialized(child, contentChByColumn[child.col||1], renderedElements)
        });

        // Append Elements in correct order
        elDiv.appendChild(content);

        if (!parentDiv) {
            return;
        }

        parentDiv.appendChild(elDiv);

        // Initialize the correct element after setting childrens and content
        if (renderedElements !== null) {
            const elementInstance = this.initializeElement(element.type);
            if (elementInstance) {
                renderedElements.push(elementInstance);
            }
        }
    }

    /**
     * Collects HTML elements that serve as containers (identifid by {{col=X}} in content)
     *
     * @param {object} acc
     * @param {HTMLElement} elem
     */
    static collectContainers(acc, elem) {
        if (elem.children.length > 0) {
            return [...elem.children].reduce(this.collectContainers.bind(this), acc);
        }

        if (elem.textContent.includes('{{col=')) {
            const cont = elem.textContent.replace(/(^\{+col=|\}+$)/g, '');
            elem.textContent = '';
            acc[cont] = elem;
        }

        return acc;
    }

    static lazyload(onlyReferences = false) {
        const selectors = ['.lazyload', '.ed-lazyload'];
        var lazyImages = [
            ...document.querySelectorAll(
                selectors.map(selector => onlyReferences ? `.ed-reference ${selector}` : selector).join(',')
            )
        ];

        if (!lazyImages.length) {
            return;
        }

        const doSwap = (lazyImage) => {
            lazyImage.classList.remove("lazyload");
            lazyImage.classList.remove("ed-lazyload");
            ImageUtils.swapLazyloadedImage(lazyImage);
        };

        if ("IntersectionObserver" in window) {
            let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
                entries.forEach(function(entry) {
                    if (entry.isIntersecting) {
                        let lazyImage = entry.target;
                        doSwap(lazyImage);
                        lazyImageObserver.unobserve(lazyImage);
                    }
                });
            });

            lazyImages.forEach(function(lazyImage) {
                lazyImageObserver.observe(lazyImage);
            });

            return;
        }

        lazyImages.forEach(doSwap);
    }
}

export default ElementUtils;
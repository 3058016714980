import ElementUtils from "../../helpers/elementutils";
import ImageUtils from '../../helpers/imageutils';
import { getFileSizePath, getFullSizeFile } from './utils';

const SLIDER_TYPE = 'ElementSlider';
const GALLERY_TYPE = 'ElementGallery';
function fileElement(webcardElement, collectionItem, dataBind) {
    const { field: fieldKey, options: fieldOptions } = dataBind;
    if (!collectionItem[fieldKey]) {
        return;
    }

    if (fieldOptions && fieldOptions.multiple) {
        const files = Array.isArray(collectionItem[fieldKey])
            ? collectionItem[fieldKey]
            : [collectionItem[fieldKey]];
        const elementType = ElementUtils.getTypeClassName($(webcardElement.parentElement).attr('class'));
        if (elementType === SLIDER_TYPE) {
            const sliderFiles = files.map(imgData => {
                const fileObj = new window.File(imgData.url);
                return { image: fileObj.getUrl() };
            });
            const parameters = {
                ...JSON.parse(webcardElement.dataset.parameters),
                ...{ items: sliderFiles }
            };
            webcardElement.dataset.parameters = JSON.stringify(parameters);
        } else if (elementType === GALLERY_TYPE) {
            const galleryFiles = files.map(imgData => new window.File(imgData.url));
            const clonedLiEl = webcardElement.children[0].cloneNode(true);
            const thumbnailSettings = webcardElement.querySelector('li');
            const firstImage = thumbnailSettings.querySelector('img');
            const sourceImage = new window.File(firstImage.src);
            while (webcardElement.firstChild) {
                webcardElement.removeChild(webcardElement.firstChild);
            }
            galleryFiles.forEach(file => {
                const newInst = clonedLiEl.cloneNode(true);
                const link = newInst.querySelector('a');
                const img = newInst.querySelector('img');
                const srcSet = ImageUtils.generateSrcsetUrls(file, true, getFileSizePath(sourceImage), 1);
                img.setAttribute('srcset', srcSet);
                img.removeAttribute('data-srcset');
                img.removeAttribute('data-src');
                img.classList.remove('lazyload');
                img.classList.remove('ed-lazyload');
                link.setAttribute('href', getFullSizeFile(file, getFileSizePath(file)));
                img.src = getFullSizeFile(file, getFileSizePath(sourceImage));
                webcardElement.appendChild(newInst);
            });
        }
        new window[elementType](webcardElement.parentElement);
    } else {
        const fileData = collectionItem[fieldKey];
        if (fileData && fileData.url) {
            if (webcardElement.tagName.toLowerCase() === 'img') {
                const sourceFile = new window.File(webcardElement.src);
                const targetFile = new window.File(fileData.url);
                const fileUrl = getFullSizeFile(
                    targetFile,
                    getFileSizePath(sourceFile)
                );
                if (webcardElement.classList.contains('lazyload') || webcardElement.classList.contains('ed-lazyload')) {
                    webcardElement.setAttribute('data-src', fileUrl);
                } else {
                    webcardElement.src = fileUrl;
                }
                webcardElement.removeAttribute('srcset');
                webcardElement.removeAttribute('data-srcset');

                const parentNode = webcardElement.parentNode;
                if (parentNode.tagName.toLowerCase() === 'a' && parentNode.getAttribute('href').indexOf('images/') > -1) {
                    parentNode.setAttribute('href', fileData.url);
                }
            } else if (webcardElement.classList.contains('background-image-holder')) {
                const sourceFile = new window.File(/(?:\(['"]?)(.*?)(?:['"]?\))/.exec(webcardElement.style.backgroundImage)[1]);
                const targetFile = new window.File(fileData.url);
                const fileUrl = getFullSizeFile(
                    targetFile,
                    getFileSizePath(sourceFile)
                );
                if (webcardElement.classList.contains('lazyload') || webcardElement.classList.contains('ed-lazyload')) {
                    webcardElement.setAttribute('data-background', fileUrl);
                } else {
                    webcardElement.setAttribute('style', `background-image: url("${fileUrl}");`);
                }
            } else {
                ['src', 'href'].forEach(property => {
                    const element = webcardElement.querySelector(`[${property}]`);
                    if (element) {
                        element.setAttribute(property, fileData.url);
                    }
                });
            }
        }
    }
}
export default fileElement;

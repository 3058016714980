/**
 * @file
 * Main Store element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

const ElementEcwidProducts = (window.ElementEcwidProducts || window.ElementBase).extend(ElementEcwidStore.prototype)
    .extend({
        doWidgetWakeup: function(id) {
            var type = this.type || this.getStoreWidgetType();
            this.getViewport().promise(
                'api.ecwid.ready.' + type,
                function() {
                    this.setStoreElements();
                }.bind(this)
            );
        },
        doElementWakeUp: function(id) {
            xProductBrowser('id=product-browser-' + id);
        }
    });


export default ElementEcwidProducts;

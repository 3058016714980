require('@babel/polyfill/noConflict');
const moment = require('moment');
window.moment = moment.default || moment;

const $ = require('jquery');
window.$ = window.jQuery = $.default || $;

const _ = require('lodash');
window._ = _.default || _;

require('@app/web/shared/vendor/jquery/plugins/jquery-migrate-3.0.0');
require('@app/web/shared/vendor/jquery/plugins/jquery.closestchild');
require('@app/web/shared/vendor/jquery/plugins/imagesloaded');
require('@app/web/shared/vendor/datetimepicker/jquery.datetimepicker');
require('@app/web/webcard/js/patch/jquery/mousewheel');
require('@app/web/shared/js/helpers/bind');
require('@app/web/shared/js/helpers/inheritance');
const File = require('@app/web/shared/js/file');
window.File = File.default || File;

const Importer = require('@app/web/shared/js/importer/importer');
window.Importer = Importer.default || Importer;

require('@app/web/webcard/js/helpers/inherit');
require('@app/web/webcard/js/page');
const Viewport = require('@app/web/webcard/js/viewport');
window.Viewport = Viewport.default || Viewport;

const Animations = require('@app/web/webcard/js/animations');
window.Animations = Animations.default || Animations;

const ElementBase = require('@app/web/webcard/js/element');
window.ElementBase = ElementBase.default || ElementBase;

const ElementAudio = require('@app/web/webcard/js/elements/audio');
window.ElementAudio = ElementAudio.default || ElementAudio;

const ElementButton = require('@app/web/webcard/js/elements/button');
window.ElementButton = ElementButton.default || ElementButton;

const ElementContainer = require('@app/web/webcard/js/elements/container');
window.ElementContainer = ElementContainer.default || ElementContainer;

const ElementDocument = require('@app/web/webcard/js/elements/document');
window.ElementDocument = ElementDocument.default || ElementDocument;

const ElementFacebook = require('@app/web/webcard/js/elements/facebook');
window.ElementFacebook = ElementFacebook.default || ElementFacebook;

const ElementForm = require('@app/web/webcard/js/elements/form');
window.ElementForm = ElementForm.default || ElementForm;

const ElementGallery = require('@app/web/webcard/js/elements/gallery');
window.ElementGallery = ElementGallery.default || ElementGallery;

const ElementGrid = require('@app/web/webcard/js/elements/grid');
window.ElementGrid = ElementGrid.default || ElementGrid;

const ElementHeadline = require('@app/web/webcard/js/elements/headline');
window.ElementHeadline = ElementHeadline.default || ElementHeadline;

const ElementHtml = require('@app/web/webcard/js/elements/html');
window.ElementHtml = ElementHtml.default || ElementHtml;

const ElementIcon = require('@app/web/webcard/js/elements/icon');
window.ElementIcon = ElementIcon.default || ElementIcon;

const ElementIframe = require('@app/web/webcard/js/elements/iframe');
window.ElementIframe = ElementIframe.default || ElementIframe;

const ElementSvg = require('@app/web/webcard/js/elements/svg');
window.ElementSvg = ElementSvg.default || ElementSvg;

const ElementImage = require('@app/web/webcard/js/elements/image');
window.ElementImage = ElementImage.default || ElementImage;

const ElementLogo = require('@app/web/webcard/js/elements/logo');
window.ElementLogo = ElementLogo.default || ElementLogo;

const ElementMap = require('@app/web/webcard/js/elements/map');
window.ElementMap = ElementMap.default || ElementMap;

const ElementMenu = require('@app/web/webcard/js/elements/menu');
window.ElementMenu = ElementMenu.default || ElementMenu;

const ElementReference = require('@app/web/webcard/js/elements/reference');
window.ElementReference = ElementReference.default || ElementReference;

const ElementSection = require('@app/web/webcard/js/elements/section');
window.ElementSection = ElementSection.default || ElementSection;

const ElementSeparator = require('@app/web/webcard/js/elements/separator');
window.ElementSeparator = ElementSeparator.default || ElementSeparator;

const ElementSlider = require('@app/web/webcard/js/elements/slider');
window.ElementSlider = ElementSlider.default || ElementSlider;

const ElementContentSlider = require('@app/web/webcard/js/elements/content-slider');
window.ElementContentSlider = ElementContentSlider.default || ElementContentSlider;

const ElementSpacer = require('@app/web/webcard/js/elements/spacer');
window.ElementSpacer = ElementSpacer.default || ElementSpacer;

const ElementText = require('@app/web/webcard/js/elements/text');
window.ElementText = ElementText.default || ElementText;

const ElementVideo = require('@app/web/webcard/js/elements/video');
window.ElementVideo = ElementVideo.default || ElementVideo;

const ElementYoutube = require('@app/web/webcard/js/elements/youtube');
window.ElementYoutube = ElementYoutube.default || ElementYoutube;

const ElementVimeo = require('@app/web/webcard/js/elements/vimeo');
window.ElementVimeo = ElementVimeo.default || ElementVimeo;

const ElementCollectionContainer = require('@app/web/webcard/js/elements/collection/container');
window.ElementCollectionContainer = ElementCollectionContainer.default || ElementCollectionContainer;

const ElementCollectionItem = require('@app/web/webcard/js/elements/collection/item');
window.ElementCollectionItem = ElementCollectionItem.default || ElementCollectionItem;

const ElementPrivacy = require('@app/web/webcard/js/elements/privacy');
window.ElementPrivacy = ElementPrivacy.default || ElementPrivacy;

const ElementLanguage = require('@app/web/webcard/js/elements/language');
window.ElementLanguage = ElementLanguage.default || ElementLanguage;

const ElementFormContainer = require('@app/web/webcard/js/elements/form/container');
window.ElementFormContainer = ElementFormContainer.default || ElementFormContainer;

const ElementFormButton = require('@app/web/webcard/js/elements/form/button');
window.ElementFormButton = ElementFormButton.default || ElementFormButton;

const ElementFormCaptcha = require('@app/web/webcard/js/elements/form/captcha');
window.ElementFormCaptcha = ElementFormCaptcha.default || ElementFormCaptcha;

const ElementFormCheckbox = require('@app/web/webcard/js/elements/form/checkbox');
window.ElementFormCheckbox = ElementFormCheckbox.default || ElementFormCheckbox;

const ElementFormInput = require('@app/web/webcard/js/elements/form/input');
window.ElementFormInput = ElementFormInput.default || ElementFormInput;

const ElementFormEmail = require('@app/web/webcard/js/elements/form/email');
window.ElementFormEmail = ElementFormEmail.default || ElementFormEmail;

const ElementFormNumber = require('@app/web/webcard/js/elements/form/number');
window.ElementFormNumber = ElementFormNumber.default || ElementFormNumber;

const ElementFormPhone = require('@app/web/webcard/js/elements/form/phone');
window.ElementFormPhone = ElementFormPhone.default || ElementFormPhone;

const ElementFormDate = require('@app/web/webcard/js/elements/form/date');
window.ElementFormDate = ElementFormDate.default || ElementFormDate;

const ElementFormRadio = require('@app/web/webcard/js/elements/form/radio');
window.ElementFormRadio = ElementFormRadio.default || ElementFormRadio;

const ElementFormSelect = require('@app/web/webcard/js/elements/form/select');
window.ElementFormSelect = ElementFormSelect.default || ElementFormSelect;

const ElementFormTextarea = require('@app/web/webcard/js/elements/form/textarea');
window.ElementFormTextarea = ElementFormTextarea.default || ElementFormTextarea;

const ElementFormUpload = require('@app/web/webcard/js/elements/form/upload');
window.ElementFormUpload = ElementFormUpload.default || ElementFormUpload;

const ElementEcwidStore = require('@app/web/webcard/js/elements/store/store');
window.ElementEcwidStore = ElementEcwidStore.default || ElementEcwidStore;

const ElementEcwidProducts = require('@app/web/webcard/js/elements/store/products');
window.ElementEcwidProducts = ElementEcwidProducts.default || ElementEcwidProducts;

const ElementEcwidSearch = require('@app/web/webcard/js/elements/store/search');
window.ElementEcwidSearch = ElementEcwidSearch.default || ElementEcwidSearch;

const ElementEcwidCart = require('@app/web/webcard/js/elements/store/cart');
window.ElementEcwidCart = ElementEcwidCart.default || ElementEcwidCart;

const ElementEcwidCategories = require('@app/web/webcard/js/elements/store/categories');
window.ElementEcwidCategories = ElementEcwidCategories.default || ElementEcwidCategories;

require('@app/web/webcard/js/init');

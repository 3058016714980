/**
 * @file
 * Section element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

import { refreshParallaxData, updateParallax } from '../helpers/parallax';

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementSection
 * @extends Element
 */
const ElementSection = (window.ElementSection || window.ElementBase).extend(/** @lends ElementSection.prototype */ {

    /**
     * Holds the section
     * @type {jQuery}
     */
    $section: null,

    /**
     * Collect the section and set up event listener
     *
     * @override
     * @return {this}
     */
    wakeup: function() {

        this.$section = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);
        if (!this.$section.length) {
            console.warn('Invalid markup for section element.', this.$element[0]);
            return this;
        }

        // When page loads/resizes, first update width.
        // When the widths of all sections are updated,
        // update height (horizontal scrollbars will have disappeared)
        // and parallax
        this.getViewport().observe('load resize', function() {
            this.updateWidth();
        }.bind(this)).observe('load:after resize:after', function() {
            this.updateHeight();
            this.updateParallax(true);
        }.bind(this));

        // To prevent unnecessary function calls,
        // only bind to scroll events if parallax is used
        // or the page is opened in the editor
        // Get parallax amount
        if (!('dataset' in this.$section[0])) { // Support for IE
            var parallaxAmount = parseInt(this.$section[0].getAttribute('data-parallax-amount') || 0) / 100;
        } else {
            var parallaxAmount = parseInt(this.$section[0].dataset.parallaxAmount || 0) / 100;
        }
        if (parallaxAmount || ( typeof cms !== 'undefined' && cms )) {

            this.getViewport().observe('scroll.start', function() {

                this.refreshParallaxData();

            }.bind(this)).observe('frame.scroll', function() {

                this.updateParallax();

            }.bind(this));

        }

        // Update parallax while animations play
        this.getViewport().debounce('animation.step animation.end', 5, function() {

            this.updateParallax(true);

        }.bind(this));

        this.update();

        return this;

    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return '> section';
    },

    /**
     * Update width, height and parallax
     *
     * @override
     * @return {this}
     */
    update: function() {

        // Do nothing if the element isn't attached (happens in edit mode)
        if (!this.isAttached())
            return;

        // Initialize height / parallax
        this.updateWidth();
        this.updateHeight();
        this.updateParallax(true);

        return this;
    },

    /**
     * Update the sections width according to the properties in its data-attributes
     *
     * @return {this}
     */
    updateWidth: function() {

        // Do nothing if the element isn't attached (happens in edit mode)
        if (!this.isAttached())
            return;

        // No full width, reset css, then skip here
        if (!this.$element.is('.full-width') && !this.$section.is('.full-width')) {

            // Reset main element
            this.$element.css({
                marginLeft: '',
                width: ''
            });

            // Reset section
            this.$section.css({
                marginLeft: '',
                width: ''
            });

            // If there was an .inner, remove it
            if (this.getViewport().jQuery('> .inner', this.$section).length) {
                this.getViewport().jQuery('> .inner', this.$section).contents().unwrap();
                // The section now is the container
                this.$section.addClass('container');
            }

            return;
        }

        // Get display values of this and parent
        var elementDisplayValue = this.$element.css('display');
        var sectionDisplayValue = this.$section.css('display');
        if (elementDisplayValue == 'none')
            this.$element.show(); // Show if hidden
        if (sectionDisplayValue == 'none')
            this.$section.show(); // Show if hidden

        // If element is still not visible, skip any further calculations
        if (!this.$section.is(':visible')) {
            this.$section.css({
                marginLeft: '0',
                width: 'auto'
            });
            this.getViewport().jQuery('> .inner', this.$section).css({width: 'auto'});
            // Reset display values
            this.$element.css('display', elementDisplayValue);
            this.$section.css('display', sectionDisplayValue);
            return this;
        }


        var fullWidth = this.getViewport().getWidth(),
            contentWidth = this.$element.parent().width();

        // Section is inside a column
        var $closestColumn = this.$element.closest('[class*="span"]');
        if ($closestColumn.length)
            fullWidth = $closestColumn.width();

        // Calculate margin
        var margin = (fullWidth - contentWidth) / 2;
        if (this.$element.closest('.wv-abs, .wv-fixed').length)
            margin = 0;

        if (margin < 0)
            margin = 0;

        // Reset display values
        this.$element.css('display', elementDisplayValue);
        this.$section.css('display', sectionDisplayValue);

        // If the element has full-width class assigned,
        // the content is stretched to full width.
        if (this.$element.is('.full-width')) {

            // Everything is full width, .inner is not needed
            if (this.getViewport().jQuery('> .inner', this.$section).length) {
                this.getViewport().jQuery('> .inner', this.$section).contents().unwrap();
                // The section now is the container
                this.$section.addClass('container');
            }

            // Set the element to full width
            this.$element.css({
                marginLeft: -margin,
                width: fullWidth
            });

            // Reset styles for the section
            this.$section.css({
                marginLeft: '',
                width: ''
            });

        } else {

            // Make sure there is an ".inner"
            if (!this.getViewport().jQuery('> .inner', this.$section).length) {
                this.$section.removeClass('container').children().not('div.overlay, div.background, .slick-arrow, .slick-dots').wrapAll('<div class="inner container"></div>');
            }

            // Reset styles for the element
            this.$element.css({
                marginLeft: '',
                width: ''
            });

            // Make only the background full width
            this.$section.css({
                marginLeft: -margin,
                width: fullWidth
            });

            if (contentWidth == fullWidth) {
                // Seems there is no container, delete inline styles and trust CSS container definitions
                this.getViewport().jQuery('> .inner', this.$section).css({
                    width: '',
                    marginLeft: '',
                    marginRight: '',
                });
            } else {
                // The .inner container gets the width of the content
                this.getViewport().jQuery('> .inner', this.$section).css({
                    width: contentWidth + 'px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                });
            }


        }

        return this;

    },


    /**
     * Update the sections height according to the properties in its data-attributes
     *
     * @return {this}
     */
    updateHeight: function() {

        // Do nothing if the element isn't attached (happens in edit mode)
        if (!this.isAttached())
            return;

        // Animation in progress, do nothing.
        // Height will already have been set
        if (this.$section.is('.wv-animating') || this.$element.is('.wv-animating'))
            return;

        var heightConfiguration = {
            mode: this.$section.attr('data-height-mode'),
            height: this.$section.attr('data-height'),
            subtract: this.$section.attr('data-height-subtract'),
            reference: this.$section.attr('data-height-reference'),
        };

        if (heightConfiguration.mode === undefined || heightConfiguration.mode == 'auto' || heightConfiguration.height === undefined) {

            this.$section.css('height', '');

        } else if (heightConfiguration.mode == 'pixel') {

            this.$section.css({
                'height': parseInt(heightConfiguration.height || 0) + 'px'
            });

        } else if (heightConfiguration.mode == 'percent') {

            var height = ( ( parseFloat(heightConfiguration.height || 0) / 100 ) * ( heightConfiguration.reference == 'width' ? this.getViewport().getWidth() : this.getViewport().getHeight() ) ) - parseInt(heightConfiguration.subtract || 0);

            this.$section.css({
                'height': parseInt(height || 0) + 'px'
            });

        }

        return this;
    },

    /**
     * Refresh all data that is necessary for the parallax scrolling effect
     *
     * @return {this}
     */
    refreshParallaxData: function() {
        refreshParallaxData(this, this.$section);
    },

    /**
     * Update the parallax scrolling effect.
     *
     * @param {boolean} [deep] If set to true, refresh data via refreshParallaxData
     * @return {this}
     */
    updateParallax: function(deep) {
        updateParallax(this, this.$section, this.$section, deep);
    },

    /**
     * Get the target element for animations.
     *
     * @override
     * @return {jQuery}
     */
    getAnimationTarget: function() {

        var animation = this.getAnimation();

        if (animation && animation.type == 'slide' && this.$section && this.$section.is('.full-width'))
            return this.$section;

        return this.$element;

    }

});


export default ElementSection;

/**
 * @file
 * HTML element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementHtml
 * @extends Element
 */
const ElementHtml = (window.ElementHtml || window.ElementBase).extend(/** @lends ElementHtml.prototype */ {});


export default ElementHtml;

/**
 * Refresh all data that is necessary for the parallax scrolling effect
 * @param {element} element
 * @param {jQuery} $wrapper 
 * @param {jQuery} [$attributeHolder] element holding all parallax attributes, if it is different from the $wrapper element
 * 
 * @return {element}
 */
export const refreshParallaxData = (element, $wrapper, $attributeHolder) => {

    if (!$attributeHolder) {
        $attributeHolder = $wrapper;
    }
    
    if (!element.parallax) element.parallax = {};

    // Get background container (div.background in section), background element (div.ed-element) and background image holder
    element.parallax.$backgroundContainer = $('> .background', $wrapper);
    element.parallax.$backgroundElement = $('> .ed-element', element.parallax.$backgroundContainer);
    element.parallax.$backgroundImage = $('> .background-image-holder', element.parallax.$backgroundElement);
    
    // Do nothing if the element isn't attached (happens in edit mode) or has neither a bg container nor a background element
    if (
        !element.isAttached() ||
        !element.parallax.$backgroundContainer.length ||
        !element.parallax.$backgroundElement.length
    ) {
        element.parallax.enabled = false;
        return;
    }

    // Get slider texts, if any
    element.parallax.$sliderText = $(
        '.ed-slider-text',
        element.parallax.$backgroundElement
    );

    // Get parallax amount
    if (!('dataset' in $attributeHolder[0])) {
        // Support for IE
        element.parallax.amount = parseInt($attributeHolder[0].getAttribute('data-parallax-amount') || 0) / 100;
    } else {
        element.parallax.amount = parseInt($attributeHolder[0].dataset.parallaxAmount || 0) / 100;
    }

    // Reset parallax values
    if (!element.parallax.amount) {
        resetParallaxValues(element, $wrapper);
    }

    // Reset old transforms on background element
    element.parallax.$backgroundElement.css('transform', '');

    // Get element bounding
    element.parallax.elementBoundingBox = element.$element.offset();
    element.parallax.elementBoundingBox.height = element.$element.outerHeight();
    element.parallax.elementBoundingBox.bottom = element.parallax.elementBoundingBox.top + element.parallax.elementBoundingBox.height;

    // Prevent race condition where element bounding box is not visible at this moment (e.g. background as part of an overlay)
    if (element.parallax.elementBoundingBox.height === 0) {
        window.setTimeout(() => refreshParallaxData(element, $wrapper, $attributeHolder), 250);
        return;
    }

    // Determine parallax mode
    determineParallaxMode(element, $wrapper);

    // We dont need any calculations in fixed mode
    if (element.parallax.fixed) {
        return element;
    }

    // Get parallax start and end point
    element.parallax.startPoint = element.parallax.elementBoundingBox.top - element.getViewport().getHeight();
    element.parallax.endPoint = element.parallax.elementBoundingBox.bottom;
    element.parallax.distance = element.parallax.endPoint - element.parallax.startPoint;

    // Smallest necessary height of the parallax background
    element.parallax.height = Math.ceil(
        element.parallax.elementBoundingBox.height +
            (element.getViewport().getHeight() -
                element.parallax.elementBoundingBox.height) *
                element.parallax.amount
    );

    // Assign container height
    element.parallax.$backgroundContainer.height(element.parallax.height);

    // Calculate start and end values for the translation
    // Inverse parallax amount if reverse mode
    const parallaxValue = element.parallax.reverse ? (1 - element.parallax.amount) : element.parallax.amount;

    // Translation at start:
    element.parallax.start = -element.getViewport().getHeight() * parallaxValue;

    // Translation at end:
    element.parallax.end = element.parallax.elementBoundingBox.height * parallaxValue;

    // Finally enable parallax
    element.parallax.enabled = true;

    return element;
}

/**
 * Update the parallax scrolling effect.
 *
 * @param {element} element
 * @param {jQuery} $wrapper
 * @param {jQuery} [$attributeHolder] element holding all parallax attributes, if it is different from the $wrapper element
 * @param {boolean} [deep] If set to true, refresh data via refreshParallaxData
 * 
 * @return {element}
 */
export const updateParallax = (element, $wrapper, $attributeHolder, deep) => {
    // Get data if not present
    if (!element.parallax || deep) {
        refreshParallaxData(element, $wrapper, $attributeHolder);
    }

    // Check if parallax is enabled and element is in viewport or parallax amount is < 1
    if (
        !element.parallax.enabled ||
        element.parallax.fixed ||
        element.getViewport().getScrollTop() < element.parallax.startPoint ||
        element.getViewport().getScrollTop() > element.parallax.endPoint
    ) {
        return;
    }

    // Get percent of element scrolled
    var percentScrolled =
        (element.getViewport().getScrollTop() - element.parallax.startPoint) /
        element.parallax.distance;

    // Calculate current translation, see the differences
    // in element.parallax.start/end in refreshParallaxData
    element.parallax.translation =
        (element.parallax.end - element.parallax.start) * percentScrolled +
        element.parallax.start;

    if (element.parallax.reverse) {
        // In reverse mode, the parallax background
        // has background-attachment: fixed and thus needs
        // inversed values

        // Inverse current translation
        element.parallax.translation = -element.parallax.translation;

        // Assign background position
        element.parallax.$backgroundImage.css(
            'background-position',
            element.parallax.alignment + ' ' + element.parallax.translation + 'px'
        );
    } else {
        // Default mode, translate the container

        // Assign transform
        element.parallax.$backgroundContainer.css(
            'transform',
            'translate3d(0, ' + element.parallax.translation + 'px, 0)'
        );

        // Translate slider text, if any
        if (element.parallax.$sliderText.length) {
            element.parallax.$sliderText.css(
                'transform',
                'translate3d(0, ' +
                    -(
                        element.parallax.height -
                        element.parallax.elementBoundingBox.height +
                        element.parallax.translation
                    ) +
                    'px, 0)'
            );
        }
    }

    return element;
}

const resetParallaxValues = (element, $wrapper) => {
    element.parallax.fixed = false;
    $wrapper.removeClass('parallax parallax-fixed');
    
    // Reset element
    element.parallax.$backgroundElement.css({
        transform: '',
        height: ''
    });

    setParallaxStyles(element, $wrapper);

    // Reset slider Text
    if (element.parallax.$sliderText.length) {
        element.parallax.$sliderText.css('transform', '');
    }

    // Disable parallax
    element.parallax.enabled = false;

    return;
}

const setParallaxStyles = (element, $wrapper) => {
    // Reset container 
    element.parallax.$backgroundContainer.css({
        'transform': '',
        'height': '',
        'clip': element.parallax.fixed ? 'rect(0, auto, auto, 0)' : ''
    });

    // Reset background image
    element.parallax.$backgroundImage.css({
        'background-position': ''
    })

    element.parallax.fixed 
        ? $wrapper.addClass('parallax-fixed')
        : $wrapper.removeClass('parallax-fixed');

    element.parallax.reverse
        ? element.parallax.$backgroundImage.addClass('wv-bg-fixed')
        : element.parallax.$backgroundImage.removeClass('wv-bg-fixed');

    return element;
}

const determineParallaxMode = (element, $wrapper) => {
    // check if background element is an image and our section
    // is <= viewport height (because background-size:cover will
    // refer to the viewport size and we can't scale it up)
    const isImageBackground = element.parallax.$backgroundElement.is('.ed-image');
    const isSmallerThanViewport = element.parallax.elementBoundingBox.height <= element.getViewport().getHeight();

    // Fixed mode            
    if (element.parallax.amount == 1 && isImageBackground) {
        element.parallax.reverse = false;
        element.parallax.fixed = true;
        element.parallax.enabled = true;
    }
    // Inversed mode
    else if (
        element.parallax.amount > 0.5 && isImageBackground && isSmallerThanViewport &&
        (!element.getViewport().getIsMobile() && !element.getViewport().getIsTouch())
    ) {
        element.parallax.reverse = true;
        element.parallax.fixed = false;

        // Remove inline style and retrieve currently applied horizontal alignment,
        // because parallax will use the background-position property for the effect
        // and we want to make sure that at least horizontal alignment is kept
        element.parallax.$backgroundImage[0].style.backgroundPosition = null;
        const { backgroundPosition = 'center' } = window.getComputedStyle(element.parallax.$backgroundImage[0]) || {};
        element.parallax.alignment = backgroundPosition.split(' ')[0];
    }
    // Default mode
    else {
        element.parallax.reverse = false;
        element.parallax.fixed = false;
    }
    setParallaxStyles(element, $wrapper);
}
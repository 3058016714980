export function mapCollectionItem({ columns = {}, createdAt, updatedAt, active }, id) {
    return {
        id,
        ...columns
    };
}

/** Types
 * @typedef {{id:String, name:String, slug:String, link:String, single:Boolean}} Collection
 */

class CollectionsAPI {
    constructor() {
        this.Routing = window.Routing;
        if (!this.Routing) {
            try {
                this.Routing = window.parent.Routing;
            } catch (e) {}
        }
    }

    /**
     * get the endpoint url
     * @param {string} pathname
     */
    getUrl(pathname) {
        const isAbsolutePath = /https?[:]\/\//.test(pathname);

        const protocol = window.location.protocol;
        const hostname = `cms.${document.domain}`;
        if (isAbsolutePath) {
            return pathname;
        } else {
            return `${protocol}//${hostname}${pathname}`;
        }
    }

    /**
     * get webcard id
     * @returns {string}
     */
    getWebcardId() {
        const cms = window.cms || (
            window.Cms && window.Cms.getInstances()[0])
            || (window.LuserCms && window.LuserCms.getInstances()[0]
        );

        return (
            (cms && cms.getWebcard().getId()) ||
            (window.webcard && window.webcard.id) ||
            undefined
        );
    }

    /**
     * fetch proxy to reformat things, etc.
     * @param {string} pathnanme
     * @param {{body: object?; method: string?;}} options
     *
     * NOTE: based on Cms#ajax
     */
    request(pathname, { body, headers, method = 'GET' } = {}) {
        const url = this.getUrl(pathname);
        let fetch;
        try {
            fetch = window.parent.fetch;
        } catch (e) {
            fetch = window.fetch;
        }
        return fetch(url, {
            body: body ? JSON.stringify(body) : undefined,
            mode: 'cors',
            method,
            headers: {
                Accept: 'application/json',
                'Webcard-Id': this.getWebcardId(),
                'Content-Type': 'application/json; charset=utf-8',
                'X-Requested-With': 'XMLHttpRequest',
                ...headers
            },
            credentials: 'include'
        }).then(
            res => {
                if (res.status === 204) {
                    return null;
                }

                if (res.ok) {
                    return res.json();
                }

                throw new Error(res.status);
            }
        );
    }

    buildQueryString(params) {
        return Object.entries(params)
            // skip undefined, and null values
            .filter(([key, value]) => value != null)
            .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
            .join('&')
            ;
    }

    /**
     * get request parameters with defaults
     * @typedef {object} options
     * @property {string} id
     * @property {string} order
     * @property {boolean} paginate
     * @property {number} limit
     * @property {number} offset
     * @property {string} url
     * @property {string} search
     * @property {string} distinct
     * @property {object} filter
     *
     * @returns {Object}
     */
    getParams(options = {}) {
        const {
            id,
            order = 'createdAt_DESC',
            filter,
            paginate = true,
            limit = 10,
            offset = 0,
            url,
            search,
            distinct
        } = options;

        const params = { id, order, limit, offset, distinct, paginate, url, search };

        Object.keys(params).forEach((key) => (params[key] === null || params[key] === undefined) && delete params[key]);

        if (filter && filter.length > 0) {
            filter.forEach(({ key, value }) => {
                if (key !== null && value !== null) {
                    params[`filter[${key}]`] = typeof value === 'boolean' ? (value?1:0) : value;
                }
            });
        }

        return params;
    }


    /**
     * get live items
     * @param {object} options
     */
    getLiveCollectionItems(options = {}) {
        const { id, ...params } = this.getParams(options);

        const apiEndpoint = webcard && webcard.apiHost ? webcard.apiHost : 'api.websitebutler.local';
        const url = params.url || `https://${apiEndpoint}/collection/${id}/items?${this.buildQueryString(params)}`;

        return this.request(url).then(response => {
            return {
                ...response,
                items: Object.entries(response.collection)
                    .map(([id, item]) => mapCollectionItem(item, id))
            };
        });
    }

    getLiveCollectionItem(id, itemId) {
        const apiEndpoint = webcard && webcard.apiHost ? webcard.apiHost : 'api.websitebutler.local';
        const baseUrl = `https://${apiEndpoint}/collection/${id}/item/${itemId}`;
        const requestUrl = new URL(baseUrl);
        return fetch(requestUrl)
            .then(blob => blob.json())
            .then(response => ({
                ...response,
                items: [mapCollectionItem(response, itemId)]
            }));
    }

    /**
     * GET available collections
     * @param {{params: {[key: string]: any}, body: {[key: string]: any}}} options
     * @returns {Promise<{collection: Collection[]}>}
     */
    getCollections() {
        return (
            this.request(this.Routing.generate('cms_api_collections_index'))
                // FIXME: ask backend to provide a list and rename to "collections"
                .then(response => {
                    return {
                        ...response,
                        collections: Object.entries(response.collection).reduce(
                            (acc, [id, values]) => {
                                return acc.concat({ id, ...values });
                            },
                            []
                        )
                    };
                })
        );
    }

    /**
     * update an existing collection
     * @param {{id: string; ...body: object;}} collection
     */
    updateCollection({ id, ...body }) {
        const url = this.Routing.generate('cms_api_collections_put', { id });

        return this.request(url, {
            body,
            method: 'PUT',
        });
    }

    /**
     * create a new collection
     * @param {} options
     * @returns {Promise}
     */
    createCollection({ name, slug } = {}) {
        const body = {
            name,
            slug,
        };

        return this.request(this.Routing.generate('cms_api_collections_post'), {
            body,
            method: 'POST',
        }).then((response) => {
            if (response.errors) {
                return response;
            }

            const collection = response;

            // FIXME: we don't have the collection id for new collections...
            return this.getCollections()
                .then(({ collections }) => {
                    return {
                        ...(collections.find(el => el.slug === collection.slug) || {}),
                        ...collection,
                    };
                })
            ;
        });
    }

    /**
     * DELETE collection
     * @param {Collection} collection
     */
    deleteCollection(collection) {
        return this.request(collection.link, { method: 'DELETE' });
    }

    /**
     * GET collection fields
     * @param {{id: String}} params
     * @returns {Promise}
     */
    getFields({ id } = {}) {
        return this.request(
            this.Routing.generate('cms_api_collections_fields_index', { id })
        )
            // FIXME: ask backend to rename "collection" to "fields"
            .then(response => {
                return {
                    ...response,
                    fields: response.collection
                };
            })
            ;
    }

    /**
     * update collection fields
     * @param {{params: {[key: string]: any}, body: {[key: string]: any}}} options
     * @returns {Promise}
     */
    updateField({ params, body } = {}) {
        return this.request(
            this.Routing.generate('cms_api_collections_fields_put', params),
            {
                body,
                method: 'PUT'
            }
        );
    }

    /**
     * create collection field
     * @param {{params: {[key: string]: any}, body: {[key: string]: any}}} options
     * @returns {Promise}
     */
    createField({ params, body } = {}) {
        return this.request(
            this.Routing.generate('cms_api_collections_fields_post', params),
            {
                body,
                method: 'POST'
            }
        );
    }

    /**
     * GET collection fields
     * @param {String} link
     * @returns {Promise}
     */
    deleteField(link) {
        return this.request(link, { method: 'DELETE' });
    }

    /**
     * get collection items
     * @param {{ id: string; limit?: number; offset?: number; }} options
     * @returns {Promise<object>}
     */
    getItems(options) {
        const params = this.getParams(options);

        const url =
            params.url ||
            this.Routing.generate('cms_api_collections_items_index', params)
            ;

        return this.request(url).then(response => {
            return {
                ...response,
                items: Object.entries(response.collection)
                    .map(([id, item]) => mapCollectionItem(item, id))
            };
        });
    }

    /**
     * GET collection item
     * @param {{ id: String, item: String }} params
     */
    getItem(params = {}) {
        const url = this.Routing.generate('cms_api_collections_items_get', params);

        return this.request(url);
    }

    /**
     * change collection item
     * @param {{params: {[key: string]: any}, body: {[key: string]: any}}} options
     * @returns {Promise}
     */
    updateItem({ params, body } = {}) {
        return this.request(
            this.Routing.generate('cms_api_collections_items_put', params),
            {
                body,
                method: 'PUT'
            }
        );
    }

    /**
     * Add new Item into Collection
     * @param {{params: {[key: string]: any}, body: {[key: string]: any}}} options
     * @returns {Promise}
     */
    createItem({ params, body } = {}) {
        return this.request(
            this.Routing.generate('cms_api_collections_items_post', params),
            {
                body,
                method: 'POST'
            }
        );
    }

    /**
     * delete collection items
     * @param {{id: String, item: String}} params
     * @returns {Promise}
     */
    deleteItem(params = {}) {
        return this.request(
            this.Routing.generate('cms_api_collections_items_delete', params),
            {
                method: 'DELETE'
            }
        );
    }
}

export default new CollectionsAPI();

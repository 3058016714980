/**
 * @file
 * Form element VERSION 2
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

import ElementUtils from "../../helpers/elementutils";

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementFormContainer
 * @extends Element
 */
const ElementFormContainer = (window.ElementFormContainer || window.ElementBase).extend(/** @lends ElementFormContainer.prototype */ {

    /**
     * Holds the form
     * @type {jQuery}
     */
    $form: null,

    /**
     * If set to true, form is submitted via AJAX
     */
    ajax: true,

    /**
     * Instantiation time
     * @type {Date}
     */
    time: null,

    isSubmitting: false,

    /**
     * Collect the form
     *
     * @override
     * @return {this}
     */
    wakeup: function() {

        // Set instantiation time
        this.time = new Date();

        // Get the form
        this.$form = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);

        this.$form.on('submit', function(event) {
            if (this.ajax) {
                event.preventDefault();
                this.submit();
            }
        }.bind(this));

        return this;

    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return '> form';
    },

    /**
     * @inheritDoc
     */
    getMainElement() {
        return this.$form || this.$element;
    },

    /**
     * Submits the form
     */
    submit: function() {

        // Disable form in CMS
        if (typeof cms !== 'undefined' && cms) {
            return false;
        }

        if (!this.ajax) {
            this.$form.submit();
            return this;
        }

        if (this.isSubmitting) {
            return;
        }
        this.isSubmitting = true;

        // Safari bugfix (https://stackoverflow.com/questions/49614091/safari-11-1-ajax-xhr-form-submission-fails-when-inputtype-file-is-empty)
        var $fileInputs = $('input[type="file"]:not([disabled])', this.$form)
        $fileInputs.each(function(_, input) {
            if (input.files.length > 0) return
            $(input).prop('disabled', true)
        });

        // Serialize the form
        var data = new FormData(this.$form[0]);
        if (typeof webcard !== 'undefined' && 'culture' in webcard && webcard.culture) {
            data.append('culture', webcard.culture);
        }

        $fileInputs.prop('disabled', false);

        // Append form ID
        data.append('id', this.getId());

        // Append request time
        data.append('tac', Math.floor(( new Date().getTime() - this.time.getTime()) / 1000));

        var url = this.$form.attr('action') || ((typeof webcard !== 'undefined' && webcard.apiHost ? 'https://' + webcard.apiHost : '') + '/form_container/submit');

        // Send form
        $.ajax({
            url: url,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            data: data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: (response) => {
                this.isSubmitting = false;

                // Destroy all elements inside the form
                this.$element.find('.ed-element').each(function () {
                    this.element.destroy();
                });

                // Replace content
                this.$element.html(response);

                // Center the form so the errors/success messages can be seen by the user
                this.getViewport().scrollTo(this.$element, 'center', 300);

                if ($(response).is('.wv-success')) {
                    this.getViewport().notify('form.success', [this, this.$form]);
                } else {
                    this.getViewport().notify('form.error', [this, this.$form]);
                }

                // Reinitialize this form
                this.wakeup();

                // Get all children
                var $children = this.$element.find('.ed-element');

                // Now we need to reinitialize the elements inside the form
                var elements = [];
                $children.each((key, element) => {
                    const className = ElementUtils.getTypeClassName($(element).attr('class'));

                    if (className in window) {
                        elements.push(new window[className](element));
                    }
                });

                // Re-initialize animations
                animations.initSubsequent(elements);
            }
        });


        return this;

    },

    /**
     * Enable or disable AJAX submission
     */
    setAjax: function(ajax) {
        this.ajax = ajax;
        return this;
    }

});


export default ElementFormContainer;

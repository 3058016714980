/**
 * @file
 * Defines the class system
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */
import { isPlainObject, cloneDeep } from 'lodash';

const buildClass = function() {
    var initializing = false;

    /**
     * The main class constructor
     *
     * @class Class
     * @constructs Class
     */
    this.Class = function() {};

    // Easy, cross-browser way to detect classes
    this.Class._isClass = true;

    // Instances of this class
    this.Class._instances = [];

    // Inheritors of this class
    this.Class._inheritors = [];

    // Remember instantiating window
    this.Class.prototype._window = window;

    /**
     * Holds the observers of this class instance
     * @protected
     * @type {Object.<string,Array>}
     */
    this.Class.prototype.observers = {};

    /**
     * Stores all (latest) events fired on this class
     * instance along with their arguments
     * @protected
     * @type {Object.<string,Array>}
     */
    this.Class.prototype.firedEvents = {};

    /**
     * Removes observers from this class instance by event name.
     * If listener is provided, only that specifc listener is removed.
     *
     * @param {String} event The event name.
     * @param {*Function} listener The listener, if any.
     *
     * @return {this} The class.
     */
    this.Class.prototype.removeObserver = function(event, listener) {
        if (!this.observers.hasOwnProperty(event)) {
            return this;
        }

        if (!listener) {
            delete this.observers[event];
            return this;
        }

        var index = this.observers[event].indexOf(listener);

        if (-1 === index) return this;

        delete this.observers[index];

        return this;
    };

    /**
     * Registers a new observer on this class instance.
     * Used to be informed about any events occurring during program execution.
     * The return values of the observers aren't processed.
     * To prevent specific operations, use the hooking system.
     *
     * @param {string} event            Events to observe, multiple events can be separated by space
     * @param {function} observer       The callback function
     * @param {Class} [bind]            Bind this event to an event of another class instance. If this event occurs, the observer will be removed
     * @param {string} [to='destroy']   Event that has to occur in "bind" to remove this observer
     * @return {this}
     */
    this.Class.prototype.observe = function(event, observer, bind, to) {

        // Allow specifying multiple events at once
        if (event.indexOf(' ') != -1) {
            var events = event.split(' ');
            for (var i = 0; i < events.length; i++)
                this.observe(events[i], observer, bind, to);
            return this;
        }

        // No observer set yet
        if (!(event in this.observers))
            this.observers[event] = [];

        // Shouldn't happen
        if (!this.observers[event])
            this.observers[event] = [];

        // Observer added by constructor
        if (typeof this.observers[event] == 'function')
            this.observers[event] = [this.observers[event]];

        this.observers[event].push(observer);

        // If bind is set, delete the observer as soon as
        // the bound object is destroyed
        if (bind && 'once' in bind) {

            bind.once((to || 'destroy'), function() {

                var index = this.observers[event].indexOf(observer);
                if (index !== -1) {
                    this.observers[event].splice(index, 1);
                }

            }.bind(this));

        }

        return this;

    };

    /**
     * Registers a new observer on this class instance and debounce it.
     * That means, when the event occurs, a timeout is set. If the event occurs again
     * while the timeout is still active, the observer won't be executed and the timeout
     * is prolonged.
     *
     * @param {string} event        Events to observe, multiple events can be separated by space
     * @param {number} time         Timeout duration
     * @param {function} observer   The callback function
     * @return {this}
     */
    this.Class.prototype.debounce = function(event, time, observer) {

        // Allow specifying multiple events at once
        if (event.indexOf(' ') != -1) {
            var events = event.split(' ');
            for (var i = 0; i < events.length; i++)
                this.debounce(events[i], time, observer);
            return this;
        }

        // No observer set yet
        if (!(event in this.observers))
            this.observers[event] = [];

        // Shouldn't happen
        if (!this.observers[event])
            this.observers[event] = [];

        // Observer added by constructor
        if (typeof this.observers[event] == 'function')
            this.observers[event] = [this.observers[event]];

        this.observers[event].push({
            observer: observer,
            debounceTimeout: null,
            debounceTime: time
        });

        return this;

    };

    /**
     * Registers a new observer on this class instance and instantly execute it
     * if the event already occured before the registration
     *
     * @param {string} event            Events to observe, multiple events can be separated by space
     * @param {function} observer       The callback function
     * @param {boolean} [once=false]    If true, the observer will instantly be removed after it was executed once
     * @param {Class} [bind]            Bind this event to an event of another class instance. If this event occurs, the observer will be removed
     * @param {string} [to='destroy']   Event that has to occur in "bind" to remove this observer
     * @return {this}
     */
    this.Class.prototype.promise = function(event, observer, once, bind, to) {

        // Allow specifying multiple events at once
        if (event.indexOf(' ') != -1) {
            var events = event.split(' ');
            for (var i = 0; i < events.length; i++)
                this.promise(events[i], observer, once);
            return this;
        }

        // Event already fired.
        if (event in this.firedEvents) {
            observer.apply(this, this.firedEvents[event]);
            // No need to add the observer to the stack
            if (once)
                return this;
        }

        this[once ? 'once' : 'observe'](event, observer, bind, to);

        return this;

    };

    /**
     * Registers a new observer on this class instance that is executed only once (and then removed)
     *
     * @param {string} event            Events to observe, multiple events can be separated by space
     * @param {function} observer       The callback function
     * @param {Class} [bind]            Bind this event to an event of another class instance. If this event occurs, the observer will be removed
     * @param {string} [to='destroy']   Event that has to occur in "bind" to remove this observer
     * @return {this}
     */
    this.Class.prototype.once = function(event, observer, bind, to) {

        // Allow specifying multiple events at once
        if (event.indexOf(' ') != -1) {
            var events = event.split(' ');
            for (var i = 0; i < events.length; i++)
                this.once(events[i], observer);
            return this;
        }

        // No observer set yet
        if (!(event in this.observers))
            this.observers[event] = [];

        // Shouldn't happen
        if (!this.observers[event])
            this.observers[event] = [];

        // Observer added by constructor
        if (typeof this.observers[event] == 'function')
            this.observers[event] = [this.observers[event]];

        this.observers[event].push({
            observer: observer,
            delete: true
        });

        // If bind is set, delete the observer as soon as
        // the bound object is destroyed
        if (bind && 'once' in bind) {

            bind.once((to || 'destroy'), function() {

                var index = this.observers[event].indexOf(observer);
                if (index !== -1) {
                    this.observers[event].splice(index, 1);
                }

            }.bind(this));

        }

        return this;

    };

    /**
     * Internally used function to dispatch all observers of an event
     *
     * @param {string} event    The event to dispatch
     * @param {*} args          Array of arguments to pass to the observers
     */
    this.Class.prototype.executeObservers = function(event, args) {

        // No observer set yet
        if (!(event in this.observers))
            return this;

        // Shouldn't happen
        if (!this.observers[event])
            this.observers[event] = [];

        // Observer added by constructor
        if (typeof this.observers[event] == 'function')
            this.observers[event] = [this.observers[event]];

        for (var i = 0; i < this.observers[event].length; i++) {
            var observer = this.observers[event][i];

            // Observer already deleted, skip it
            if (!observer)
                continue;

            if (typeof observer == 'function') {
                // Fire instantly, no debouncing
                observer.apply(this, args);
            } else {
                // Delete the observer after the call
                if ('delete' in observer && observer.delete) {
                    // Execute the observer
                    observer.observer.apply(this, args);
                    // Make sure instance still exists (maybe it was deleted in observer)
                    if (!this.observers[event]) {
                        return this;
                    }
                    // Delete the observer
                    this.observers[event][i] = null;
                } else if ('debounceTimeout' in observer) {
                    // Debounce observer
                    window.clearTimeout(observer.debounceTimeout);
                    observer.debounceTimeout = window.setTimeout(function(observer, args) {
                        return function() {
                            observer.observer.apply(this, args);
                        }.bind(this);
                    }.bind(this)(observer, args), observer.debounceTime);
                }
            }

            // Class was destroyed in an observer
            if (!this.observers || !(event in this.observers)) {
                return this;
            }
        }

        return this;

    };


    /**
     * Dispatch all observers for an event
     *
     * @param {string} event                Events to notify, multiple events can be separated by space
     * @param {*} [args=[]]                 Arguments to pass to the observers
     * @param {boolean} [deferred=false]    If true, the observers will be dispatched after a 0s timeout (usually this is done to ensure browsers rendering is executed first)
     * @return {this}
     */
    this.Class.prototype.notify = function(event, args, deferred) {
        var i;

        // If wanted, defer the notification. Usually, this
        // is done to ensure browsers rendering is executed first.
        if (deferred) {
            window.setTimeout(function() {
                this.notify(event, args);
            }.bind(this), 0);
            return this;
        }

        // Allow specifying multiple events at once
        if (event.indexOf(' ') != -1) {
            var events = event.split(' ');
            for (i = 0; i < events.length; i++)
                this.notify(events[i], args);
            return this;
        }

        // Remember the fired event (or override with last arguments)
        this.firedEvents[event] = args;

        // Execute observers of the :before sub-event, if any
        if (event + ':before' in this.observers) {
            this.executeObservers(event + ':before', args);
        }

        // Execute observers of the main events
        this.executeObservers(event, args);

        // Execute observers of the :after sub-event, if any
        if (event + ':after' in this.observers) {
            this.executeObservers(event + ':after', args);
        }

        // Execute global observers
        if (this.constructor._observers && this.constructor._observers.hasOwnProperty(event)) {
            for (i = 0; i < this.constructor._observers[event].length; i++) {
                var observer = this.constructor._observers[event][i];
                typeof observer == 'function' && observer.apply(this, args);
            }
        }

        return this;
    };

    /**
     * Holds the hooks of this class instance
     * @protected
     * @type {Object.<string,Array>}
     */
    this.Class.prototype.hooks = {};

    /**
     * Registers a new hook on this class instance.
     * Used to prevent/allow specific processes. The main executing function can fire an event with .call(event, args) and then listen for the return value to determine if execution should continue.
     * If any of the registered hooks returns false, the overall return value will be false - otherwise, it will be true.
     *
     * @param {string} event    Event to hook
     * @param {function} hook   The callback function
     * @return {this}
     */
    this.Class.prototype.hook = function(event, hook) {

        // No hooks set yet
        if (!(event in this.hooks))
            this.hooks[event] = [];

        // Shouldn't happen
        if (!this.hooks[event])
            this.hooks[event] = [];

        // Hooks added by constructor
        if (typeof this.hooks[event] == 'function')
            this.hooks[event] = [this.hooks[event]];

        this.hooks[event].push(hook);

        return this;

    };

    /**
     * Dispatch the hooks for "event" of this class instance and get their return value.
     *
     * @param {string} event  Events to call
     * @param {*} [args=[]]   Arguments to pass to the hooks
     * @return {this}
     */
    this.Class.prototype.call = function(event, args) {
        var callReturn = true;

        // No hooks set yet
        if (!(event in this.hooks))
            return callReturn;

        // Shouldn't happen
        if (!this.hooks[event])
            this.hooks[event] = [];

        // Hooks added by constructor
        if (typeof this.hooks[event] == 'function')
            this.hooks[event] = [this.hooks[event]];

        for (var i = 0; i < this.hooks[event].length; i++) {
            var hookReturn = this.hooks[event][i].apply(this, args);
            callReturn = ( !callReturn || typeof hookReturn === 'undefined' ? false : hookReturn );
        }

        return callReturn;
    };

    /**
     * "Destroys" the class instance without breaking the basic functionality.
     * The instance isn't actually removed, it's just emptied.
     */
    this.Class.prototype.destroy = function() {

        // Notify about this
        this.notify('destroy');

        // Make sure to delete all references to it
        if(this._class && this._class._instances && this._class._instances.length) {
            var index = this._class._instances.indexOf(this);
            if(index !== -1) {
                this._class._instances.splice(index, 1);
            }
        }

        for (var property in this) {
            if(!this.hasOwnProperty(property)) {
                continue;
            }

            if (typeof this[property] === 'function') {
                // Empty functions
                this[property] = function() {
                };
            } else if (typeof jQuery !== 'undefined' && this[property] instanceof jQuery) {
                // Clear jQuery objects
                this[property] = null;
            } else if (typeof this[property] === 'object') {
                // Empty objects
                this[property] = {};
            } else if (Array.isArray(this[property])) {
                // Empty arrays
                this[property] = [];
            } else {
                // Empty everything
                this[property] = null;
            }
        }

    };

    /**
     * Extend this class
     *
     * @param {object} prop New class' definition
     * @return {function}   The new class
     */
    Class.extend = function extend(prop) {
        var _super = this.prototype;

        // Instantiate a base class (but only create the instance,
        // don't run the init constructor)
        initializing = true;
        var prototype = new this();
        initializing = false;

        // Copy the properties over onto the new prototype
        for (var name in prop) {
            // if we're overwriting an existing function
            // set the base property
            var value = prop[name];
            if (typeof prop[name] == "function" && typeof _super[name] == "function") {
                value.base = _super[name];
            }
            prototype[name] = value;
        }

        // The dummy class constructor
        var Class = function() {
            // All construction is actually done in the init method
            if (!initializing) {

                // Deep clone all arrays and objects to avoid references
                for (var name in this) {
                    if (isPlainObject(this[name]) || Array.isArray(this[name])) {
                        this[name] = cloneDeep(this[name]);
                    }
                }

                // Assign instantiating class
                this._class = Class;

                // Convert arguments to an array
                var args = Array.prototype.slice.call(arguments);

                // Call pre-construct method, which is allowed
                // to change the arguments that will get passed to
                // the constructor method
                if (this.__wakeup) {
                    this.__wakeup.call(this, args);
                }

                // Call the constructor method
                // with converted arguments
                this.init && this.init.apply(this, args);

                // Add instance to stack
                Class._instances.push(this);
            }
        };

        // Easy, cross-browser way to detect classes
        Class._isClass = true;

        // Instances of this class
        Class._instances = [];

        // Inheritors of this class
        Class._inheritors = [];

        // Global observers of this class
        Class._observers = {};

        // Populate our constructed prototype object
        Class.prototype = prototype;

        // Enforce the constructor to be what we expect
        Class.prototype.constructor = Class;

        // Add class as inheritor of this
        this._inheritors.push(Class);
        Class.getInstances = function(inheritors) {
            var instances = this._instances;

            // If requested, append instances of inheritors
            if (inheritors) {
                for (var i = 0; i < this._inheritors.length; ++i) {
                    instances = instances.concat(this._inheritors[i].getInstances(true));
                }
            }

            return instances;
        };

        /**
         * Observe event across all instances.
         * @param {String} event
         * @param {function} observer
         * @return {Class}
         */
        Class.observe = function(event, observer) {
            // Allow specifying multiple events at once
            if (event.indexOf(' ') != -1) {
                var events = event.split(' ');
                for (var i = 0; i < events.length; i++)
                    this.observe(events[i], observer);
                return this;
            }

            // No observer set yet
            if (!(event in this._observers)) {
                this._observers[event] = [];
            }

            this._observers[event].push(observer);

            return this;
        };

        // And make this class extendable
        Class.extend = extend.bind(Class);

        return Class;
    };

    /**
     * Extend the first class in "candidates" that is available.
     * If not candidate is found, extend base class
     *
     * @param  {string[]} candidates    Array with candidates to extend
     * @param  {object} object          Class definition
     * @return {function}               Returns the new class constructor
     */
    this.extendAvailable = function(candidates, object, parentize) {

        // Iterate through candidates
        for (var i = 0; i < candidates.length; i++) {

            if (typeof this[candidates[i]] == 'function' && this[candidates[i]].name == 'Class') {
                var newObject = this[candidates[i]].extend(object);
                // Make overridden object available to the parent context
                if (parentize)
                    this[candidates[i]].prototype._window[candidates[i]] = newObject;
                return newObject;
            }

        }

        return this.Class.extend(object);

    };
};

buildClass.apply(window);

export default buildClass;
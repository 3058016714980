import {
    COLLECTION_ELEMENTS,
    COLLECTION_ITEM_ELEMENT,
    COLLECTION_DATA_BINDING,
    COLLECTION_CONTAINER_ELEMENT,
} from './constants';

/**
 * check if an element has a collection
 * @param {ElementBase} element
 * @returns {Boolean}
 */
export const isCollectionElement = element => {
    return element ? COLLECTION_ELEMENTS.includes(element.getType()) : false;
};

export const isCollectionContainer = element =>
    element && element.getType() === COLLECTION_CONTAINER_ELEMENT ? true : false;

export const isCollectionItem = element =>
    element && element.getType() === COLLECTION_ITEM_ELEMENT ? true : false;

/**
 * collection data bindings may contain HTML, encode it
 * @param {string} input
 * @return {string}
 */
export const encodeBinding = (input = {}) => {
    return window.escape(JSON.stringify(input));
};

/**
 * collection data may contain HTML, encode it
 * @param {string} input
 * @return {JSON}
 */
export const decodeBinding = (input = '{}') => {
    return JSON.parse(window.unescape(input));
};

/**
 * Find first text containing node in a DOM tree
 * @param {HTMLElement} el
 * @returns {Element|null}
 */
export const findTextNode = (el) => {

    if (el.nodeType === Node.TEXT_NODE) {
        return el.parentNode;
    } else if (el.childNodes.length === 0) {
        return el;
    }

    let ret = null;

    Object.values(el.childNodes).some(child => {
        ret = findTextNode(child);
        return ret !== null;
    });

    return ret;
};

/**
 * set data bindings
 * @param {Object} data
 * @param {Element} element
 * @returns {HTMLElement}
 */
export const setDataBinding = (element, data = {}) => {
    element.getElement().attr(COLLECTION_DATA_BINDING, encodeBinding(data));
    return element;
};

/**
 * @param {ElementBase|jQuery} element
 * @returns {object}
 */
export const getDataBinding = (element = null) => {
    let $element;
    if (!(element instanceof ElementBase)) {
        $element = element;
    } else {
        $element = element.getElement();
    }

    if (!$element) {
        $element = element.getMainElement();
    }

    if (!$element[0]) {
        return {};
    }

    const binding = $element[0].getAttribute(COLLECTION_DATA_BINDING);
    if (binding) {
        return decodeBinding(binding);
    }

    let boundElement = $element[0].firstChild;
    // Jump through Froala div's
    if (boundElement && boundElement.classList && boundElement.classList.contains('fr-wrapper')) {
        boundElement = boundElement.firstChild.firstChild;
    }
    if (boundElement && boundElement.hasAttribute && boundElement.hasAttribute(COLLECTION_DATA_BINDING)) {
        return decodeBinding(boundElement.getAttribute(COLLECTION_DATA_BINDING));
    }

    return {};
};

/**
 * get first collection item found
 * @param {Element} element
 */
export const getCollectionItem = (element) => {
    if (element.getType() === COLLECTION_ITEM_ELEMENT) {
        return element;
    }

    let collectionItemElement = element.getParent();

    while (collectionItemElement && collectionItemElement.getType() !== COLLECTION_ITEM_ELEMENT) {
        collectionItemElement = collectionItemElement.getParent();
    }

    return collectionItemElement;
};

export const parseQueryString = (hashQueryString) => {
    const hashParams = {};

    if (!hashQueryString) {
        return hashParams;
    }

    hashQueryString.split('&').forEach(variable => {
        const pair = variable.split('=');
        const key = decodeURIComponent(pair[0]), value = decodeURIComponent(pair[1]);
        const checkArray = key.match(/(\w+)\[([a-zA-Z0-9._-]+)\]/);
        if (null !== checkArray) {
            if (!hashParams.hasOwnProperty(checkArray[1])) {
                hashParams[checkArray[1]] = [];
            }
            hashParams[checkArray[1]].push({key: checkArray[2], value});
            return;
        }
        hashParams[key] = value;
    });

    return hashParams;
};
/**
 *
 * @export
 * @param {Object} file
 * @returns {string}
 */
export const getFileSizePath = (file) => file.getSizePath(file.getDisplayWidth() || 'default');
/**
 *
 * @export
 * @param {Object} file
 * @returns {string}
 */
export const getFullSizeFile = (file, size) => file.getUrl().replace(/\/images\/[^\/]+\//, '/images/' + size + '/');

export const replaceCSSName = str => (str || '').replace(/[^_a-zA-Z0-9-]/g, '');

//Get closest collection item
export const findClosestCollectionItem = (element) => {
    if (element.getType() === 'collection-item') {
        return element;
    }

    if (!element.getParent()) {
        return null;
    }

    return findClosestCollectionItem(element.getParent());
};

import ImageUtils from '@app/web/webcard/js/helpers/imageutils';

/**
 * @file
 * Slider element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

import SliderTrait from './traits/slider';

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementSlider
 * @extends Element
 */
const ElementSlider = (window.ElementSlider || window.ElementBase).extend(/** @lends ElementSlider.prototype */ {

    ...SliderTrait(),

    setSrcset: function (elm, file, width, retinaImages) {
        if (!retinaImages) {
            this.removeSrcset(elm);
            return;
        }

        elm.setAttribute('srcset', ImageUtils.generateSrcsetUrls(file, retinaImages, width));
    },

    removeSrcset: function (elm) {
        elm.removeAttribute('srcset');
        elm.removeAttribute('sizes');
    },

    initSlider(currentSlide = null) {
        // (Re-)fetch the settings and remember them
        this.settings = this.getSliderSettings();

        let isBackground = this.getIsBackground();

        // If it's already initialized, un-initialize
        if (this.slick) {
            if (currentSlide === null) {
                currentSlide = this.slick.currentSlide;
            }
            if (this.settings.slidesToShow >= this.slick.countSlides) {
                currentSlide = 0;
            }
            this.resetSlider();
        }

        // Remove old flexslider or slick instances
        // or any other trash floating around
        this.$slider.empty().removeClass('slick-initialized slick-slider');

        const configuration = this.getSlickConfig(currentSlide);

        // Attach all the slides
        for (var i = 0; i < this.settings.items.length; i++) {

            var $item = this.getViewport().jQuery('<div class="ed-slider-item"></div>');

            var image, url;
            if (typeof editor !== 'undefined' && editor && 'getMediaManager' in editor) {
                image = editor.getMediaManager().getFileByUrl(this.settings.items[i].image);
            } else {
                image = new File(this.settings.items[i].image)
            }

            const newWidth = isBackground ? this.getViewport().getBackgroundDefaultWidth() : this.settings.insideContainer ?this.getViewport().getImageContainerWidth() : this.getViewport().getImageDefaultWidth();

            if (isBackground || ('height' in this.settings)) { // v2 slider
                $item.addClass('bg-' + (this.settings.items[i].orientation || 'cc'));
            }

            url = image.getImageSize(newWidth);

            var $img = this.getViewport().jQuery('<img />')
                .attr(this.settings.lazyLoad ? 'data-lazy' : 'src', url)
                .attr('alt', (this.settings.items[i].title ? this.settings.items[i].title : ''));

            $img.appendTo($item).on('load', (function (self) {
                return function () {
                    self.notify('load', this); // this is the image
                };
            })(this));

            this.setSrcset($img[0], image, newWidth, this.settings.retinaImages);

            if (this.settings.items[i].title) {
                this.getViewport().jQuery('<div class="ed-slider-text"></div>').html(this.settings.items[i].title).appendTo($item);
            }

            if (this.settings.enlarge) {
                $item.wrapInner('<a href="' + image.getLightboxImage() + '"></a>');
            }

            $item.appendTo(this.$slider);
        }

        // Determine arrow/dots position
        if (isBackground) {
            configuration.appendArrows = this.getParent().$section;
            configuration.appendDots = configuration.appendArrows;
        }

        // Cache the slides
        this.$items = this.getViewport().jQuery('> .ed-slider-item', this.$slider);

        this.initSlick(configuration);

        if (this.settings.enlarge) {
            this.initEnlargedGallery();
        }

        this.fixSlick();
    },

    initEnlargedGallery() {
        // Wait for magnific to load
        this.getViewport().promise('api.magnific.ready', () => {

            this.$slider.on('click', '.ed-slider-item', (e) => {
                e.preventDefault();

                // Rotate items array, so the enlarged gallery starts with currently displayed slide/image
                const { items } = this.settings;
                let cur = parseInt(e.currentTarget.dataset.slickIndex);
                if (Number.isNaN(cur)) {
                    cur = this.slick.currentSlide;
                }
                cur = (cur + items.length) % items.length;
                const rotatedItems = [
                    ...items.slice(cur, items.length),
                    ...items.slice(0, cur)
                ];

                this.getViewport().jQuery.magnificPopup.open({
                    items: rotatedItems.map((item) => {
                        return {
                            src: item.image,
                            type: 'image'
                        }
                    }),
                    tLoading: '...',
                    mainClass: 'mfp-img-mobile',
                    gallery: {
                        enabled: true,
                        arrows: true,
                        navigateByImgClick: true,
                        preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                        tPrev: '',
                        tNext: ' ',
                        tCounter: '<span class="mfp-counter">%curr%/%total%</span>' // markup of counter
                    },
                    callbacks: {
                        afterChange: function () {
                            this.ev.triggerHandler('mfpBuildControls');
                        }
                    }
                });
            });
        });

        // Require magnific
        this.getViewport().requireMagnific();
    }
});


export default ElementSlider;

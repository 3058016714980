/**
 * @file
 * Main Store element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

const ElementEcwidCart = (window.ElementEcwidCart || window.ElementBase).extend(ElementEcwidStore.prototype)
    .extend({
        doWidgetWakeup: function() {
            var type = this.type || this.getStoreWidgetType();
            this.getViewport().promise(
                'api.ecwid.ready.' + type,
                function() {
                    this.setStoreElements();
                }.bind(this)
            );
        },
        doElementWakeUp: function() {
            Ecwid.init();
        }
    });


export default ElementEcwidCart;

/* We setup the object inside a function to
   return a new object everytime it is called,
   preventing accidental overrides */
const SliderTrait = () => ({
    /**
     * Holds the slider container
     * @type {jQuery}
     */
    $slider: null,

    /**
     * Holds the slick instance
     * @type {Slick}
     */
    slick: false,

    /**
     * Initialize the slider.
     * Collect the slider container, require SlickSlider and
     * initialize it
     *
     * @override
     * @return {this}
     */
    wakeup() {
        this.$slider = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);

        this.getViewport().promise('api.slick.ready', () => {
            this.updateSlider();
        });

        // When a parent element is slide-animated force update this slider
        this.getViewport().observe('animation.start', (element, reverse) => {
            if (reverse || ['slide', 'overlay'].indexOf(element.getAnimation().type) === -1 || (this !== element && !this.hasParent(element))) {
                return;
            }

            // Show the animated element, update this slider and hide element again

            // Get initial hidden status
            var hidden = (element.getAnimationTarget().css('display') == 'none');

            // Show element
            element.getAnimationTarget().show();

            // Update this slider
            this.update();

            // Hide if it was hidden before
            if (hidden) {
                element.getAnimationTarget().css('display', 'none');
            }
        });

        this.getViewport().requireSlick();

        return this;
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return '> .slider-container';
    },

    /**
     * Get main element
     * @return {jQuery}
     */
    getMainElement() {
        return this.$slider || this.$element;
    },

    /**
     * Update the slider.
     *
     * @override
     * @return {this}
     */
    update() {
        return this.updateSlider();
    },

    /**
     * (Re)start autoplay (only if autoplay is active)
     *
     * @return {this}
     */
    play() {
        if (this.slick && this.settings && this.settings.autoplay) {
            this.slick.play();
        }
        return this;
    },

    /**
     * Pause autoplay
     *
     * @return {this}
     */
    pause() {
        if (this.slick) {
            this.slick.pause();
        }
        return this;
    },

    /**
     * Go to the previous slide
     *
     * @return {this}
     */
    prev: function () {
        if (this.slick) {
            this.slick.prev();
        }
        return this;
    },

    /**
     * Go to the next slide
     *
     * @return {this}
     */
    next: function () {
        if (this.slick) {
            this.slick.next();
        }
        return this;
    },

    /**
     * Get or set the current slide index
     *
     * @param {number} [current] Set the current slide to "current". Otherwise just return the current slides index
     * @return {number|this}
     */
    current: function (current) {
        if (!this.slick) {
            return this;
        }

        // Get current slide
        if (typeof current === 'undefined') {
            return this.slick.slickCurrentSlide();
        }

        // Set current slide
        this.slick.goTo(current);
        return this;
    },

    getSlickConfig(currentSlide) {
        // Transform the speed values
        this.settings.autoplaySpeed = (this.settings.autoplaySpeed.indexOf('ms') != -1 ? parseInt(this.settings.autoplaySpeed) : (parseFloat(this.settings.autoplaySpeed) * 1000));
        this.settings.animationSpeed = (this.settings.animationSpeed.indexOf('ms') != -1 ? parseInt(this.settings.animationSpeed) : (parseFloat(this.settings.animationSpeed) * 1000));

        return {
            accessibility: false, // Arrow key navigation
            adaptiveHeight: this.settings.adaptiveHeight,
            arrows: this.settings.nav,
            prevArrow: '<button type="button" data-role="none" class="slick-prev" tabindex="0" role="button"></button>',
            nextArrow: '<button type="button" data-role="none" class="slick-next" tabindex="0" role="button"></button>',
            autoplay: this.settings.autoplay,
            autoplaySpeed: this.settings.autoplaySpeed,
            dots: this.settings.dots,
            draggable: false,
            fade: (this.settings.animation == 'fade'),
            focusOnSelect: false,
            infinite: this.settings.loop,
            initialSlide: currentSlide,
            mobileFirst: false,
            pauseOnHover: this.settings.pauseOnHover,
            pauseOnFocus: this.settings.pauseOnHover,
            pauseOnDotsHover: this.settings.pauseOnHover,
            respondTo: 'window', // Used for breakpoints
            responsive: this.settings.responsive, // Used for breakpoints
            slide: '',
            slidesToShow: this.settings.slidesToShow,
            slidesToScroll: this.settings.slidesToScroll,
            rows: this.settings.rows,
            slidesPerRow: this.settings.slidesPerRow,
            lazyLoad: this.settings.lazyLoad,
            speed: this.settings.animationSpeed,
            swipe: true,
            swipeToSlide: false,
            touchMove: true,
            touchThreshold: 5,
            useCSS: true,
            useTransform: true,
            variableWidth: this.settings.variableWidth,
            centerMode: this.settings.centerMode,
            centerPadding: this.settings.centerPadding,
            vertical: this.settings.animation == 'slide' && this.settings.direction == 'vertical',
            verticalSwiping: false
        };
    },

    initSlick(configuration) {
        this.$slider.slick(configuration).on('afterChange', () => {
            this.notify('slide');
        }).on('beforeChange', () => {
            this.notify('slide.before');
        });

        // Get the slick object.
        // Opens the API for foreigners
        this.slick = this.$slider.slick('getSlick');

        // Assign a class that represents if we've dots
        this.$slider[(this.settings.dots ? 'addClass' : 'removeClass')]('has-dots');
    },

    /**
     * Bind to visibilitychange event to fix a bug in slick
     * which starts autoplay when window is re-focused
     */
    fixSlick() {
        if (typeof editor === 'undefined' || !editor) {
            return;
        }

        this.getViewport().jQuery(document).off('visibilitychange.slickFix mozvisibilitychange.slickFix webkitvisibilitychange.slickFix');
        this.getViewport().jQuery(document).on('visibilitychange.slickFix mozvisibilitychange.slickFix webkitvisibilitychange.slickFix', () => {
            if (this.editing)
                this.pause();
        });
    },

    getSlideIndex() {
        return this.slick ? this.slick.currentSlide : 0;
    },

    /**
     * Get the slider settings
     *
     * @return {object}
     */
    getSliderSettings() {
        let parameters,
            old = this.$slider.data('params');
        try {
            parameters = JSON.parse(this.$slider.attr('data-parameters'));
            old = null; // Prefer the new settings
        } catch (e) {
            parameters = {};
        }

        const settings = Object.assign({
            items: [],
            adaptiveHeight: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 1,
            slidesPerRow: 1,
            height: null,
            animation: 'slide',
            animationSpeed: '800ms',
            direction: 'horizontal',
            autoplay: true,
            autoplaySpeed: '5s',
            pauseOnHover: true,
            loop: true,
            nav: !this.getIsBackground(),
            dots: false,
            enlarge: false,
            retinaImages: false,
            lazyLoad: 'progressive',
            variableWidth: false,
            centerMode: false,
            centerPadding: '0px'
        }, parameters);

        // Make it compatible with the legacy image slider format
        if (old) {
            settings.animation = old.animation;
            settings.animationSpeed = (old.animationSpeed || 800) + 'ms';
            settings.autoplay = !!old.slideshow;
            settings.autoplaySpeed = (old.slideshowSpeed || 5000) + 'ms';
            settings.loop = !!old.animationLoop;
            settings.nav = !!old.directionNav;
            settings.dots = !!old.controlNav;
            settings.enlarge = !!old.enlarge;
            settings.retinaImages = false;

            // Find the items
            this.$slider.find('.ed-slider-item:not(.clone)').each(function () {
                var item = {};
                item.image = this.getViewport().jQuery('img', this).attr('src');
                if (!item.image) {
                    // Look for an image in the background
                    var bgImage = this.getViewport().jQuery(this).css('background-image');
                    item.image = (bgImage.match(/url\((?:'|")?(.*?)(?:'|")?\)/) || ['', false])[1];
                    item.orientation = (this.getViewport().jQuery(this).attr('class').match(/bg-([a-z]{2})/) || ['', 'cc'])[1];
                }
                item.title = this.getViewport().jQuery('.ed-slider-text', this).text() || '';
                if (item.image)
                    settings.items.push(item);
            });

            // Transfer the settings into the data-attribute,
            // to avoid regrabbing all the items
            this.$slider.attr('data-parameters', JSON.stringify(settings));

            // Remove the old attribute
            this.$slider.removeAttr('data-params');

        }

        return settings;
    },

    /**
     * Update the slider.
     * If deep is true, completely reinitialize the slider.
     *
     * @param  {boolean} [deep=false] If set to true, the slider will be completely reinitialized
     * @return {this}
     */
    updateSlider(deep, initialSlide = 0) {
        // Slick is not defined,
        // skip here, it's on its way
        if (!('slick' in this.$slider)) {
            return;
        }

        if (!this.slick || deep) {
            this.notify('update.before');
            this.initSlider(initialSlide);
            this.notify('update.after');
        }

        // Use requestAnimationFrame to to prevent wrong width calculation
        window.requestAnimationFrame(() => {
            // Refresh
            this.slick.setPosition();
            this.slick.setOption(null, null, true);
        });

        if (this.editing) {
            this.pause();
        }

        return this;
    },

    resetSlider() {
        if (!this.slick) {
            return;
        }
        this.slick.unslick();
        this.slick = null;
    }
});

export default SliderTrait;
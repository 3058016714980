/**
 * @file
 * Reference element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

import ElementUtils from "../helpers/elementutils";

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementReference
 * @extends Element
 */
const ElementReference = (window.ElementReference || window.ElementBase).extend(/** @lends ElementReference.prototype */ {

    /**
     * Holds the target element object
     * @type {Element}
     */
    target: null,

    /**
     * Initialize the target element type. This means, instantiate another
     * Element with the same DOMElement as this instance to activate its features
     * (like parallax scrolling effect if the referenced element is a section or grid)
     *
     * @return {this}
     */
    initializeTarget: function() {

        // Destroy old target, if any
        if (this.target) {
            this.target.destroy();
            // Re-attach "this"
            this.$element[0].element = this;
        }

        // Get original type
        var elementType = ( this.$element.attr('class').match(/ed-reference-([a-z\-]+)/) || ['', ''] )[1];
        if (elementType === 'reference') {
            console.warn('Can\'t reference a reference, aborting...');
            return this;
        }

        if (elementType) {
            const className = ElementUtils.getTypeClassName(elementType);

            // Initialize only if class exists.
            if (className in this.getViewport().window) {

                // Initialize in dry mode
                var win = this.getViewport().window;
                if ('getEditor' in this && this.getEditor()) {
                    this.target = new win[className](this.getEditor(), this.$element[0], true);
                } else {
                    this.target = new win[className](this.$element[0], true);
                }

            }

        }

        return this;

    },

    /**
     * Compare two elements for equality.
     *
     * @override
     * @param {Element} element Element to compare against
     * @return {boolean}        Returns true, if element is the same element as "this".
     */
    is: function(element) {
        return ( this == element || this.target == element );
    },


    /**
     * Initializes the reference.
     *
     * @override
     * @return {this}
     */
    wakeup: function() {

        this.initializeTarget();

        return this;

    },

    /**
     * Redirect the update call to the target element
     *
     * @override
     * @return {this}
     */
    update: function() {

        if (this.target) {
            this.target.update();
        }

        return this;
    },

    /**
     * Redirects the animate call to the target instance,
     * overriding it's context with this (the reference)
     *
     * @override
     * @return {this}
     */
    animate: function(reverse, trigger) {

        if (this.target) {
            this.target.animate.call(this, reverse, trigger);
        }

        // Don't do anything
        return this;

    },

    /**
     * Return the animation target of the target element
     *
     * @override
     * @return {jQuery} The animation target of the referenced element
     */
    getAnimationTarget: function() {

        if (this.target) {
            return this.target.getAnimationTarget();
        }

        return this.$element;

    },

});


export default ElementReference;

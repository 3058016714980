/**
 * @file
 * Video element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementVideo
 * @extends Element
 */
const ElementVideo = (window.ElementVideo || window.ElementBase).extend(/** @lends ElementVideo.prototype */ {

    /**
     * Holds the video iframe
     * @type {jQuery}
     */
    $video: null,

    /**
     * Collect the video iframe
     *
     * @return {this}
     */
    wakeup: function() {
        this.$video = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);

        return this;
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return 'video';
    },

    /**
     * Get the main representing element.
     * The <img> tag for an image, for example.
     * Used to draw the outline
     * @return {jQuery}
     */
    getMainElement: function() {
        if (this.$video && this.$video.length) {
            return this.$video;
        }

        return this.$element;
    },

});


export default ElementVideo;

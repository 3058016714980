import { COLLECTION_ITEM_ELEMENT } from './constants';

const ElementCollectionItem = (window.ElementCollectionItem || window.ElementBase).extend({
    type: COLLECTION_ITEM_ELEMENT,

    $inner: null,

    wakeup() {
        this.$inner = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return '> .inner';
    },

    /**
     * @inheritDoc
     */
    getMainElement() {
        return this.$inner || this.$element;
    }
});

export default ElementCollectionItem;
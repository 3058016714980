/**
 * @file
 * Video element
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementAudio
 * @extends Element
 */
const ElementAudio = (window.ElementAudio || window.ElementBase).extend(/** @lends ElementAudio.prototype */ {

    /**
     * Holds the video iframe
     * @type {jQuery}
     */
    $audio: null,

    /**
     * Collect the video iframe
     *
     * @return {this}
     */
    wakeup: function() {
        this.$audio = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);

        return this;
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return 'audio';
    },

    /**
     * Get the main representing element.
     * The <img> tag for an image, for example.
     * Used to draw the outline
     * @return {jQuery}
     */
    getMainElement: function() {
        if (this.$audio && this.$audio.length) {
            return this.$audio;
        }

        return this.$element;
    },

});


export default ElementAudio;

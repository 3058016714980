/**
 * @file
 * Defines the Viewport class.
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * The viewport class wraps convenience functions as getWidth, getHeight, etc.
 * and serves as a global event transmitter for screen actions (resize, scroll, etc.) and animations.
 * An instance of this class is globally available as "viewport".
 *
 * @class Viewport
 * @fires "scroll"
 * @fires "scroll.start"
 * @fires "resize"
 * @fires "load"
 * @fires "animation.start"
 * @fires "animation.end"
 * @fires "animation.step"
 * @example
 * viewport.getWidth(); // Get current window width
 * viewport.getHeight(); // Get current window height
 * viewport.getScrollTop(); // Get current scroll top
 * viewport.observe('resize', function() { ... }); // Sets up an event listener for the (window) resize event
 * viewport.observe('scroll', function() { ... }); // Sets up an event listener for the (window) scroll event
 * viewport.observe('animation.end', function(element, reverse, trigger) { ... }); // Sets up an event listener
 */
var Viewport = Class.extend(
    /** @lends Viewport.prototype */ {
        /**
         * Holds the jQuery instance of the website
         * @type {jQuery}
         */
        jQuery: jQuery,

        /**
         * Viewport window
         * @type {Window}
         */
        window: window,

        /**
         * Cache jQuery window object
         * @type {jQuery}
         */
        $window: null,

        /**
         * Current page width
         * @type {number}
         */
        width: 0,

        /**
         * Current page height
         * @type {number}
         */
        height: 0,

        /**
         * Window scroll top
         * @type {number}
         */
        scrollTop: 0,

        /**
         * Window scroll bottom (lower corner)
         * @type {number}
         */
        scrollBottom: 0,

        /**
         * Window scroll left
         * @type {number}
         */
        scrollLeft: 0,

        /**
         * Window scroll right (right corner)
         * @type {number}
         */
        scrollRight: 0,

        /**
         * Default width for images in sliders, galleries, etc.
         * @type {number}
         * @const
         */
        imageDefaultWidth: 1920,

        /**
         * Default width for images inside a container and for sliders, galleries
         * @type {number}
         * @const
         */
        imageContainerWidth: 1024,

        /**
         * Default width for background images
         * @type {number}
         * @const
         */
        backgroundDefaultWidth: 1920,

        /**
         * Determines if requestAnimationFrame is supported (to provide a fallback).
         * Is hardcoded to "false", as we currently don't want to use the requestAnimationFrame feature
         * @type {boolean}
         * @const
         */
        animationFrameSupported: false, //( 'requestAnimationFrame' in this.window ),

        /**
         * Requested frame types which will be fired
         * when a new animation frame is available
         * @type {string[]}
         */
        requestedAnimationFrames: [],

        /**
         * Determines if an animation frame should be requested
         * as soon as the mousewheel is used. Helps with lags.
         * @type {boolean}
         * @const
         */
        requestFrameOnMousewheel: true,

        /**
         * Constructor function that initializes the viewport
         *
         * @return {this}
         * @constructs Viewport
         */
        init: function() {
            // Cache window
            this.$window = this.jQuery(this.window);

            // Init internal values
            this.update();

            // Bind events to the window
            this.bindEvents();

            // Init overlay links
            this.initOverlayLinks();

            // Get device configuration
            this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                this.window.navigator.userAgent
            );

            // Determines if device is touch-enabled
            this.isTouch = !!(
                'ontouchstart' in this.window || this.window.navigator.msMaxTouchPoints
            );
            if (this.isTouch) {
                // Reset value and bind an event handler to check if touch is actually used
                // (no need to bind event listener if touch isn't supported)
                this.isTouch = false;
                var checkTouchSupport = function() {
                    this.isTouch = true;
                    this.window.removeEventListener('touchstart', checkTouchSupport);
                }.bind(this);
                this.window.addEventListener('touchstart', checkTouchSupport);
            }

            return this;
        },

        /**
         * Update internal values like the window width, scroll top, etc.
         *
         * @return {this}
         */
        update: function() {
            // Get all important values
            this.width = this.$window.width();
            this.height = this.$window.height();

            this.scrollTop = this.$window.scrollTop();
            this.scrollBottom = this.scrollTop + this.height;
            this.scrollLeft = this.$window.scrollLeft();
            this.scrollRight = this.scrollLeft + this.width;

            return this;
        },

        /**
         * Bind all needed events to the window
         *
         * @return {this}
         */
        bindEvents: function() {
            // Add resize listener
            this.window.addEventListener(
                'resize',
                function(event) {
                    // Calling the resize function on an jQuery object
                    // could cause an endless loop
                    if (event.target !== this.window) {
                        return;
                    }

                    // Update inner values
                    this.width = this.$window.width();
                    this.height = this.$window.height();

                    // Notify about the resize
                    this.notify('resize');
                }.bind(this)
            );

            // Add scroll listener
            this.window.addEventListener(
                'scroll',
                function() {
                    // Update inner values
                    this.scrollTop = this.$window.scrollTop();
                    this.scrollBottom = this.scrollTop + this.height;
                    this.scrollLeft = this.$window.scrollLeft();
                    this.scrollRight = this.scrollLeft + this.width;

                    // Simulate scroll start event
                    if (!this.scrollStartTimeout) {
                        this.notify('scroll.start');
                    } else {
                        this.window.clearTimeout(this.scrollStartTimeout);
                    }

                    this.scrollStartTimeout = this.window.setTimeout(
                        function() {
                            this.scrollStartTimeout = false;
                        }.bind(this),
                        1000
                    );

                    // Notify about scroll, please don't bind any animations to this
                    this.notify('scroll');

                    // Request animation frame
                    this.requestAnimationFrame('scroll');
                }.bind(this)
            );

            // Add load listener
            this.window.addEventListener(
                'load',
                function() {
                    // Notify about window.load
                    this.notify('load');
                }.bind(this)
            );

            // Re-grab window width and height after all "load"
            // and "resize" event handlers are processed
            this.observe(
                'load:after resize:after',
                function() {
                    // Re-grab window width and height, as they could have changed
                    this.width = this.$window.width();
                    this.height = this.$window.height();
                }.bind(this)
            );

            if (this.requestFrameOnMousewheel) {
                /**
                 * Request a scroll frame when the mouse wheel is used.
                 * Note that this doesn't necessarily means more calculation costs,
                 * the frame is only earlier requested (see the animationFrameRequested part)
                 */
                this.window.addEventListener(
                    'mousewheel',
                    function() {
                        this.requestAnimationFrame('scroll');
                    }.bind(this)
                );
                this.window.addEventListener(
                    'DOMMouseScroll',
                    function() {
                        this.requestAnimationFrame('scroll');
                    }.bind(this)
                );
            }

            return this;
        },

        /**
         * Initialize overlay links.
         *
         * @return {this}
         */
        initOverlayLinks: function() {
            var self = this;

            this.jQuery(this.window.document).on('click', '.wv-link-overlay', function(
                event
            ) {
                event.preventDefault();

                // Get target
                var href = this.getAttribute('href');
                if (!href) {
                    return;
                }

                // Make sure magnific is loaded
                self.promise(
                    'api.magnific.ready',
                    function() {
                        // Open as magnific popup
                        this.jQuery.magnificPopup.open({
                            items: {
                                src: href,
                                type: 'iframe'
                            },
                            iframe: {
                                patterns: {
                                    youtube: {
                                        index: 'youtube.com',
                                        id: 'v=',
                                        src:
                                            '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
                                    },
                                    vimeo: {
                                        index: 'vimeo.com/',
                                        id: '/',
                                        src: '//player.vimeo.com/video/%id%?autoplay=1'
                                    },
                                    gmaps: {
                                        index: '//maps.google.',
                                        src: '%id%&output=embed'
                                    }
                                }
                            },
                            fixedContentPos: false,
                            callbacks: {
                                open: function() {
                                    this.notify('overlay.open');
                                }.bind(this),
                                close: function() {
                                    this.notify('overlay.close');
                                }.bind(this)
                            }
                        });
                    }.bind(self),
                    true
                ).requireMagnific();
            });

            return this;
        },

        /**
         * Request an animation frame of "type".
         * As soon as a new animation frame is available, an event with
         * the name "frame.type" will be fired
         *
         * @param {string} type The frame type requested
         * @return {this}
         */
        requestAnimationFrame: function(type) {
            // Fallback for non supported browsers
            if (!this.animationFrameSupported) {
                this.notify('frame.' + type);
                return this;
            }

            // Add type to requested frames
            if (this.requestedAnimationFrames.indexOf(type) === -1) {
                this.requestedAnimationFrames.push(type);
            }

            // If no animation frame was requested
            if (!this.animationFrameRequested) {
                // Remember we requested a frame (reset in the frame itself)
                this.animationFrameRequested = true;

                // Request animation frame
                this.window.requestAnimationFrame(this.animationFrame.bind(this));
            }

            return this;
        },

        /**
         * Callback for requestAnimationFrame which "executes" (fires events of)
         * all our requested animation frame types
         *
         * @callback
         */
        animationFrame: function() {
            // Reset frame requested
            this.animationFrameRequested = false;

            // Execute all animation frame handlers
            for (var i = 0; i < this.requestedAnimationFrames.length; i++) {
                this.notify('frame.' + this.requestedAnimationFrames[i]);
            }

            // Reset requested frames
            this.requestedAnimationFrames = [];

            return this;
        },

        /**
         * Get the current window width
         *
         * @return {number} Window width
         */
        getWidth: function() {
            return this.width;
        },

        /**
         * Get the current window height
         *
         * @return {number} Window height
         */
        getHeight: function() {
            return this.height;
        },

        /**
         * Get the current scroll top
         *
         * @return {number} Scroll top
         */
        getScrollTop: function() {
            return this.scrollTop;
        },

        /**
         * Get the current scroll bottom (scroll top + window height)
         *
         * @return {number} Scroll bottom
         */
        getScrollBottom: function() {
            return this.scrollBottom;
        },

        /**
         * Get the current scroll left
         *
         * @return {number} Scroll left
         */
        getScrollLeft: function() {
            return this.scrollLeft;
        },

        /**
         * Get the current scroll right (scroll left + window width)
         *
         * @return {number} Scroll right
         */
        getScrollRight: function() {
            return this.scrollRight;
        },

        /**
         * Set scroll top
         *
         * @param {number} scrollTop
         *
         * @return {this}
         */
        setScrollTop: function(scrollTop) {
            this.$window.scrollTop(scrollTop);

            // Re-read because browser might have corrected the value
            this.scrollTop = this.$window.scrollTop();

            return this;
        },

        /**
         * Set scroll left
         *
         * @param {number} scrollLeft
         *
         * @return {this}
         */
        setScrollLeft: function(scrollLeft) {
            this.$window.scrollLeft(scrollLeft);

            // Re-read because browser might have corrected the value
            this.scrollLeft = this.$window.scrollLeft();

            return this;
        },

        /**
         * Returns if the device is touch enabled
         *
         * @return {boolean} Touch enabled
         */
        getIsTouch: function() {
            return this.isTouch;
        },

        /**
         * Returns if the device is a mobile device
         *
         * @return {boolean} Mobile device
         */
        getIsMobile: function() {
            return this.isMobile;
        },

        isEdit() {
            try {
                return this.window.document.body.classList.contains('edit');
            } catch (e) {
                return false;
            }
        },

        isPreview() {
            try {
                return this.window.document.body.classList.contains('preview');
            } catch (e) {
                return false;
            }
        },

        /**
         * Get default image width
         *
         * @return {number} Default image width
         */
        getImageDefaultWidth: function() {
            return this.imageDefaultWidth;
        },

        /**
         * Get container image width
         *
         * @return {number} Default image width
         */
        getImageContainerWidth: function() {
            return this.imageContainerWidth;
        },

        /**
         * Get default background image width
         *
         * @return {number} Default background image width
         */
        getBackgroundDefaultWidth: function() {
            return this.backgroundDefaultWidth;
        },

        /**
         * Scroll the page to "target"
         *
         * @param {Element|HTMLElement|jQuery|number} target    Position in pixels or an element whose offset will be used
         * @param {string} [position='top']                     Screen sector that is scrolled to "target" ('top', 'center' or 'bottom')
         * @param {number} [duration=0]                         Duration of the scroll animation
         * @param {number} [offset=0]                           Offset that is added/subtracted from target position
         * @return {this}
         */
        scrollTo: function(target, position, duration, offset) {
            // Default to top
            if (!position) position = 'top';

            // Default to zero
            if (!offset) offset = 0;

            // Allow passing an element instance
            if (target instanceof ElementBase && 'getMainElement' in target) {
                target = target.getMainElement();
            }

            var originalOffset = offset,
                originalTarget = target;

            // Find fixed elements and add their height to the offset
            this.jQuery('.ed-element, #menu, #header, #navigation')
                .filter(
                    function(index, element) {
                        var $element = $(element);

                        // Make sure element is visible
                        if (!$element.is(':visible')) {
                            return false;
                        }

                        // Check if element is fixed
                        if ($element.css('position') !== 'fixed') {
                            return false;
                        }

                        // Skip if height is >50% of window height
                        // (we don't want to include overlays and stuff like that)
                        if ($element.height() > this.getHeight() / 2) {
                            return false;
                        }

                        return true;
                    }.bind(this)
                )
                .each(
                    function(index, element) {
                        // Only elements which are stuck to top and wide enough to prevent the visibility of target
                        if (
                            parseInt(this.jQuery(element).css('top') || 0) < 10 &&
                            this.jQuery(element).width() >= Math.min(600, this.getWidth())
                        ) {
                            offset -= this.jQuery(element).outerHeight();
                        }
                    }.bind(this)
                );

            // Element mode?
            if (typeof target == 'object') {
                var $target = this.jQuery(target);
                if (!$target.is(':visible')) {
                    // Can't scroll to element as it's not visible
                    return this;
                }
                target = $target.offset().top;

                // Target position
                if (position == 'center') {
                    target += $target.outerHeight() / 2;
                } else if (position == 'bottom') {
                    target += $target.outerHeight();
                }
            }

            // Target position
            if (position == 'center') {
                target -= this.getHeight() / 2;
            } else if (position == 'bottom') {
                target -= this.getHeight();
            }

            // Finally scroll
            this.jQuery('html, body')
                .stop(true)
                .animate(
                    {
                        scrollTop: target + offset
                    },
                    duration || 0,
                    function() {
                        // Correct scroll position in case that elements changed their position while scrolling
                        duration > 0 &&
                            this.scrollTo(originalTarget, position, 0, originalOffset);
                    }.bind(this)
                );

            return this;
        },

        /**
         * Returns if target is (fully) visible
         *
         * @param {Element|HTMLElement|jQuery} target   Element to check against
         * @param {boolean} [section=false]             Can be set to 'top', 'bottom' or 'full' (or true)
         * @return {boolean}                            Returns if section of target is visible
         */
        targetVisible: function(target, section) {
            var $target = target;

            // Allow passing an element instance
            if (target instanceof ElementBase && 'getMainElement' in target) {
                $target = target.getMainElement();
            } else {
                $target = this.jQuery(target);
            }

            // Get target offset
            var offset = $target.offset().top,
                height = $target.height();

            return (
                (!section &&
                    (offset + height > this.getScrollTop() &&
                        offset < this.getScrollBottom())) ||
                (section === true ||
                    (section == 'full' &&
                        (offset > this.getScrollTop() &&
                            offset + height < this.getScrollBottom()))) ||
                (section == 'top' &&
                    (offset > this.getScrollTop() && offset < this.getScrollBottom())) ||
                (section == 'bottom' &&
                    (offset + height > this.getScrollTop() &&
                        offset + height < this.getScrollBottom()))
            );
        },

        /**
         * Get elements on page that are (partially) visible
         *
         * @return {Element[]}
         */
        getVisibleElements: function() {
            // Get all elements
            var $elements = this.jQuery('.ed-element:not(.wv-bg)');

            // Filter them by visibility
            var result = [];
            $elements.each(
                function(element) {
                    var $elm = $(element),
                        offset = $elm.offset(),
                        height = $elm.height();
                    if (
                        offset.top + height > this.getScrollTop() &&
                        offset.top < this.getScrollBottom()
                    ) {
                        result.push($elm[0].element);
                    }
                }.bind(this)
            );

            return result;
        },

        /**
         * Get the topmost element at given coordinates.
         *
         * @param {number} x  X coordinate of current viewport (0 - winWidth)
         * @param {number} y  Y coordinate of current viewport (0 - winHeight)
         *
         * @return {jQuery}
         */
        getElementAtPosition: function(x, y) {
            if (!('elementFromPoint' in this.window.document)) {
                return false;
            }

            if (x == 'center') {
                x = this.getWidth() / 2;
            }
            if (x == 'left') {
                x = 1;
            }
            if (x == 'right') {
                x = this.getWidth() - 1;
            }

            if (y == 'center') {
                y = this.getHeight() / 2;
            }
            if (y == 'top') {
                y = 1;
            }
            if (y == 'bottom') {
                y = this.getHeight() - 1;
            }

            var element = this.window.document.elementFromPoint(x, y);
            if (!element) {
                return false;
            }

            return this.jQuery(element);
        },

        /**
         * Returns if element is positioned fixed
         *
         * @param {jQuery} $element
         *
         * @return {jQuery|boolean} Returns false or the fixed element (e.g. a parent of element)
         */
        getIsFixed: function($element) {
            // Skip at HTML tag
            if ($element.prop('tagName') === 'HTML') {
                return false;
            }
            return $element.css('position') !== 'fixed'
                ? this.getIsFixed($element.offsetParent())
                : $element;
        },

        /**
         * Require a script file, then call a callback when script is successfully loaded
         * Also detects, if a cookie consent banner is present, which blocks scripts from loading and waits for approval
         *
         * @param {string} url              URL of script file
         * @param {function} [callback]     Optional callback that gets called when script is loaded
         * @return {this}
         */
        requireScript: function(url, callback) {
            const loadScript = (url, callback) => {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.addEventListener('load', () => typeof callback === 'function' && callback());

                document.getElementsByTagName('head')[0].appendChild(script);
            }

            // Wait until we have the user approval (GDPR Cookie Consent script-blocking)
            if (
                typeof ThirdPartyScripts !== 'undefined'
                && (window.YETT_WHITELIST||[]).every(pattern => !pattern.test(url))
            ) {
                const _unblock = ThirdPartyScripts.unblock;
                ThirdPartyScripts.unblock = function() {
                    _unblock.apply(this, arguments);
                    loadScript(url, callback);
                }
                return this;
            }

            loadScript(url, callback);
            return this;
        },

        /**
         * Require the Google Maps API and fire an event when it's loaded
         *
         * @fires api.maps.ready
         * @return {this}
         */
        requireMapsApi: function() {
            // Maps API already included
            if (typeof google !== 'undefined' && 'maps' in google) {
                return this.notify('api.maps.ready');
            }

            // It's already on it's way
            if (this.mapsApiRequired) return;

            // Remember we're loading
            this.mapsApiRequired = true;

            // Prefer user-defined API key. set in _global.php
            const key = typeof webcard !== 'undefined' && !webcard.isPreview && webcard.googleMapsApiKey ? webcard.googleMapsApiKey : Viewport.GOOGLE_MAPS_TOKEN;

            this.requireScript(
                '//maps.google.com/maps/api/js?key=' + key,
                function() {
                    this.notify('api.maps.ready');
                }.bind(this)
            );

            return this;
        },

        /**
         * Require SlickSlider and fire an event when it's loaded
         *
         * @fires api.slick.ready
         * @return {this}
         */
        requireSlick: function() {
            // Slick already included
            if ('slick' in $) return this.notify('api.slick.ready');

            // It's already on it's way
            if (this.slickRequired) return;

            // Remember we're loading
            this.slickRequired = true;

            this.requireScript(
                '/webcard/vendor/slick/slick.min.js',
                function() {
                    this.notify('api.slick.ready');
                }.bind(this)
            );

            return this;
        },

        /**
         * Require MagnificPopup and fire an event when it's loaded
         *
         * @fires api.magnific.ready
         * @return {this}
         */
        requireMagnific: function() {
            // Magnific already included
            if ('magnificPopup' in $) return this.notify('api.magnific.ready');

            // It's already on it's way
            if (this.magnificRequired) return;

            // Remember we're loading
            this.magnificRequired = true;

            import('magnific-popup')
                .then(() => this.notify('api.magnific.ready'))
            ;

            return this;
        },

        requireEcwid: function(storeId, type) {
            if ('Ecwid' in window) {
                return this.notify('api.ecwid.ready.' + type);
            }

            this.requireScript(
                'https://app.ecwid.com/script.js?' + storeId + '&data_platform=code',
                function() {
                    this.notify('api.ecwid.ready.' + type);
                }.bind(this)
            );

            return this;
        }
    }
);

// Constants
Viewport.GOOGLE_MAPS_TOKEN = 'AIzaSyDeIJgtGDGbtc2ID6R-fVtSMffEPvlSbSQ';

export default Viewport;

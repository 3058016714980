/**
 * @file
 * Form Button element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementFormButton
 * @extends Element
 */
const ElementFormButton = (window.ElementFormButton || window.ElementBase).extend(/** @lends ElementFormButton.prototype */ {

    /**
     * Holds the button
     * @type {jQuery}
     */
    $button: null,

    /**
     * Collect the button
     *
     * @override
     * @return {this}
     */
    wakeup: function() {
        this.$button = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);

        return this;
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return 'button';
    },

    /**
     * @inheritDoc
     */
    getMainElement() {
        return this.$button || this.$element;
    }

});


export default ElementFormButton;

/**
 * @file
 * Form Captcha element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementFormCaptcha
 * @extends Element
 */
const ElementFormCaptcha = (window.ElementFormCaptcha || window.ElementBase).extend(/** @lends ElementFormCaptcha.prototype */ {

    /**
     * Holds the image
     * @type {jQuery}
     */
    $image: null,

    /**
     * Holds the input
     * @type {jQuery}
     */
    $input: null,

    /**
     * Holds the label
     * @type {jQuery}
     */
    $label: null,

    /**
     * Holds the regenerate link
     * @type {jQuery}
     */
    $regenerate: null,

    /**
     * Determines if render token should be used
     * @type {Boolean}
     */
    withToken: false,

    /**
     * Collect the image and input
     *
     * @override
     * @return {this}
     */
    wakeup: function() {

        this.$image = this.getViewport().jQuery('img', this.$element[0]);
        this.$input = this.getViewport().jQuery('input[type="text"]', this.$element[0]);
        this.$hash = this.getViewport().jQuery('input[type="hidden"]', this.$element[0]);
        this.$label = this.getViewport().jQuery('> label', this.$element[0]);
        this.$regenerate = this.getViewport().jQuery('a.regenerate', this.$element[0]);

        this.$element.on('click', 'a.regenerate', function(event) {
            event.preventDefault();
            this.regenerate();
        }.bind(this));

        // Regenerate on initialize
        this.regenerate();

        return this;

    },

    /**
     * Regenerate captcha
     */
    regenerate: function() {

        let webcard = window.webcard;
        if (!webcard) {
            webcard = this.getViewport().window ? this.getViewport().window.webcard : null;
        }

        // Request new captcha and append
        // color informations (won't be processed in live mode)
        $.ajax({
            type: 'post',
            url: (webcard && webcard.apiHost ? 'https://' + webcard.apiHost : '') + '/form_container/captcha',
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            dataType: 'json',
            data: {
                id: this.getId(),
                token: ( this.withToken ? this.$element.closest('form').find('input[name="_render_token"]').val() : null ),
                color: this.$input.attr('data-color'),
                background: this.$input.attr('data-background'),
                opacity: this.$input.attr('data-opacity')
            },
            success: function(data) {
                this.$image.attr('src', 'data:image/png;base64,' + data.image);
                this.$hash.val(data.hash);
            }.bind(this)
        });

        return this;

    },

});

export default ElementFormCaptcha;

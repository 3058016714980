/**
 * @file
 * Form Upload element
 *
 * @author Malte Sieb <malte.sieb@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementFormUpload
 * @extends Element
 */
const ElementFormUpload = (window.ElementFormUpload || window.ElementBase).extend(/** @lends ElementFormUpload.prototype */ {

    /**
     * Holds the input
     * @type {jQuery}
     */
    $input: null,

    /**
     * Holds the label
     * @type {jQuery}
     */
    $label: null,

    /**
     * Collect the input and label
     *
     * @override
     * @return {this}
     */
    wakeup: function() {
        this.$input = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);
        this.$label = this.getViewport().jQuery('label', this.$element[0]);

        return this;
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return 'input';
    },

    /**
     * @inheritDoc
     */
    getMainElement() {
        return this.$input || this.$element;
    }

});


export default ElementFormUpload;

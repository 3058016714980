/**
 * @file
 * Defines the base Element class.
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * This is the base class for all elements on the page.
 * It's loaded inside the website and in the CMS window, where it gets extended with
 * the edit functionality. As the website, if loaded inside the CMS, inherits all class
 * definitions from the parent window (the CMS window), this class then extends the extended
 * version of itself from the CMS to include the methods needed for edit mode.
 *
 * @class ElementBase
 * @fires "animation.start"
 * @fires "animation.end"
 * @fires "animation.step"
 * @example
 * var element = new ElementHeadline(document.getElementById('element')); // Initializes #element as headline
 * element.getId(); // Get the element ID
 * element.animate(); // Play the animation sequence of the element instance
 */
import 'velocity-animate/velocity';
import 'velocity-animate/velocity.ui';

var ElementBase = window.ElementBase || (window.EditorAwareClass || window.Class).extend(/** @lends ElementBase.prototype */ {

    /**
     * Holds the elements jQuery object
     * @type {jQuery}
     */
    $element: null,

    /**
     * Holds the elements ID
     * @type {number}
     */
    id: null,

    /**
     * Element state
     */
    state: {},

    /**
     * Holds the animation details of the element
     * @type {object}
     * @default
     */
    animation: {
        type: 'none',
        initial: 'hide',
        keepHeight: true,
        duration: '.5s',
        delay: '0s',
        overlay: {
            width: 'auto'
        },
        trigger: '',
        group: {
            name: '',
            behavior: {
                show: '',
                hide: ''
            }
        }
    },

    /**
     * Is true while the element is being animated
     * @type {boolean}
     */
    animating: false,

    /**
     * Is true once the element was animated
     * @type {boolean}
     */
    animated: false,

    /**
     * Constructor function that initializes the element
     *
     * @param {HTMLElement} source    The DOM element
     * @param {boolean} [dry=false]    Defined by the extended element class of CMS. If set to true, initialization of edit functionality will be skipped.
     * @return {this}
     * @constructs ElementBase
     */
    init: function(source, dry) {

        // The element is given as a plain DOM object,
        // not as a jQuery object. This is important because otherwise
        // the Live Mode would give over the element bound to the
        // jQuery instance of the iframe. Any plugins (and .data)
        // only present in the CMS weren't available to the element then.
        this.$element = this.getViewport().jQuery(source);

        // Store this in the DOM element
        this.$element[0].element = this;

        // Bring element to life
        this.wakeup();

        return this;

    },

    destroy: function() {
        this.$element.remove();
        this.$element = null;
    },

    /**
     * Brings the element to life (formally known as "animate"),
     * e.g. initialize sliders, lightboxes and such stuff
     *
     * @abstract
     */
    wakeup: function() {
    },

    /**
     * Update element specific stuff (adjust widths, etc.)
     *
     * @abstract
     */
    update: function() {
    },

    /**
     * Get the element
     *
     * @return {jQuery} The elements jQuery object
     */
    getElement: function() {
        return this.$element;
    },

    /**
     * Get the main representing element.
     * The <img> tag for an image, for example.
     * Amongst others, used to draw the outline
     * @return {jQuery}
     */
    getMainElement: function() {
        return this.$element;
    },

    /**
     * Get the main representing element to apply styles to.
     * The <img> tag for an image, for example.
     * @returns {jQuery}
     */
    getElementForStyling: function() {
        return this.getMainElement();
    },

    /**
     * Get query selector to fetch the main representing element
     * @returns {string|null}
     */
    getSelectorForMainElement() {
        return null;
    },

    /**
     * Return element id
     *
     * @param  {boolean} [plain=false]  Return the ID like it's in the attribute (ed-xxx)
     * @return {number|string}            Returns the ID (as number or string, if plain is true)
     */
    getId: function(plain) {

        if (!this.id) {
            // Retrieve ID from HTML-Attribute
            const id = this.$element.attr('id');
            // Attribute found?
            if (id && id.length)
                this.id = id.replace('ed-', '');
        }

        // User wants the prefixed ID
        if (plain)
            return this.$element.attr('id') || 'ed-' + this.id;

        return this.id;

    },


    /**
     * Get the parent element
     *
     * @return {Element|boolean} The parent Element instance or false, if no parent was found
     */
    getParent: function() {
        var $parent = this.$element.parent().closest('.ed-element');
        if ($parent.length)
            return $parent[0].element;
        return false;
    },

    /**
     * Return all parents as array (closest parent is first)
     *
     * @return {Element[]} Array of all ancestors
     */
    getParents: function() {
        const parents = [];

        let parent = this;
        while (parent = parent.getParent()) {
            parents.push(parent);
        }

        return parents;
    },

    /**
     * Walk up the hierarchy and try to find an element with a background.
     * @returns {*|Element} Or NULL when no background could be found
     */
    getClosestBackgroundElement: function() {
        let parent = this.getParent();
        while (parent) {
            if (parent.getBackground()) {
                return parent;
            }
            parent = parent.getParent();
        }

        return null;
    },

    /**
     * Check if element has a (specific) parent
     *
     * @param  {Element} [parent]    Check if this element is a child of "parent"
     * @return {boolean}
     */
    hasParent: function(parent) {

        if (!parent) {
            return !!this.$element.parent().closest('.ed-element').length;
        }

        if (_.isArray(parent)) {
            for (var i = 0; i < parent.length; i++) {
                if (this.hasParent(parent[i])) {
                    return true;
                }
            }
            return false;
        }

        return !!this.$element.parent().closest(parent.getElement()).length;

    },

    /**
     * Compare two elements for equality.
     *
     * @param {Element} element Element to compare against
     * @return {boolean}        Returns true, if element is the same element as "this".
     */
    is: function(element) {
        return ( this == element );
    },

    /**
     * Play the animation sequence of the element.
     * If reverse is true, animation target status is "hide"
     *
     * @param {boolean} invert             Reverse the animation
     * @param {string|Element|HTMLElement} trigger  What triggered the animation? Used to avoid loops with group animations
     * @return {this}
     */
    animate(invert, trigger) {
        let reverse = invert;

        // Get animation details
        const animation = this.getAnimation();

        // No animation set, stop here
        if (animation.type == 'none') {
            return this;
        }

        // Get target element for the animation
        const $target = this.getAnimationTarget();

        if (!this.getViewport().isEdit() && !this.getViewport().isPreview()) {
            $target.removeClass('animation-initial');
        }

        // Transform animation durations/delays into milliseconds
        animation.duration = String(animation.duration).replace('ms', '');
        if (animation.duration.match('s')) {
            animation.duration = parseFloat(animation.duration.replace('s', '')) * 1000;
        }

        animation.delay = String(animation.delay).replace('ms', '');
        if (animation.delay.match('s')) {
            animation.delay = parseFloat(animation.delay.replace('s', '')) * 1000;
        }

        // Stop old animations
        $target.velocity('finish').stop(true);

        // Determine direction of animation if not specified
        if (animation.type == 'pulse' || animation.type == 'shrink') {

            // These animations doesn't have a direction
            reverse = false;

        } else if (reverse == null) {
            // If target is hidden, show hide
            reverse = !(
                $target.is(":hidden") || 
                $target.css("visibility") == "hidden" || 
                $target.css("opacity") == 0
            );
        }

        // In compatibility mode, don't animate the element but
        // show or hide it, according to the direction of the animation
        if (animation.type != 'overlay' && this.getAnimations().getCompatibilityMode()) {

            // Notify about the animation
            this.notify('animation.start', [reverse, trigger]).animating = true;
            this.getViewport().notify('animation.start', [this, reverse, trigger]);

            // Show or hide element
            $target[( reverse ? 'hide' : 'show' )]();

            // Notify about end of animation
            this.notify('animation.end', [reverse, trigger]).animating = false;
            this.getViewport().notify('animation.end', [this, reverse, trigger]);

            // Remember element was "animated"
            this.animated = true;

            return this;
        }

        // Scroll to element if (1) element isn't visible after animation is finished and
        // (2) it was triggered by click (simply determined by the fact that trigger is an object)
        if ([
            'fade', 'slide', 'move-left-to-right', 'move-right-to-left', 'move-top-to-bottom', 'move-bottom-to-top'
        ].includes(animation.type) && typeof trigger === 'object' && !reverse) {
            this.once('animation.end', () => {
                if (!this.getViewport().targetVisible(this.$element, 'top')) {
                    this.getViewport().scrollTo(this.$element, 'top', 500, -100);
                }
            });
        }

        // Switch between animation types
        switch (animation.type) {
            // Fade out element
            case 'fade': {

                // Animate element
                if (!reverse) {
                    $target.velocity("fadeIn", {
                        duration: animation.duration,
                        delay: animation.delay,
                        display: null,
                        visibility: 'visible',
                        progress: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        begin: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                } else {

                    $target.velocity("fadeOut", {
                        duration: animation.duration,
                        delay: animation.delay,
                        display: null,
                        visibility: 'hidden',
                        progress: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        begin: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                }

                break;
            }
            // Slide element up/down
            case 'slide': {

                // Animate element.
                // Don't use velocity here, their implementation is buggy (cached height problems)
                if (!reverse) {
                    // Animate
                    $target.delay(animation.delay).queue((next) => {
                        // Pre-execute the animation.start callback so
                        // that updating sliders or maps affects the slide animation
                        this.notify('animation.start', [reverse, trigger]).animating = true;
                        this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        next();
                    }).slideDown({
                        duration: animation.duration,
                        step: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        complete: () => {
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                } else {

                    $target.delay(animation.delay).slideUp({
                        duration: animation.duration,
                        step: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        start: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                }

                break;
            }
            // Move element from left to right (out of screen)
            case 'move-left-to-right': {

                // Hook existing transforms
                const existingProperties = this.getAnimations().getTransforms($target);
                for (var i in existingProperties)
                    $.Velocity.hook($target, i, existingProperties[i]);

                // Animate element
                if (!reverse) {

                    $target.velocity({
                        translateX: [existingProperties.translateX, -(this.getAnimations().getHighestOffset() + $target.outerWidth())],
                        opacity: [1, 0]
                    }, {
                        duration: animation.duration,
                        delay: animation.delay,
                        easing: [100, 20],
                        display: null,
                        visibility: 'visible',
                        progress: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        begin: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            // We have to remove transform definitions because their values (translateX/Y in detail)
                            // were previously replaced by fixed values, overriding percentage definitions from stylesheets (like -50%).
                            // Thus elements would loose their positioning on resize
                            $target.css('transform', '');
                            // Trigger Event
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                } else {

                    $target.velocity({
                        translateX: [this.getViewport().getWidth() - this.getAnimations().getLowestOffset(), existingProperties.translateX],
                        opacity: [0, 1]
                    }, {
                        duration: animation.duration,
                        delay: animation.delay,
                        easing: [100, 20],
                        display: null,
                        visibility: 'hidden',
                        progress: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        begin: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            // We have to remove transform definitions because their values (translateX/Y in detail)
                            // were previously replaced by fixed values, overriding percentage definitions from stylesheets (like -50%).
                            // Thus elements would loose their positioning on resize
                            $target.css('transform', '');
                            // Trigger Event
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                }

                break;
            }
            // Move element from right to left (out of screen)
            case 'move-right-to-left': {

                // Hook existing transforms
                const existingProperties = this.getAnimations().getTransforms($target);
                for (let i in existingProperties)
                    $.Velocity.hook($target, i, existingProperties[i]);

                // Animate element
                if (!reverse) {

                    $target.velocity({
                        translateX: [existingProperties.translateX, this.getViewport().getWidth() - this.getAnimations().getLowestOffset()],
                        opacity: [1, 0]
                    }, {
                        duration: animation.duration,
                        delay: animation.delay,
                        easing: [100, 20],
                        display: null,
                        visibility: 'visible',
                        progress: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        begin: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            // We have to remove transform definitions because their values (translateX/Y in detail)
                            // were previously replaced by fixed values, overriding percentage definitions from stylesheets (like -50%).
                            // Thus elements would loose their positioning on resize
                            $target.css('transform', '');
                            // Trigger Event
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                } else {

                    $target.velocity({
                        translateX: [-(this.getAnimations().getHighestOffset() + $target.outerWidth()), existingProperties.translateX],
                        opacity: [0, 1]
                    }, {
                        duration: animation.duration,
                        delay: animation.delay,
                        easing: [100, 20],
                        display: null,
                        visibility: 'hidden',
                        progress: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        begin: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            // We have to remove transform definitions because their values (translateX/Y in detail)
                            // were previously replaced by fixed values, overriding percentage definitions from stylesheets (like -50%).
                            // Thus elements would loose their positioning on resize
                            $target.css('transform', '');
                            // Trigger Event
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                }

                break;
            }
            // Move element from top to bottom
            case 'move-top-to-bottom':
            // Move element from bottom to top
            case 'move-bottom-to-top': {
                var bottomTop = animation.type === 'move-bottom-to-top',
                    distance = 200;

                // Hook existing transforms
                const existingProperties = this.getAnimations().getTransforms($target);
                for (let i in existingProperties) {
                    $.Velocity.hook($target, i, existingProperties[i]);
                }

                // Animate element
                if (!reverse) {

                    $target.velocity({
                        translateY: [existingProperties.translateX, (existingProperties.translateY || 0) + (bottomTop ? distance : -distance)],
                        opacity: [1, 0]
                    }, {
                        duration: animation.duration,
                        delay: animation.delay,
                        easing: [100, 20],
                        display: null,
                        visibility: 'visible',
                        progress: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        begin: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            // We have to remove transform definitions because their values (translateX/Y in detail)
                            // were previously replaced by fixed values, overriding percentage definitions from stylesheets (like -50%).
                            // Thus elements would loose their positioning on resize
                            $target.css('transform', '');
                            // Trigger Event
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                } else {

                    $target.velocity({
                        translateY: [(existingProperties.translateY || 0) + (bottomTop ? distance : -distance), existingProperties.translateX],
                        opacity: [0, 1]
                    }, {
                        duration: animation.duration,
                        delay: animation.delay,
                        easing: [100, 20],
                        display: null,
                        visibility: 'hidden',
                        progress: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        begin: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            // We have to remove transform definitions because their values (translateX/Y in detail)
                            // were previously replaced by fixed values, overriding percentage definitions from stylesheets (like -50%).
                            // Thus elements would loose their positioning on resize
                            $target.css('transform', '');
                            // Trigger Event
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                }

                break;
            }
            // Shrink and fade out element
            case 'shrink': {

                // Hook existing transforms
                const existingProperties = this.getAnimations().getTransforms($target);
                for (let i in existingProperties)
                    $.Velocity.hook($target, i, existingProperties[i]);

                // Animate element
                if (!reverse) {

                    $target.velocity("transition.expandIn", {
                        duration: animation.duration,
                        delay: animation.delay,
                        display: null,
                        visibility: 'visible',
                        progress: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        begin: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            // We have to remove transform definitions because their values (translateX/Y in detail)
                            // were previously replaced by fixed values, overriding percentage definitions from stylesheets (like -50%).
                            // Thus elements would loose their positioning on resize
                            $target.css('transform', '');
                            // Trigger Event
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                } else {

                    $target.velocity("transition.expandOut", {
                        duration: animation.duration,
                        delay: animation.delay,
                        display: null,
                        visibility: 'hidden',
                        progress: () => {
                            this.notify('animation.step', [animation.type]);
                            this.getViewport().notify('animation.step', [this, animation.type]);
                        },
                        begin: () => {
                            this.notify('animation.start', [reverse, trigger]).animating = true;
                            this.getViewport().notify('animation.start', [this, reverse, trigger]);
                        },
                        complete: () => {
                            // We have to remove transform definitions because their values (translateX/Y in detail)
                            // were previously replaced by fixed values, overriding percentage definitions from stylesheets (like -50%).
                            // Thus elements would loose their positioning on resize
                            $target.css('transform', '');
                            // Trigger Event
                            this.notify('animation.end', [reverse, trigger]).animating = false;
                            this.getViewport().notify('animation.end', [this, reverse, trigger]);
                        }
                    });

                }

                break;
            }
            // Pulse (no direction)
            case 'pulse': {

                // Hook existing transforms
                var existingProperties = this.getAnimations().getTransforms($target);
                for (let i in existingProperties)
                    $.Velocity.hook($target, i, existingProperties[i]);

                $target.velocity("callout.pulse", {
                    duration: animation.duration,
                    delay: animation.delay,
                    progress: () => {
                        this.notify('animation.step', [animation.type]);
                        this.getViewport().notify('animation.step', [this, animation.type]);
                    },
                    begin: () => {
                        this.notify('animation.start', [reverse, trigger]).animating = true;
                        this.getViewport().notify('animation.start', [this, reverse, trigger]);
                    },
                    complete: () => {
                        // We have to remove transform definitions because their values (translateX/Y in detail)
                        // were previously replaced by fixed values, overriding percentage definitions from stylesheets (like -50%).
                        // Thus elements would loose their positioning on resize
                        $target.css('transform', '');
                        // Trigger Event
                        this.notify('animation.end', [reverse, trigger]).animating = false;
                        this.getViewport().notify('animation.end', [this, reverse, trigger]);
                    }
                });

                break;
            }
            // Flash (no direction)
            case 'flash': {

                $target.velocity("callout.flash", {
                    duration: animation.duration,
                    delay: animation.delay,
                    progress: () => {
                        this.notify('animation.step', [animation.type]);
                        this.getViewport().notify('animation.step', [this, animation.type]);
                    },
                    begin: () => {
                        this.notify('animation.start', [reverse, trigger]).animating = true;
                        this.getViewport().notify('animation.start', [this, reverse, trigger]);
                    },
                    complete: () => {
                        this.notify('animation.end', [reverse, trigger]).animating = false;
                        this.getViewport().notify('animation.end', [this, reverse, trigger]);
                    }
                });

                break;
            }
            case 'overlay': {

                // Wait until magnific is ready
                this.getViewport().promise('api.magnific.ready', () => {

                    if ($target.closest('.mfp-content').length) {
                        // Overlay is already opened, close it
                        this.getViewport().jQuery.magnificPopup.close();
                        return;
                    }

                    // Width provided
                    if (animation.overlay.width && animation.overlay.width !== 'auto')
                        $target.css({
                            'position': 'relative',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                            'max-width': '100%',
                            'width': animation.overlay.width
                        });
                    else // Auto width
                        $target.css({
                            'position': 'relative',
                            'display': 'inline-block',
                            'width': 'auto',
                            'text-align': 'left'
                        });

                    // Make sure element is visible
                    $target.show();

                    // Open as magnific popup
                    this.getViewport().jQuery.magnificPopup.open({
                        items: {
                            src: '#' + $target.attr('id'),
                            type: 'inline'
                        },
                        // Add gallery here, because content might be replaced later when popup is opened inside popup
                        gallery: {
                            enabled: true,
                            arrows: true,
                            navigateByImgClick: true,
                            preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                            tPrev: '',
                            tNext: ' ',
                            tCounter: '<span class="mfp-counter">%curr%/%total%</span>' // markup of counter
                        },
                        callbacks: {
                            open: () => {
                                this.notify('animation.start', [reverse, trigger]).animating = true;
                                this.getViewport().notify('animation.start', [this, reverse, trigger]);
                            },
                            close: () => {
                                this.notify('animation.end', [reverse, trigger]).animating = false;
                                this.getViewport().notify('animation.end', [this, reverse, trigger]);
                            },
                            afterChange: function() {
                                this.ev.triggerHandler('mfpBuildControls');
                            }
                        }
                    });

                    // Set text-align center for auto-width
                    if (!animation.overlay.unit)
                        $target.parent().css('text-align', 'center');

                }, true);

                // Require magnific
                this.getViewport().requireMagnific();

                break;
            }
        }

        // Remember element was animated
        this.animated = true;

        return this;

    },

    /**
     * Get the target for animations.
     *
     * @return {jQuery} The actual element being animated
     * @abstract
     */
    getAnimationTarget: function() {
        return this.$element;
    },

    /**
     * Returns if the element is currently animating.
     *
     * @return {boolean}
     */
    getIsAnimating: function() {
        return this.animating;
    },

    /**
     * Set or get if the element was already animated.
     *
     * @param {boolean} [animated] Set if element was animated. If omitted, just return if element was animated
     * @return {boolean}
     */
    wasAnimated: function(animated) {
        if (typeof animated !== 'undefined')
            this.animated = animated;
        return this.animated;
    },

    /**
     * Get animations controller.
     * Overridden in CMS mode.
     * @return {Animations}
     */
    getAnimations: function() {
        return window.animations;
    },

    /**
     * Get viewport controller.
     * Overridden in CMS mode.
     * @return {Viewport}
     */
    getViewport: function() {
        return window.viewport;
    },

    /**
     * Get the animation details for this element.
     * If no animation was set, the "type" property will be "none"
     *
     * @return {object}    The animation details
     */
    getAnimation: function() {
        var animation;

        try {
            animation = JSON.parse(this.$element.attr('data-animation'));
        } catch (e) {}

        _.merge(this.animation, animation);

        if (typeof this.animation.duration == 'number')
            this.animation.duration = this.animation.duration + 's';
        if (typeof this.animation.delay == 'number')
            this.animation.delay = this.animation.delay + 's';
        if ('unit' in this.animation.overlay && this.animation.overlay.unit) {
            this.animation.overlay.width = parseFloat(this.animation.overlay.width) + this.animation.overlay.unit;
            delete this.animation.overlay.unit;
        }

        return this.animation;
    },

    /**
     * Returns true if element has an animation assigned
     *
     * @return {boolean}
     */
    getIsAnimated: function() {
        var animation = this.getAnimation();
        return ( animation && animation.type != 'none' );
    },

    /**
     * Get first parent element which has an animation assigned
     *
     * @return {Element|boolean} The animated parent or false, if none found
     */
    getAnimatedParent: function() {

        // Iterate up to first animated parent
        var child = this,
            parent;
        while (( parent = child.getParent() )) {
            if (parent.getIsAnimated())
                break;
            child = parent;
        }

        return ( parent && parent.getIsAnimated() ? parent : false );

    },

    /**
     * Returns if the element is a background element
     *
     * @return {boolean}
     */
    getIsBackground: function() {
        return this.$element.is('.wv-bg');
    },

    /**
     * Returns if the element is currently attached to the DOM
     *
     * @return {boolean}
     */
    isAttached: function() {
        var element = this.$element[0];

        // No parent at all, so it can not even be attached
        if (element.parentNode === null) {
            return false;
        }

        // Walk up the tree
        while (element.parentNode !== null) {
            element = element.parentNode;
        }

        return element.nodeType === Node.DOCUMENT_NODE;
    },

    /**
     * set element state
     * @param {object} newValues
     */
    setState(newValues = {}) {
        const oldState = { ...this.state };
        this.state = { ...this.state, ...newValues };
    },

    /**
     * Returns a collection ID if this element is (deeply) nested into a collection container and NULL if not
     * @returns {String|null}
     */
    getCollectionId() {
        return this.getParent() ? this.getParent().getCollectionId() : null;
    }
});


export default ElementBase;

/**
 * @file
 * Icon element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementIcon
 * @extends Element
 */
const ElementIcon = (window.ElementIcon || window.ElementBase).extend(/** @lends ElementIcon.prototype */ {

    /**
     * Get the main representing element.
     * The <img> tag for an image, for example.
     * Used to draw the outline
     * @return {jQuery}
     */
    getMainElement: function() {
        return this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element);
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return 'svg';
    }

});


export default ElementIcon;

/**
 * @file
 * Gallery element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementGallery
 * @extends Element
 */
var ElementGallery = (window.ElementGallery || window.ElementBase).extend(/** @lends ElementGallery.prototype */ {

    /**
     * Holds the unordered list containing the gallery items
     * @type {jQuery}
     */
    $gallery: null,

    /**
     * Collect the gallery element and initialize MagnificPopup
     *
     * @override
     * @return {this}
     */
    wakeup: function () {

        this.$gallery = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);

        // Wait until magnific is ready
        this.getViewport().promise('api.magnific.ready', function () {

            // Assign magnific popup
            // (if the gallery isn't set to "enlarge",
            // there will be no hyperlinks - hence no
            // image enlarging occurs)
            this.$gallery.magnificPopup({
                delegate: 'a',
                type: 'image',
                tLoading: '...',
                mainClass: 'mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                    tPrev: '',
                    tNext: ' ',
                    tCounter: '<span class="mfp-counter">%curr%/%total%</span>' // markup of counter
                },
                image: {
                    tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
                },
                disableOn: () => !this.editor
                    || this.editor.getView().getPreview()
                    || !this.editor.getSecurity().isGranted('element-edit', [this])
            });

        }.bind(this));

        // Require magnific
        this.getViewport().requireMagnific();
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return '.ed-gallery-items';
    },

    /**
     * Get main element
     * @return {jQuery}
     */
    getMainElement: function () {
        return this.$gallery || this.$element;
    },

});


export default ElementGallery;

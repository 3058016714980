/**
 * @file
 * Map element
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementMap
 * @extends Element
 */
const ElementMap = (window.ElementMap || window.ElementBase).extend(/** @lends ElementMap.prototype */ {

    /**
     * Holds the map canvas
     * @type {jQuery}
     */
    $map: null,

    /**
     * The Gmaps API object
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * Holds all markers
     * @type {google.maps.Marker[]}
     */
    markers: [],

    /**
     * Holds all info windows
     * @type {google.maps.InfoWindow[]}
     */
    infoWindows: [],

    /**
     * Determines if the map needs to be re-centered.
     * @see ElementMap.updateMap
     * @type {boolean}
     */
    recenter: null,

    /**
     * Query Location Cache
     */
    queryLocationCache: {},

    /**
     * @type {google.maps}
     */
    googleMapsApi: null,

    /**
     * Runs in edit mode
     */
    editMode: false,

    /**
     * Collect the map canvas, set up event listeners and initialize the map
     *
     * @override
     * @return {this}
     */
    wakeup: function() {
        this.$map = this.getViewport().jQuery(this.getSelectorForMainElement(), this.$element[0]);

        if (!("IntersectionObserver" in window) || this.editMode) {
            this.initializeMap();
            return this;
        }

        // Lazyload for browsers that support it
        const lazyMapObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.initializeMap();
                    lazyMapObserver.unobserve(entry.target);
                }
            });
        }, { rootMargin: '0px 0px 100px 0px' });

        if (this.$map.length) {
            lazyMapObserver.observe(this.$map[0]);
        }

        return this;
    },

    /**
     * @inheritDoc
     */
    getSelectorForMainElement() {
        return '.map-canvas';
    },

    initializeMap() {
        this.getViewport().promise('api.maps.ready', () => {
            this.googleMapsApi = this.getViewport().window.google.maps;

            this.updateMap(true);

            this.getViewport().debounce('resize', 50, () => {
                this.updateMap();
            });
        });

        this.getViewport().requireMapsApi();

        // Refresh the map on animations
        this.getViewport().observe('animation.start animation.end', (element) => {
            // Check if element is a parent of this element or the map itself
            if (this !== element && !this.hasParent(element)) {
                return;
            }

            // If element isn't currently visible, show it, before updating the map
            var visible = element.getElement().css('display') !== 'none';
            !visible && element.getElement().css('display', '');

            // Update map
            this.updateMap(false, true);

            // Re-hide again
            !visible && element.getElement().css('display', 'none');
        });

        // Map isn't attached yet, defer initialization
        // until element is attached
        if (!this.isAttached()) {
            this.once('attach', () => {
                this.updateMap(true);
            });
        }
    },

    /**
     * Get the main representing element.
     * The <img> tag for an image, for example.
     * Used to draw the outline
     * @return {jQuery}
     */
    getMainElement: function() {
        return this.$map || this.$element;
    },

    /**
     * Update the map (reposition, set markers, etc.)
     *
     * @override
     * @return {this}
     */
    update: function() {

        this.updateMap();

        return this;
    },

    /**
     * Query an location by string.
     * Used by edit function (because the google object isn't defined in cms)
     *
     * @return {this}
     */
    queryLocation: function(query, callback) {
        var self = this;

        if (typeof this.queryLocationCache[query] !== 'undefined') {
            callback(this.queryLocationCache[query].lat(), this.queryLocationCache[query].lng(), query);
            return this;
        }

        // Make sure Geocoding API is available
        var viewport = this.getViewport();
        viewport.promise('api.maps.ready', function() {

            new this.googleMapsApi.Geocoder().geocode({
                'address': query
            }, (results, status) => {

                if (status === this.googleMapsApi.GeocoderStatus.OK) {
                    self.queryLocationCache[query] = results[0].geometry.location;
                    callback(results[0].geometry.location.lat(), results[0].geometry.location.lng(), query);
                } else {
                    // To make things easy,
                    // just center the center of the world (Klosterstr. 62) :)
                    // (easier for the command stuff)
                    callback(52.5158473, 13.4109857, query);
                }
            });

        }.bind(this), true);

        // Tell viewport to load the API
        viewport.requireMapsApi();

        return this;
    },

    /**
     * Redraw the map.
     *
     * If "deep" is true, change all the settings.
     * Otherwise, just redraw
     *
     * @param {boolean} [deep=false] If set to true, re-initialize the map. Otherwise just redraw (after resize, for example)
     * @param {boolean} [recenter=false] If set to true, force re-centering.
     * @param {boolean} [forceRecreate=false] force re-creation of the map using google api rather than embedded version
     * @return {this}
     */
    updateMap: function(deep, recenter, forceRecreate) {
        var i;

        // Google is not defined,
        // skip here, API is on its way
        if (!this.googleMapsApi)
            return this;

        // (Re-)fetch the settings
        if (!this.map || deep)
            this.settings = this.getMapSettings();

        if (
            (typeof webcard === 'undefined' || !webcard.googleMapsApiKey)
            && this.settings.style.name === 'default'
            && this.settings.type === 'roadmap'
            && this.settings.markers
            && (
                this.settings.markers.length === 0
                || (this.settings.markers.length === 1 && !this.settings.markers[0].info)
            )
            && !this.editMode
        ) {
            if (deep) {
                this.buildEmbed();
            }
            return this;
        }

        // Initialize the map or recreate the map through api rather then embed
        // if more than one marker present
        if (!this.map || forceRecreate) {
            this.map = new this.googleMapsApi.Map(this.$map[0]);

            // Save map object in DOM
            this.$map.data('map', this.map);

            // Determine if map needs to be re-centered (needed on refresh)
            this.recenter = (this.settings.center.markers && this.settings.markers.length) || !this.$map.is(':visible');

            // Map is initialized empty, of course we now need to deep update
            deep = true;
        }

        if (deep) {

            // Some basic options
            this.map.setOptions({
                center: {
                    lat: this.settings.center.lat,
                    lng: this.settings.center.lng
                },
                scrollwheel: this.settings.controls.mousewheel,
                zoom: this.settings.zoom,
                zoomControl: this.settings.controls.zoom,
                scaleControl: this.settings.controls.scale,
                styles: (
                    this.settings.style.name === 'colorize' ?
                        (function(color) {

                            // Convert HEX color to RGB and then to (H)SL (we don't need the hue)
                            var rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
                            if (rgb) {

                                var r = parseInt(rgb[1], 16) / 255,
                                    g = parseInt(rgb[2], 16) / 255,
                                    b = parseInt(rgb[3], 16) / 255;
                                var max = Math.max(r, g, b), min = Math.min(r, g, b);
                                var h, s, l = (max + min) / 2;

                                if (max == min) {
                                    h = s = 0; // achromatic
                                } else {
                                    var d = max - min;
                                    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                                    switch (max) {
                                        case r:
                                            h = (g - b) / d + (g < b ? 6 : 0);
                                            break;
                                        case g:
                                            h = (b - r) / d + 2;
                                            break;
                                        case b:
                                            h = (r - g) / d + 4;
                                            break;
                                    }
                                    h /= 6;
                                }

                                // Return style configuration.
                                // Google expects values between -100 and 100
                                return [{
                                    "stylers": [
                                        {'hue': color},
                                        {'saturation': s * 200 - 100},
                                        {'lightness': l * 200 - 100}
                                    ]
                                }];

                            }

                            // Fallback, don't colorize
                            return null;

                        })(this.settings.style.color) :
                        this.settings.style.configuration
                ),
                mapTypeId: this.googleMapsApi.MapTypeId[this.settings.type.toUpperCase()]
            });

            // Delete all old markers
            for (var i = 0; i < this.markers.length; i++)
                this.markers[i].setMap(null);
            for (var i = 0; i < this.infoWindows.length; i++)
                this.infoWindows[i].setMap(null);
            this.markers = [];
            this.infoWindows = [];


            // Create the markers
            for (i = 0; i < this.settings.markers.length; i++) {

                (function(index) {

                    var iconUrl = 'https://mt.googleapis.com/vt/icon/name=icons/spotlight/spotlight-poi.png&scale=1';
                    if (this.settings.markers[index].icon) {
                        var width = this.settings.markers[index].width;
                        if (!width || width === 'auto') {
                            width = 'full';
                        }
                        iconUrl = new File(this.settings.markers[index].icon).getImageSize(width);
                    }

                    if (!this.settings.markers[index].lat || !this.settings.markers[index].lng) {
                        return;
                    }

                    var position = new this.googleMapsApi.LatLng(this.settings.markers[index].lat, this.settings.markers[index].lng);

                    // Fill cache with existing markers to prevent exceeding Googles geocoding access limit on editing a map with a lot of markers
                    this.queryLocationCache[this.settings.markers[index].query] = position;

                    var marker = new this.googleMapsApi.Marker({
                        position: position,
                        map: this.map,
                        title: this.settings.markers[index].title,
                        icon: iconUrl
                    });
                    this.markers.push(marker);

                    // Also create an info window
                    if (this.settings.markers[index].title || this.settings.markers[index].description) {

                        var windowContent =
                            '<div id="content">' +
                            ( this.settings.markers[index].title ? '<p><strong>' + this.settings.markers[index].title + '</strong></p>' : '' ) +
                            ( this.settings.markers[index].description ? '<div>' + this.settings.markers[index].description + '</div>' : '' ) +
                            '</div>';

                        var infoWindow = new this.googleMapsApi.InfoWindow({
                            content: windowContent,
                            disableAutoPan: true
                        });
                        this.infoWindows.push(infoWindow);

                        this.markers[index].addListener('click', function() {
                            infoWindow.open(this.map, marker);
                        }.bind(this));

                        if (this.settings.markers[index].open) {
                            infoWindow.open(this.map, marker);
                        }
                    }

                }.bind(this))(i);
            }

            this.$map.data('markers', this.markers);
        }

        // Redraw the map
        this.googleMapsApi.event.trigger(this.map, 'resize');
        if (this.recenter || recenter || deep || this.editMode) { // Always resize in edit mode, user can't drag the map

            // Center on markers
            if (this.settings.center.markers && this.settings.markers.length) {

                var bounds = new this.googleMapsApi.LatLngBounds();
                for (i = 0; i < this.markers.length; i++)
                    bounds.extend(this.markers[i].getPosition());

                if (this.settings.center.markers === 'zoom') {
                    this.map.fitBounds(bounds);
                } else {
                    this.map.setCenter(bounds.getCenter());
                }
            } else {
                // Default centering
                this.map.setCenter(new this.googleMapsApi.LatLng(this.settings.center.lat, this.settings.center.lng));
            }

            // Don't do it twice (as long as the map is visible now),
            // the map would loose the user-defined position
            this.recenter = !this.$map.is(':visible');
        }

        return this;
    },

    parseMapSettings: function(map, addDefaults = true) {
        var parameters,
            old = this.$map.data('params');
        try {
            parameters = JSON.parse(map.attr('data-parameters'));
            old = null; // Prefer the new settings
        } catch (e) {
            parameters = {};
        }

        const settings = Object.assign(addDefaults ? {
            markers: [],
            center: {
                query: 'Berlin',
                lat: 52.522254,
                lng: 13.393534,
                markers: 'center'
            },
            zoom: 13,
            type: 'roadmap',
            style: {
                name: 'default',
                configuration: null,
                color: '#999999'
            },
            controls: {
                zoom: true,
                mousewheel: true,
                scale: false
            }
        } : {}, parameters);

        // Make it compatible with the old format
        if (old) {
            settings.zoom = old.zoom;

            settings.center.query = '';
            settings.center.lat = parseFloat(old.lat);
            settings.center.lng = parseFloat(old.lng);

            if (old.marker) {
                settings.markers = [{
                    title: '',
                    description: '',
                    marker: '',
                    lat: old.lat,
                    lng: old.lng
                }];
            } else {
                settings.markers = [];
            }

            settings.controls.zoom = !!old.zoomControls;
            settings.controls.scale = !!old.scale;
            settings.controls.mousewheel = !!old.scroll;
        }

        return settings;
    },

    /**
     * Get the map settings
     *
     * @return {object}
     */
    getMapSettings: function(useDefaults = true) {
        return this.parseMapSettings(this.$map, useDefaults);
    },

    /**
     * Builds the map with the Google Maps Embed API instead of Dynamic Maps API
     *
     * As of Google Maps' new pricing of July 2018, we want to use the (free) Embed API for most requests possible
     */
    buildEmbed: function() {
        this.settings = this.getMapSettings();

        var type = 'view',
            query, iframe,
            queryParams = {
                key: typeof webcard !== 'undefined' ? webcard.googleMapsEmbedApiKey : '',
                center: this.settings.center.lat + ',' + this.settings.center.lng,
                zoom: this.settings.zoom
            };

        if (this.settings.markers && this.settings.markers.length > 0) {
            type = 'place';
            queryParams.q = this.settings.markers[0].query;

            // Center to markers
            if (['center', 'zoom'].indexOf(this.settings.center.markers) > -1 && this.settings.markers[0].lat && this.settings.markers[0].lng) {
                queryParams.center = this.settings.markers[0].lat + ',' + this.settings.markers[0].lng;
            }
        }

        query = Object.keys(queryParams)
            .map(function(k) { return encodeURIComponent(k) + '=' + encodeURIComponent(queryParams[k]); })
            .join('&');

        iframe = document.createElement('iframe');
        iframe.width = "100%;";
        iframe.height = this.$map.height();
        iframe.style.border = "0";
        iframe.frameBorder = "0";
        iframe.src = 'https://www.google.com/maps/embed/v1/' + type + '?' + query;
        iframe.allowFullscreen = true;

        this.$map[0].innerHTML = '';
        this.$map[0].appendChild(iframe);

        return this;
    }

});


export default ElementMap;

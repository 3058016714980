/**
 * @file
 * Defines the File class
 *
 * @author Christian Sauthoff <christian.sauthoff@websitebutler.de>
 */

/**
 * The File class wraps file and image manipulation methods.
 * A file object can either be initialized by an URL or an object
 * with it's desired properties (usually coming from server, see MediaController).
 *
 * @class File
 * @example
 * var file = new File('/images/400/123/filename.jpg'); // Initializes an internal file with the ID "123"
 * file.getInternal(); // Will return true as the file is internally hosted
 * file.getUrl(); // Will return the full-size URL (/images/0/123/filename.jpg)
 * file.setRotation(90); // Set image rotation to 90 degrees (will be used in getImageSize)
 * file.setCrop(0, 0, 100, 100); // Crop 100px*100px from 0/0 (will be used in getImageSize)
 * file.getImageSize(50); // Returns an URL of the file with the previously set properties (90 degrees rotation, cropped <0,0,100,100>) in 50px size.
 */
var File = Class.extend(/** @lends File.prototype */ {

    /**
     * Holds the file ID
     * @type {string}
     */
    id: '',

    /**
     * Holds the created at date
     * @type {Date}
     */
    created_at: false,

    /**
     * Holds the file name
     * @type {string}
     */
    name: '',

    /**
     * Holds the file extension
     * @type {string}
     */
    extension: '',

    /**
     * Holds the original domain
     * @type {string}
     */
    domain: '',

    /**
     * Holds the files mime type
     * @type {string}
     */
    mime: '',

    /**
     * Holds the associated mime image
     * @type {String}
     */
    mimeImage: '',

    /**
     * Holds the files type (image/document/unknown)
     * @type {string}
     */
    type: '',

    /**
     * Holds the file size in bytes
     * @type {number}
     */
    size: null,

    /**
     * Holds the width, if file is an image
     * @type {number}
     */
    width: null,

    /**
     * Holds the height, if file is an image
     * @type {number}
     */
    height: null,

    /**
     * Holds the displayed width, extracted from the URL
     * @type {number}
     */
    displayWidth: null,

    /**
     * Holds the displayed height, extracted from the URL
     * @type {number}
     */
    displayHeight: null,

    /**
     * Holds the image rotation in degrees
     * @type {number}
     */
    rotation: 0,

    /**
     * Holds the crop X, if image is cropped
     * @type {number}
     */
    cropX: null,

    /**
     * Holds the crop Y, if image is cropped
     * @type {number}
     */
    cropY: null,

    /**
     * Holds the crop width, if image is cropped
     * @type {number}
     */
    cropWidth: null,

    /**
     * Holds the crop height, if image is cropped
     * @type {number}
     */
    cropHeight: null,

    /**
     * Holds the images blur level
     * @type {number}
     */
    blur: 0,

    /**
     * Holds if the image is grayscaled?
     * @type {boolean}
     */
    grayscale: false,

    /**
     * Holds the files URL
     * @type {string}
     */
    url: '',

    /**
     * Holds the URL to a thumbnail of the file (provided server-side)
     * @type {string}
     */
    thumbnail: '',

    /**
     * Is true if the file is internally hosted
     * @type {boolean}
     */
    internal: false,

    /**
     * Is true if the file is in use somewhere (provided server-side)
     * @type {boolean}
     */
    in_use: false,

    /**
     * List of image tags
     *
     * @type {array}
     */
    tags: [],

    /**
     * Image aesthetic score
     *
     * @type {float}
     */
    aesthetic: null,

    /**
     * Folder name
     *
     * @type {string}
     */
    folder: null,

    /**
     * folder_created_at of the folder
     *
     * @type {Date}
     */
    folder_created_at: null,

    /**
     * Constructor function that initializes the file
     *
     * @param {object|string} properties    Object with properties or an URL to parse
     * @return {File}
     * @constructs File
     */
    init: function(properties) {
        if (typeof properties === 'object') {
            _.merge(this, properties);
        } else if (typeof properties === 'string') {
            // Initialized with a url.
            // Generate an file object out of it
            var matches = properties.match(/(.*?)?(\/images\/([^\/]+)\/([0-9]+)\/(.*?(\.[a-z]+)?))$/i);
            if (matches) {
                this.setDomain(matches[1] || '');
                this.setUrl(matches[2].replace('/' + matches[3] + '/', '/0/'));
                // Get the display width and height
                var display = matches[3].match(/^([0-9]+)(?:x([0-9]+))?/);
                if (display) {
                    this.setDisplayWidth(parseInt(display[1]));
                    this.setDisplayHeight(typeof display[2] !== 'undefined' ? parseInt(display[2]) : null); // Can be undefined
                }
                // Get the cropping
                var cropping = matches[3].match(/(?:,|%2C)([0-9]+)x([0-9]+)(?:\+|%2B|%252B|%25252B)?([\-0-9]+)?(?:(?:\+|%2B|%252B|%25252B)([\-0-9]+))?/i);
                if (cropping) {
                    this.setCropX(parseInt(cropping[3] || 0));
                    this.setCropY(parseInt(cropping[4] || 0));
                    this.setCropWidth(parseInt(cropping[1]));
                    this.setCropHeight(parseInt(cropping[2]));
                }
                // Get the rotation
                var rotation = matches[3].match(/(?:,|%2C)([\-0-9]+)(?:,|%2C|$)/i);
                if (rotation) {
                    this.setRotation(parseInt(rotation[1]));
                }
                // Get filter
                var filters = matches[3].match(/(?:,|%2C)(?:B([0-9]+))?(G)?$/i);
                if (filters) {
                    this.setBlur(parseInt(filters[1]));
                    this.setGrayscale(!!filters[2]);
                }
                this.setId(matches[4]);
                this.setName(matches[5]);
                this.setExtension(matches[6]);
                this.setInternal(true);
            } else {
                // Generic url
                this.setUrl(properties);
                this.setInternal(false);
                var matches = properties.match(/(https?:\/\/([^\/]*))?(.*?([^\/]*(\.[a-z]+)$))/i);
                if (matches) {
                    this.setDomain(matches[1] || '');
                    this.setName(matches[4] || '');
                    this.setExtension(matches[5] || '');
                }
            }

            // Try to determine type by name, default to image
            // (to support imported images without file extensions)

            if ((this.getName() || '').match(/\.(pdf|doc|docx|xls|odt|ods|ppt)$/i)) {
                // Some document extension
                this.setType('document');
            } else if ((this.getName() || '').match(/\.(mp4|webm)$/i)) {
                // set video extension
                this.setType('video');
            } else if ((this.getName() || '').match(/\.(mp3|ogg|wav)$/i)) {
                // set audio extension
                this.setType('audio');
            } else if ((this.getName() || '').match(/\.(?!jpg|jpeg|png|webp|gif|tiff)[a-z0-9]+$/i)) {
                // Any other extension, except image extensions
                this.setType('unknown');
            } else {
                // No extension or image extension
                this.setType('image');
            }

        }

        return this;
    },

    /**
     * Compares two files for equality
     *
     * @param {File} file    File to compare against
     * @return {boolean}
     */
    is: function(file) {
        if (!file || !('id' in file))
            return false;
        return file.id == this.id;
    },

    /**
     * Returns the list of tags.
     *
     * @returns {array}
     */
    getTags: function() {
        return this.tags;
    },

    /**
     * Returns the image aesthetic score
     *
     * @returns {float}
     */
    getAesthetic: function() {
        return this.aesthetic
    },

    /**
     * Get the ID
     *
     * @return {number}
     */
    getId: function() {
        return this.id;
    },

    /**
     * Get the created at date (or false if not set)
     *
     * @return {Date|boolean} Return the created at or false, if not available
     */
    getCreatedAt: function() {
        return ( this.created_at ? new Date(this.created_at.replace(/-/g, '/')) : false );
    },

    /**
     * Get the name
     *
     * @return {string}
     */
    getName: function() {
        return this.name;
    },

    /**
     * Get the extension
     *
     * @return {string}
     */
    getExtension: function() {
        return this.extension;
    },

    /**
     * Get the domain the file is from (if initialized with an URL)
     *
     * @return {string}
     */
    getDomain: function() {
        return this.domain;
    },

    /**
     * Get the mime type
     *
     * @return {string}
     */
    getMime: function() {
        return this.mime;
    },

    /**
     * Get the file type (image, document or unknown)
     *
     * @return {string}
     */
    getType: function() {
        return this.type;
    },

    /**
     * Get the image width, if available
     *
     * @return {number}
     */
    getWidth: function() {
        return this.width;
    },

    /**
     * Get the image height, if available
     *
     * @return {number}
     */
    getHeight: function() {
        return this.height;
    },

    /**
     * Get the width the image is displayed with (parsed from the size part of the URL)
     *
     * @return {number}
     */
    getDisplayWidth: function() {
        return this.displayWidth;
    },

    /**
     * Get the height the image is displayed with (parsed from the size part of the URL)
     *
     * @return {number}
     */
    getDisplayHeight: function() {
        return this.displayHeight;
    },

    /**
     * Get the images rotation in degrees
     *
     * @return {number}
     */
    getRotation: function() {
        return this.rotation;
    },

    /**
     * Get the crop x-coordinate of the image (or null, if not cropped)
     *
     * @return {number|null}
     */
    getCropX: function() {
        return this.cropX;
    },

    /**
     * Get the crop y-coordinate of the image (or null, if not cropped)
     *
     * @return {number|null}
     */
    getCropY: function() {
        return this.cropY;
    },

    /**
     * Get the crop width of the image (or null, if not cropped)
     *
     * @return {number|null}
     */
    getCropWidth: function() {
        return this.cropWidth;
    },

    /**
     * Get the crop height of the image (or null, if not cropped)
     *
     * @return {number|null}
     */
    getCropHeight: function() {
        return this.cropHeight;
    },

    /**
     * Get if image is displayed grayscaled
     *
     * @return {boolean}
     */
    getGrayscale: function() {
        return this.grayscale;
    },

    /**
     * Get the blur amount of the image
     *
     * @return {number}
     */
    getBlur: function() {
        return this.blur;
    },

    /**
     * Get if image is cropped
     *
     * @return {boolean}
     */
    isCropped: function() {
        return ( this.cropX !== false && this.cropX !== 'undefined' && this.cropX !== null ) &&
            ( this.cropY !== 'undefined' && this.cropY !== null ) &&
            ( this.cropWidth !== 'undefined' && this.cropWidth !== null ) &&
            ( this.cropHeight !== 'undefined' && this.cropHeight !== null );
    },

    /**
     * Get if the image is rotated
     *
     * @return {boolean}
     */
    isRotated: function() {
        return ( this.rotation );
    },

    /**
     * Returns if the image was modified (rotated, cropped, filtered)
     *
     * @return {boolean}
     */
    isModified: function() {
        return ( this.getDisplayWidth() || this.isCropped() || this.isRotated() || this.getBlur() || this.getGrayscale() );
    },

    /**
     * Get file size in bytes.
     *
     * @param {boolean} human    If true, size is converted to a human readable format
     * @return {number|string}
     */
    getSize: function(human) {
        if (human) {
            var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'],
                size = this.size;
            for (var i = 0; size > 1024; i++)
                size = Math.round(size / 10.24) / 100;
            return size + ' ' + units[i];
        }

        return this.size;
    },

    /**
     * Get aspect ratio of image.
     * If uncropped is not set to false, cropped aspect ratio is returned (if image was cropped)
     *
     * @param {Boolean} [uncropped]
     *
     * @return {Number}
     */
    getAspectRatio: function(uncropped) {
        return (this.isCropped() && uncropped !== false ? this.getCropWidth() / (this.getCropHeight() || 1) : this.getWidth() / (this.getHeight() || 1));
    },

    /**
     * Get the URL to the original file
     *
     * @return {string}
     */
    getUrl: function() {
        return (this.url || '').substr(0, (this.domain || '').length) !== this.domain ? this.domain + this.url : this.url;
    },

    /**
     * Get an URL to a thumbnail of the file (provided server-side)
     *
     * @return {string}
     */
    getThumbnail: function() {
        return this.thumbnail;
    },

    getMimeImage: function() {
        return this.mimeImage;
    },

    /**
     * Returns true if the file is internally hosted
     *
     * @return {boolean}
     */
    getInternal: function() {
        return this.internal;
    },

    /**
     * Returns true if the file is in use somewhere (provided server-side)
     *
     * @return {boolean}
     */
    getInUse: function() {
        return this.in_use;
    },

    /**
     * Returns the folder name the file belongs to
     * @returns {string}
     */
    getFolder: function() {
        return this.folder;
    },

    /**
     * Returns the createdAt of the folder the file belongs to
     * @returns {folder_created_at}
     */
    getFolderCreatedAt: function() {
        return this.folder_created_at;
    },

    /**
     * Generates a size path to the image file with specified parameters
     *
     * @param {number|string} width    Image width in pixels or 'full' for full size or 'default' for default size (see Viewport)
     * @param {?number} height            Image height in pixels or null for proportional resizing
     * @param {?boolean} uncropped        If set to true, previously set cropping details will be ignored
     * @param {?boolean} unrotated        If set to true, previously set rotation will be ignored
     * @param {?boolean} unfiltered        If set to true, previously set filters (grayscale, blur) will be ignored
     * @return {string}
     */
    getSizePath: function(width, height = null, uncropped = false, unrotated = false, unfiltered = false) {
        // Full width
        if (width === 'full')
            width = 0;

        // Default width
        if (width === 'default')
            width = ( typeof viewport !== 'undefined' && viewport && 'getImageDefaultWidth' in viewport ? viewport.getImageDefaultWidth() : 1920 );

        var sizePath = ( height ? parseInt(width) + 'x' + parseInt(height) : parseInt(width) );

        // Add cropping parameters
        if (this.isCropped() && !uncropped) {
            // %2B is an encoded plus sign (pure plus sign would be decoded to space)
            sizePath += '%2C' + parseInt(this.getCropWidth()) + 'x' + parseInt(this.getCropHeight()) + '%2B' + parseInt(this.getCropX()) + '%2B' + parseInt(this.getCropY());
        }

        if (this.isRotated() && !unrotated) {
            sizePath += '%2C' + this.getRotation();
        }

        if (( this.getBlur() || this.getGrayscale() ) && !unfiltered) {
            sizePath += '%2C';

            if (this.getBlur()) {
                sizePath += 'B' + this.getBlur();
            }

            if (this.getGrayscale()) {
                sizePath += 'G';
            }
        }

        return String(sizePath);
    },

    /**
     * Generates an URL to the image file with specified parameters
     *
     * @param {number|string} width    Image width in pixels or 'full' for full size or 'default' for default size (see Viewport)
     * @param {?number} height            Image height in pixels or null for proportional resizing
     * @param {?boolean} uncropped        If set to true, previously set cropping details will be ignored
     * @param {?boolean} unrotated        If set to true, previously set rotation will be ignored
     * @param {?boolean} unfiltered        If set to true, previously set filters (grayscale, blur) will be ignored
     * @return {string}
     */
    getImageSize: function(width, height = null, uncropped = false, unrotated = false, unfiltered = false) {
        var url = this.getUrl();

        // If the file isn't an image or a GIF or hasn't got an ID,
        // stop and return the unchanged url
        if (this.getType() != 'image' || this.getMime() === 'image/gif' || !this.getId()) {
            return url;
        }

        // Adjust the url
        return url.replace(/\/images\/[^\/]+\//, '/images/' + this.getSizePath(width, height, uncropped, unrotated, unfiltered) + '/');
    },

    /**
     * Generates an URL to the image file with specified parameters.
     * Image is shrinked down until the longest side of the image matches maxSize.
     *
     * @param {number} maxSize        Size of the longest side (width or height)
     * @param {boolean} uncropped    If set to true, previously set cropping details will be ignored
     * @param {boolean} unrotated    If set to true, previously set rotation will be ignored
     * @param {boolean} unfiltered    If set to true, previously set filters (grayscale, blur) will be ignored
     * @return {string}
     */
    shrink: function(maxSize, uncropped, unrotated, unfiltered) {

        // If the file isn't an image or a GIF or hasn't got an ID,
        // stop and return the unchanged url
        if (this.getType() != 'image' || this.getMime() === 'image/gif' || !this.getId()) {
            return this.getUrl();
        }

        if (this.getWidth() && this.getHeight()) {

            if (this.getHeight() > this.getWidth()) {
                maxSize = ( this.getWidth() / this.getHeight() ) * maxSize;
            }

            // Don't scale up
            if (maxSize > this.getWidth()) {
                maxSize = this.getWidth();
            }

        }

        return this.getImageSize(maxSize, null, uncropped, unrotated, unfiltered);

    },

    /**
     * Get an URL to the image, ready to use in a lightbox
     *
     * @return {string}
     */
    getLightboxImage: function() {

        // Return an image shrinked down to 1200px,
        // uncropped, unfiltered but rotated.
        var width = ( typeof viewport !== 'undefined' && viewport && 'getImageDefaultWidth' in viewport ? viewport.getImageDefaultWidth() : 1200 );

        // Get current rotation
        var rotationBefore = this.getRotation();

        // Force quarters of 360
        var rotationLightbox = Math.round(((this.getRotation() || 0) / 360) * 4) * 90;

        // Temporary override rotation
        this.setRotation(rotationLightbox);

        // Generate image url
        var url = this.shrink(width, true, false, true);

        // Reset rotation
        this.setRotation(rotationBefore);

        return url;

    },

    /**
     * Set the file ID
     *
     * @param {number} id The new ID
     * @return {File}
     */
    setId: function(id) {
        this.id = id;
        return this;
    },

    /**
     * Set the created at date
     *
     * @param {Date} created_at The new created at date
     * @return {File}
     */
    setCreatedAt: function(created_at) {
        this.created_at = created_at;
        return this;
    },

    /**
     * Set the file name
     *
     * @param {string} name The new name
     * @return {File}
     */
    setName: function(name) {
        this.name = name;
        return this;
    },

    /**
     * Set the file extension
     *
     * @param {string} extension The new extension
     * @return {File}
     */
    setExtension: function(extension) {
        this.extension = extension;
        return this;
    },

    /**
     * Set the domain
     *
     * @param {string} domain The new domain
     * @return {File}
     */
    setDomain: function(domain) {
        this.domain = domain;
        return this;
    },

    /**
     * Set the mime type
     *
     * @param {string} mime    The new mime type
     * @return {File}
     */
    setMime: function(mime) {
        this.mime = mime;
        return this;
    },

    /**
     * Sets the mime image.
     * @param mimeImage The mime image url.
     *
     * @return {File}
     */
    setMimeImage: function(mimeImage) {
        this.mimeImage = mimeImage;
        return this;
    },

    /**
     * Set the file type
     *
     * @param {string} type    The new file type
     * @return {File}
     */
    setType: function(type) {
        this.type = type;
        return this;
    },

    /**
     * Set the file size
     *
     * @param {number} size    The new size in bytes
     * @return {File}
     */
    setSize: function(size) {
        this.size = size;
        return this;
    },

    /**
     * Set the image original width
     *
     * @param {number} width The new width
     * @return {File}
     */
    setWidth: function(width) {
        this.width = width;
        return this;
    },

    /**
     * Set the image original height
     *
     * @param {number} height The new height
     * @return {this}
     */
    setHeight: function(height) {
        this.height = height;
        return this;
    },

    /**
     * Set the image display width
     *
     * @param {number} width The new display width
     * @return {File}
     */
    setDisplayWidth: function(width) {
        this.displayWidth = width;
        return this;
    },

    /**
     * Set the image display height
     *
     * @param {number} height The new display height
     * @return {File}
     */
    setDisplayHeight: function(height) {
        this.displayHeight = height;
        return this;
    },

    /**
     * Set the file URL
     *
     * @param {string} url The new URL
     * @return {File}
     */
    setUrl: function(url) {
        this.url = url;
        return this;
    },

    /**
     * Set the thumbnail URL
     *
     * @param {string} thumbnail The new thumbnail URL
     * @return {File}
     */
    setThumbnail: function(thumbnail) {
        this.thumbnail = thumbnail;
        return this;
    },

    /**
     * Set if file is internally hosted
     *
     * @param {boolean} internal File internally hosted?
     * @return {File}
     */
    setInternal: function(internal) {
        this.internal = internal;
        return this;
    },

    /**
     * Set if file is in use
     *
     * @param {boolean} in_use File in use?
     * @return {File}
     */
    setInUse: function(in_use) {
        this.in_use = in_use;
        return this;
    },

    /**
     * Set the image rotation
     *
     * @param {number} rotation Image rotation in degrees
     * @return {File}
     */
    setRotation: function(rotation) {
        this.rotation = rotation;
        return this;
    },

    /**
     * Set the image crop x-coordinate
     *
     * @param {number} cropX Image crop x-coordinate
     * @return {File}
     */
    setCropX: function(cropX) {
        this.cropX = cropX;
        return this;
    },

    /**
     * Set the image crop y-coordinate
     *
     * @param {number} cropY Image crop y-coordinate
     * @return {File}
     */
    setCropY: function(cropY) {
        this.cropY = cropY;
        return this;
    },

    /**
     * Set the image crop width
     *
     * @param {number} cropWidth Image crop width
     * @return {File}
     */
    setCropWidth: function(cropWidth) {
        this.cropWidth = cropWidth;
        return this;
    },

    /**
     * Set the image crop height
     *
     * @param {number} cropHeight Image crop height
     * @return {File}
     */
    setCropHeight: function(cropHeight) {
        this.cropHeight = cropHeight;
        return this;
    },

    /**
     * Set the image cropping coordinates (shorthand)
     *
     * @param {number|boolean} cropX    Image crop x-coordinate or false if cropping should be disabled
     * @param {number} cropY            Image crop y-coordinate
     * @param {number} cropWidth        Image crop width
     * @param {number} cropHeight        Image crop height
     * @return {File}
     */
    setCrop: function(cropX, cropY, cropWidth, cropHeight) {
        if (cropX === false || ( cropX == 0 && cropY == 0 && cropWidth == this.getWidth() && cropHeight == this.getHeight() )) {
            this.cropX = false;
            this.cropY = false;
            this.cropWidth = false;
            this.cropHeight = false;
        } else {
            this.cropX = cropX;
            this.cropY = cropY;
            this.cropWidth = cropWidth;
            this.cropHeight = cropHeight;
        }
        return this;
    },

    /**
     * Set if image should be grayscaled
     *
     * @param {boolean} grayscale Grayscale image?
     * @return {File}
     */
    setGrayscale: function(grayscale) {
        this.grayscale = grayscale;
        return this;
    },

    /**
     * Set image blur level
     *
     * @param {number} blur New blur level. Zero to disable
     * @return {File}
     */
    setBlur: function(blur) {
        this.blur = blur;
        return this;
    },


    /**
     * Automatically crop the image so that it matches the specified aspect-ratio
     *
     * @param {number} aspectRatio Aspect ratio to match
     * @return {File}
     */
    autoCrop: function(aspectRatio) {

        var imageAspectRatio = ( this.getCropWidth() || this.getWidth() ) / ( this.getCropHeight() || this.getHeight() );

        if (imageAspectRatio.toFixed(4) === aspectRatio.toFixed(4)) {
            return this;
        }

        if (this.getRotation() && Math.abs(this.getRotation()) != 180) {
            // Rotated image, it will get complicated

            // Calculate the largest rectangle that would fit into the rotated image
            var largestRectangle = function(width, height, angle) {

                // Convert angle to radians
                angle = angle * ( Math.PI / 180 );

                var widthIsLonger = width >= height,
                    sideShort, sideLong;

                if (widthIsLonger) {
                    sideLong = width;
                    sideShort = height;
                } else {
                    sideLong = height;
                    sideShort = width;
                }

                var sinA = Math.abs(Math.sin(angle)),
                    cosA = Math.abs(Math.cos(angle));

                if (sideShort <= 2 * sinA * cosA * sideLong) {
                    var x = 0.5 * sideShort;
                    if (widthIsLonger) {
                        return {
                            width: x / sinA,
                            height: x / cosA,
                        };
                    } else {
                        return {
                            width: x / cosA,
                            height: x / sinA
                        };
                    }

                } else {
                    var cos2a = cosA * cosA - sinA * sinA;
                    return {
                        width: ( width * cosA - height * sinA ) / cos2a,
                        height: ( height * cosA - width * sinA ) / cos2a
                    };
                }

            };

            var cropArea = largestRectangle(this.getWidth(), this.getHeight(), this.getRotation());
            cropArea.ratio = cropArea.width / cropArea.height;

            if (aspectRatio > cropArea.ratio) {

                this.setCropWidth(cropArea.width);
                this.setCropHeight(cropArea.width / aspectRatio);

            } else {

                this.setCropHeight(cropArea.height);
                this.setCropWidth(cropArea.height * aspectRatio);

            }

            this.setCropX(this.getWidth() / 2 - this.getCropWidth() / 2);
            this.setCropY(this.getHeight() / 2 - this.getCropHeight() / 2);

        } else {

            if (aspectRatio > imageAspectRatio) {
                // The image is to high. We need to match it into the width
                // of the slider and then cut it at its top and bottom

                if (this.isCropped()) {
                    // Image was already cropped, this is the hard part.

                    // Try to increase the crop width, if that is enough, fine.
                    // If not, decrease the crop height.
                    var imageCropWidth = this.getCropHeight() * aspectRatio,
                        imageCropHeight = this.getCropHeight(),
                        imageCropX = this.getCropX(),
                        imageCropY = this.getCropY();

                    if (imageCropWidth > this.getWidth()) {
                        // Sorry, too wide, decrease crop height
                        imageCropWidth = this.getWidth();
                        imageCropHeight = this.getWidth() / aspectRatio;

                    }

                    var cropWidthDifference = imageCropWidth - this.getCropWidth();
                    var cropHeightDifference = this.getCropHeight() - imageCropHeight;

                    imageCropX -= cropWidthDifference / 2;
                    imageCropY -= cropHeightDifference / 2;

                    imageCropX = Math.max(0, Math.min(this.getWidth() - imageCropWidth, imageCropX));
                    imageCropY = Math.max(0, Math.min(this.getHeight() - imageCropHeight, imageCropY));

                    this.setCrop(imageCropX, imageCropY, imageCropWidth, imageCropHeight);
                } else {
                    // Image wasn't cropped before, this is easy:

                    // Crop width is image width
                    var imageCropWidth = this.getWidth();
                    // Crop height is the image height scaled to the ratio
                    var imageCropHeight = this.getWidth() / aspectRatio;

                    // Vertically center
                    var imageCropY = ( this.getHeight() / 2 ) - ( imageCropHeight / 2 );

                    // Set the new values
                    this.setCrop(0, imageCropY, imageCropWidth, imageCropHeight);
                }

            } else {
                // The image is not high enough, we need to scale the image up until it
                // matches the height of the slider and then cut it left and right

                if (this.isCropped()) {
                    // Image was already cropped, this is the hard part.

                    // Try to increase the crop height, if that is enough, fine.
                    // If not, decrease the crop width.
                    var imageCropHeight = this.getCropWidth() / aspectRatio,
                        imageCropWidth = this.getCropWidth(),
                        imageCropX = this.getCropX(),
                        imageCropY = this.getCropY();

                    if (imageCropHeight > this.getHeight()) {
                        // Sorry, too wide, decrease crop width
                        imageCropHeight = this.getHeight();
                        imageCropWidth = this.getHeight() * aspectRatio;
                    }

                    var cropWidthDifference = this.getCropWidth() - imageCropWidth;
                    var cropHeightDifference = imageCropHeight - this.getCropHeight();

                    imageCropX -= cropWidthDifference / 2;
                    imageCropY -= cropHeightDifference / 2;


                    imageCropX = Math.max(0, Math.min(this.getWidth() - imageCropWidth, imageCropX));
                    imageCropY = Math.max(0, Math.min(this.getHeight() - imageCropHeight, imageCropY));

                    this.setCrop(imageCropX, imageCropY, imageCropWidth, imageCropHeight);
                } else {
                    // Image wasn't cropped before, this is easy:

                    // Crop height is image height
                    var imageCropHeight = this.getHeight();
                    // Crop width is the image width scaled to the ratio
                    var imageCropWidth = this.getHeight() * aspectRatio;

                    // Horizontally center
                    var imageCropX = ( this.getWidth() / 2 ) - ( imageCropWidth / 2 );

                    // Set the new values
                    this.setCrop(imageCropX, 0, imageCropWidth, imageCropHeight);
                }
            }
        }

        return this;
    },

    /**
     * @param {File} file
     */
    applyFilters(file) {
        if (!file) {
            return;
        }

        if (file.isRotated()) {
            this.setRotation(file.getRotation());
        }

        if (file.getGrayscale()) {
            this.setGrayscale(file.getGrayscale());
        }

        if (file.getBlur()) {
            this.setBlur(file.getBlur());
        }
    },
});

export default File;

/**
 * @file
 * Content Slider element
 */

import SliderTrait from './traits/slider';

/**
 * In CMS mode, this class overrides itself, after it was already overriden by
 * the CMS-extended element class. This is to ensure the right window context
 * inside the methods in this base class (viewport, etc.). That means, CMS-extended
 * element classes shouldn't override the base methods, because they would get re-overridden here.
 *
 * @class ElementContentSlider
 * @extends Element
 */
const ElementContentSlider = (window.ElementContentSlider || window.ElementBase).extend(/** @lends ElementContentSlider.prototype */ {
    ...SliderTrait(),

    initSlider(currentSlide = null) {
        // (Re-)fetch the settings and remember them
        this.settings = this.getSliderSettings();

        // If it's already initialized, un-initialize
        if (this.slick) {
            if (currentSlide === null) {
                currentSlide = this.slick.currentSlide;
            }
            if (this.settings.slidesToShow >= this.slick.countSlides) {
                currentSlide = 0;
            }
            this.resetSlider();
        }

        // Remove old slick markup
        if (this.$slider.hasClass('slick-initialized')) {
            const $slides = this.$slider.find('.slick-slide:not(.slick-cloned)');
            this.$slider.empty().append($slides);
            this.$slider.removeClass('slick-initialized slick-slider');
        }

        const configuration = this.getSlickConfig(currentSlide);

        this.initSlick(configuration);
        this.fixSlick();
    },
});

export default ElementContentSlider;
/**
 * Universal Importer
 *
 * @type {Function}
 */
var Importer = Class.extend({

    options: {
        target: null,
        url: null,
        error: function(message, importer) {
            alert(message);
        },
        success: function(response, importer) {
            importer.display(response.contents);
        }
    },

    $preview: null,

    contents: [],

    target: null,

    init: function (options) {
        this.options = $.extend(this.options, options || {});

        if (! this.options.url) {
            throw new Error('Importer requires at least an url to be set.');
        }
    },

    load: function (payload) {
        $.ajax({
            url: this.result(this.options.url),
            type: 'POST',
            data: payload,
            dataType: 'application/json',
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: function(response, status, xhr) {
                if (xhr.status == 301 && xhr.responseJSON && ( 'redirect' in xhr.responseJSON )) {
                    window.location.href = xhr.responseJSON.redirect;
                    return;
                }

                if (response.errors.length) {
                    this.options.error(response.errors.join("\n"), this);
                    return;
                }

                this.options.success(response, this);
            }.bind(this)
        });
    },

    display: function(contents) {
        this.$preview.contents().find('body').html(contents);
    },

    result: function(value) {
        if (typeof value == 'function') {
            return value(this);
        }

        return value;
    }
});



var handleMouseOver = function (e) {
    e.preventDefault();
    $(this).addClass('highlighted');
};

var handleMouseOut = function (e) {
    e.preventDefault();
    $(this).removeClass('highlighted');
};

var handleClick = function(e) {

    console.log('handling click');

    if (spaceDown) {

    } else if (ctrlDown) {

    } else if (shiftDown) {

    }

    if (! $('.selection').is('selection-active')) {
        $('.selected', '.selection-view').removeClass('selected');
    }
};

var handleKeydown = function (e) {
    if (e.keyCode === 32) {
        spaceDown = true;
    } else if (e.keyCode === 17) {
        ctrlDown = true;
    }
};

var handleKeyup = function (e) {
    if (e.keyCode === 32) {
        spaceDown = false;
    } else if (e.keyCode === 17) {
        ctrlDown = false;
    }
};

var collide = function(a, b) {
    var ao = a.offset(), bo = b.offset();

    return !(
        ao.top + a.height() < bo.top || ao.top > bo.top + b.height() ||
        ao.left + a.width() < bo.left || ao.left > bo.left + b.width()
    );
};

var selectElements = function(e) {

    if (spaceDown) {
        $(document).off('mousemove', updateViewport);
    }

    if (!$('.selection', '.selection-view').is('.selection-active')) {
        return;
    }

    console.log('handling mouseup');

    $(document).off('mousemove', updateSelection);
    $(document).off('mouseup', selectElements);

    $('.selection', '.selection-view')
        .removeClass('selection-active')
        .css({ width: 0, height: 0 });
};

var updateSelection = function(e) {
    e.preventDefault();

    var o = $('.selection-view').offset(),
        w = Math.abs(startW - (e.pageX - o.left)),
        h = Math.abs(startH - (e.pageY - o.top)),
        $selection = $('.selection', '.selection-view');

    if (!$selection.is('.selection-active')) {
        $('.selection', '.selection-view')
            .addClass('selection-active')
            .css({ left: startW, top: startH });
        console.log('selecting...');
    }

    $selection.css({ width: w, height: h });

    if (e.pageX - o.left <= startW && e.pageY - o.top >= startH) {
        $selection.css({ left: e.pageX - o.left });
    } else if (e.pageY - o.top <= startH && e.pageX - o.left >= startW) {
        $selection.css({ top: e.pageY - o.top });
    } else if (e.pageY - o.top < startH && e.pageX - o.left < startW) {
        $selection.css({ left: e.pageX - o.left, top: e.pageY - o.top });
    }

    $('.selection-view > :not(.grid)').each(function() {
        if (collide($selection, $(this))) {
            $(this).addClass('selected');
        } else {
            $(this).removeClass('selected');
        }
    });
};

var insertContents = function(elem, items) {
    var contents = [];

    $.each(items, function(){
        contents.push($.trim($(this).text().replace(/(?:\r\n|\r|\n)/g, " ")));
    });

    insert(elem, contents.join("\n\n"));
};

var parseResponse = function(response) {
    var $contents = $(response.contents),
        contents = [];

    $contents.each(function() {

        var text = $.trim($(this).text().replace(/(?:\r\n|\r|\n)/g, " "));

        if (text.length) {
            contents.push(text);
        }
    });
};

var insert = function (elem, val) {
    if (document.selection) {
        elem.focus();
        var selection = document.selection.createRange();
        selection.text = val;
        elem.focus();
    } else if (elem.selectionStart || elem.selectionStart === '0') {
        var start = elem.selectionStart,
            end = elem.selectionEnd,
            top = elem.scrollTop;
        elem.value = elem.value.substring(0, start) + val + elem.value.substring(end, elem.value.length);
        elem.focus();
        elem.selectionStart = start + val.length;
        elem.selectionEnd = start + val.length;
        elem.scrollTop = top;
    } else {
        elem.value += val;
        elem.focus();
    }
};

var updateViewport = function (e) {

};

var initSelection = function(e) {

    if (spaceDown) {
        $(document).on('mousemove', updateViewport);
    }

    if (spaceDown || ctrlDown || shiftDown) {
        return;
    }

    console.log('handle mousedown');

    startW = e.pageX - $('.selection-view').offset().left;
    startH = e.pageY - $('.selection-view').offset().top;

    $(document).on('mousemove', updateSelection);
    $(document).on('mouseup', selectElements);
};


export default Importer;
